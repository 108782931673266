import React, { useEffect, useState, useContext, useRef } from "react";
import Loader from "react-loader-spinner";
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import Modal from "react-modal";
import Axios from "../utils/axios";
import { useLocation } from "react-router-dom";
import { Helper } from "../helper";
import CustomStyle from "../styles";
import { AuthContext } from "../context/auth";
import { showToast } from "utils/general";

const customStyles = {
  content: {
    top: "50%",
    left: "10%",
    right: "10%",
    bottom: "auto",
    // marginRight: "-50%",
    transform: "translate(0%, -50%)",
    // maxWidth: "500px",
  },
};

Modal.setAppElement("#root");

function Coupons() {
  const context = useContext(AuthContext);
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [loadingPromos, setLoadingCoupons] = useState(false);
  const [loadingPromoDelete, setLoadingPromoDelete] = useState(null);
  const [activeCoupon, setActiveCoupon] = useState(null);
  const [editLoading, setEditLoading] = useState(false);

  const [coupons, setCoupons] = useState([]);
  const [users, setUsers] = useState([]);
  const [attachedUsers, setAttachedUsers] = useState([]);
  const [viewCoupon, setViewCoupon] = useState(false);
  const [couponType, setCouponType] = useState("code");

  const [fetchLoading, setFetchLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [validated, setValidated] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formValidated, setFormValidated] = useState(false);

  const [id, setId] = useState(0);
  const [code, setCode] = useState("");
  const [offer, setOffer] = useState("");
  const [offerType, setOffertype] = useState("");
  const [description, setDescription] = useState("");
  const [validity, setvalidity] = useState("");
  const [status, setStatus] = useState("");
  const [minCartAmount, setMinCartAmount] = useState("");
  const [cap, setCap] = useState("");

  let codeInput = useRef(null);
  let capInput = useRef(null);
  let offerInput = useRef(null);
  let offerTypeInput = useRef(null);
  let descInput = useRef(null);
  let minCartInput = useRef(null);
  let validityInput = useRef(null);
  let statusInput = useRef(null);

  let editCodeInput = useRef(null);
  let editCapInput = useRef(null);
  let editOfferInput = useRef(null);
  let editOfferTypeInput = useRef(null);
  let editDescInput = useRef(null);
  let editMinCartInput = useRef(null);
  let editValidityInput = useRef(null);
  let editStatusInput = useRef(null);

  useEffect(() => {
    if (Helper.isPopulator(context.authState?.admin)) {
      return false;
    }

    fetchCoupons();
  }, []);

  useEffect(() => {
    if (activeCoupon && activeCoupon?._id) {
      setId(activeCoupon?._id || 0);
      setCode(activeCoupon?.code || "");
      setOffer(activeCoupon?.offer || "");
      setOffertype(activeCoupon?.offerType || "");
      setDescription(activeCoupon?.description || "");
      setvalidity(activeCoupon?.validity || "");
      setStatus(activeCoupon?.status || "");
      setMinCartAmount(activeCoupon?.minCartAmount || "");
      setAttachedUsers(activeCoupon?.usersAppliedTo || []);
      setCouponType(
        activeCoupon?.code == "Registration" || activeCoupon?.code == "Referral"
          ? activeCoupon?.code
          : "code"
      );
      setFormValidated(false);
    }
  }, [activeCoupon]);

  const fetchCoupons = async () => {
    const { token } = context.authState;
    setLoadingCoupons(true);
    try {
      let couponResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/coupons`,
        token
      );

      if (couponResponse && couponResponse?.data) {
        setCoupons(couponResponse?.data || []);
      }
      setLoadingCoupons(false);
    } catch (error) {
      showToast("Oops. failed to fetch coupons");
      setLoadingCoupons(false);
    }
  };

  const handleDeleteSubmit = async (item) => {
    setLoadingPromoDelete(item?._id);
    try {
      const { token } = context.authState;
      let response = await Axios.delete(
        `${process.env.REACT_APP_API_V2}/admin/coupons/${item?._id}`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      fetchCoupons();
      showToast("Coupon Deleted", "success");
      setLoadingPromoDelete(null);
    } catch (error) {
      showToast("Oops. something went wrong while deleting promo");
      setLoadingPromoDelete(null);
    }
  };

  const onFormSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      const data = {
        code: couponType == "code" ? codeInput.current.value : couponType,
        offer: offerInput.current.value,
        offerType: offerTypeInput.current.value,
        validity: validityInput.current.value,
        status: statusInput.current.value,
        minCartAmount: minCartInput.current.value,
        cap: capInput.current.value,
        description: descInput.current.value,
        usersAppliedTo: attachedUsers,
      };

      setSubmitLoading(true);

      try {
        const { token } = context.authState;
        await Axios.post(
          `${process.env.REACT_APP_API_V2}/admin/coupons`,
          data,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );

        fetchCoupons();
        setIsOpen(false);
      } catch (error) {
        showToast(JSON.stringify(error.response.data.message));
      }

      setSubmitLoading(false);
    }
  };

  const handleUpdateSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setFormValidated(false);
    } else {
      setEditLoading(true);
      const data = {
        code: couponType == "code" ? code : couponType,
        cap,
        offer,
        offerType,
        validity,
        status,
        minCartAmount,
        description,
        usersAppliedTo: attachedUsers,
      };

      try {
        const { token } = context.authState;
        let response = await Axios.patch(
          `${process.env.REACT_APP_API_V2}/admin/coupons/${activeCoupon?._id}`,
          data,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );

        showToast("Coupon Updated", "success");
        fetchCoupons();
        setEditLoading(false);
        setActiveCoupon(null);
      } catch (error) {
        showToast("Oops. something went wrong while saving item");
        setEditLoading(false);
      }
    }

    setFormValidated(true);
  };

  const fetchUsers = async (name) => {
    const { token } = context.authState;
    setLoadingCoupons(true);
    try {
      let userResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/coupons/users/${name}`,
        token
      );

      if (userResponse && userResponse?.data) {
        setUsers(userResponse?.data || []);
      }
      setLoadingCoupons(false);
    } catch (error) {
      showToast("Oops. failed to fetch coupons");
      setLoadingCoupons(false);
    }
  };

  const attachUser = (_user) => {
    const findUser = attachedUsers.find((user) => user._id == _user._id);
    if (!!findUser) {
      const usersRemaining = attachedUsers.filter(
        (user) => user._id != _user._id
      );
      setAttachedUsers(usersRemaining);
    } else {
      setAttachedUsers([...attachedUsers, _user]);
    }
  };

  if (Helper.isPopulator(context.authState?.admin) || Helper.isFinance(context.authState?.admin)) {
    return (
      <div style={{ textAlign: "center" }}>
        <small>You are not permitted to view this page</small>
      </div>
    );
  }

  if (loading) {
    return (
      <div
        style={{
          flex: 1,
          textAlign: "center",
          padding: "20px 0px",
        }}
      >
        <Loader
          type="Oval"
          color={CustomStyle.colors.primary}
          height={50}
          width={50}
        />
      </div>
    );
  }

  return (
    <>
      <Container fluid id="MainArea">
        <Row>
          <Col md="12">
            {fetchLoading ? (
              <div
                style={{
                  flex: 1,
                  textAlign: "center",
                  padding: "20px 0px",
                  display: "flex",
                  alignItems: "center",
                  height: "80px",
                  justifyContent: "center",
                }}
              >
                <Loader
                  type="Oval"
                  color={CustomStyle.colors.primary}
                  height={50}
                  width={50}
                />
              </div>
            ) : (
              <Card className="card-tasks">
                <Card.Header
                  style={{ display: "flex" }}
                  className="justify-content-md-between"
                >
                  <Card.Title as={Col} flex="1">
                    Coupons
                  </Card.Title>
                  <Card.Title as={Col} md="3">
                    <Button
                      className="btn-fill form-control"
                      type="submit"
                      variant="primary"
                      style={{
                        background: CustomStyle.colors.primary,
                        borderColor: CustomStyle.colors.primary,
                      }}
                      onClick={() => setIsOpen(true)}
                    >
                      Add Coupon
                    </Button>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="table-full-width">
                    <Table className="table-hover table-striped">
                      <thead>
                        <tr>
                          <th className="border-0">Code</th>
                          <th className="border-0">Offer</th>
                          <th className="border-0">Cart amount</th>
                          <th className="border-0">Validity</th>
                          <th className="border-0">Cap</th>
                          <th className="border-0">User Specific</th>
                          <th className="border-0">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {coupons.length > 0 ? (
                          coupons.map((item, index) => (
                            <tr key={index} key={item._id}>
                              <td>
                                <Button
                                  variant="outline-light"
                                  style={{
                                    borderWidth: 0,
                                    color: CustomStyle.colors.primary,
                                  }}
                                  onClick={() => {
                                    setActiveCoupon(item);
                                    setViewCoupon(true);
                                  }}
                                >
                                  {item.code}
                                </Button>
                              </td>
                              <td>
                                {item.offerType == "percentage"
                                  ? `${item.offer}%`
                                  : `₦${item.offer}`}
                              </td>
                              <td>
                                {!!item.minCartAmount
                                  ? `₦${item.minCartAmount}`
                                  : "Unlimited"}
                              </td>
                              <td>
                                {item.validity != "none"
                                  ? `${item.validity} days`
                                  : `${item.validity}`}
                              </td>
                              <td>
                                {!!item.cap ? `${item.cap}` : `Unlimited`}
                              </td>
                              <td>
                                {item.usersAppliedTo.length == 0 ? "No" : "Yes"}
                              </td>
                              <td>
                                <Button
                                  className="btn-fill form-control mb-2"
                                  type="submit"
                                  variant="info"
                                  style={{
                                    background: CustomStyle.colors.info,
                                    borderColor: CustomStyle.colors.info,
                                  }}
                                  onClick={() => setActiveCoupon(item)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  className="btn-fill form-control"
                                  type="submit"
                                  variant="danger"
                                  style={{
                                    background: CustomStyle.colors.danger,
                                    borderColor: CustomStyle.colors.danger,
                                  }}
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Are you sure you want to delete promo?"
                                      )
                                    ) {
                                      handleDeleteSubmit(item);
                                    }
                                  }}
                                >
                                  {loadingPromoDelete == item?._id ? (
                                    <Loader
                                      type="Oval"
                                      color={CustomStyle.colors.white}
                                      height={20}
                                      width={20}
                                    />
                                  ) : (
                                    "Delete"
                                  )}
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="2"
                              style={{
                                textAlign: "center",
                                color: CustomStyle.colors.danger,
                              }}
                            >
                              <small>No promo found</small>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            )}
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setIsOpen(false)}
              style={customStyles}
              contentLabel=" Modal"
            >
              <Form
                noValidate
                validated={validated}
                onSubmit={(e) => {
                  e.preventDefault();
                  onFormSubmit(e);
                }}
              >
                <Row>
                  <Col md="6">
                    <Form.Group controlId="exampleForm.ControlSelect0">
                      <Form.Label>Type</Form.Label>
                      <Form.Control
                        as="select"
                        required
                        onChange={(e) => setCouponType(e.target.value)}
                      >
                        <option value="code">Coupon</option>
                        <option value="Registration">New registration</option>
                        <option value="Referral">Referral</option>
                      </Form.Control>
                    </Form.Group>
                    {couponType == "code" && (
                      <Form.Group>
                        <label>Code</label>
                        <Form.Control
                          type="text"
                          required
                          ref={codeInput}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter a coupon.
                        </Form.Control.Feedback>
                      </Form.Group>
                    )}
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <label>Offer</label>
                          <Form.Control
                            type="text"
                            required
                            ref={offerInput}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please enter an offer value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>Offer Type</Form.Label>
                          <Form.Control
                            as="select"
                            required
                            ref={offerTypeInput}
                            value={
                              couponType == "Registration" ||
                              (couponType == "Referral" && "amount")
                            }
                            disabled={
                              couponType == "Registration" ||
                              couponType == "Referral"
                            }
                          >
                            <option value="amount">Amount</option>
                            <option value="percentage">Percentage</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group>
                          <label>Min Cart Amount</label>
                          <Form.Control
                            type="text"
                            // required
                            ref={minCartInput}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please enter a minimum cart amount value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group>
                          <label>Cap</label>
                          <Form.Control
                            type="text"
                            ref={capInput}
                          ></Form.Control>
                          <Form.Control.Feedback type="invalid">
                            Please enter a cap value.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <Form.Group controlId="exampleForm.ControlSelect2">
                          <Form.Label>Validity</Form.Label>
                          <Form.Control
                            as="select"
                            required
                            ref={validityInput}
                          >
                            <option value="none">None</option>
                            <option value="1">1 day</option>
                            <option value="2">2 days</option>
                            <option value="3">3 days</option>
                            <option value="5">5 days</option>
                            <option value="7">7 days</option>
                            <option value="10">10 days</option>
                            <option value="12">12 days</option>
                            <option value="15">15 days</option>
                            <option value="30">30 days</option>
                            <option value="60">60 days</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group controlId="exampleForm.ControlSelect3">
                          <Form.Label>Status</Form.Label>
                          <Form.Control as="select" required ref={statusInput}>
                            <option value="active">Active</option>
                            <option value="disabled">Disable</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group>
                      <label>Description</label>
                      <Form.Control as="textarea" ref={descInput} />
                      <Form.Control.Feedback type="invalid">
                        Please enter a description.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Button
                      className="btn-fill form-control pull-right"
                      variant="primary"
                      type="submit"
                      disabled={submitLoading}
                      style={{
                        background: CustomStyle.colors.primary,
                        borderColor: CustomStyle.colors.primary,
                      }}
                    >
                      {submitLoading ? (
                        <Loader
                          type="Oval"
                          color={CustomStyle.colors.white}
                          height={20}
                          width={20}
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Col>
                  <Col md="6">
                    <div>
                      <Form.Group>
                        <Form.Label>
                          Add Users coupon applies to (if any)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Search users you want to attach"
                          onChange={(e) => fetchUsers(e.target.value)}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please enter a coupon.
                        </Form.Control.Feedback>
                      </Form.Group>
                      <div>
                        {users.map((user) => (
                          <div
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 5,
                            }}
                            key={user._id.toString()}
                          >
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: "normal",
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              {user.name}
                            </span>
                            {!attachedUsers.find(
                              (_user) => user._id == _user._id
                            ) && (
                              <Button
                                onClick={() => attachUser(user)}
                                size="sm"
                              >
                                Add
                              </Button>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div style={{ marginTop: 50 }}>
                      <hr />
                      <p>Attached Users</p>
                      <div>
                        {attachedUsers.map((user) => (
                          <div
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 5,
                            }}
                            key={user._id.toString()}
                          >
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: "normal",
                                padding: 0,
                                margin: 0,
                              }}
                            >
                              {user.name}
                            </span>
                            <Button onClick={() => attachUser(user)} size="sm">
                              {attachedUsers.includes(user) ? "Remove" : "Add"}
                            </Button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </Modal>
          </Col>
        </Row>
      </Container>
      {!!activeCoupon && !viewCoupon ? (
        <Modal
          isOpen={true}
          onRequestClose={() => setActiveCoupon(null)}
          style={customStyles}
          contentLabel=" Modal"
        >
          <Form
            noValidate
            validated={formValidated}
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateSubmit(e);
            }}
          >
            <Row>
              <Col md="6">
                <Form.Group controlId="exampleForm.ControlSelect0">
                  <Form.Label>Type</Form.Label>
                  <Form.Control
                    as="select"
                    required
                    onChange={(e) => setCouponType(e.target.value)}
                    value={couponType}
                  >
                    <option value="code">Coupon</option>
                    <option value="Registration">New registration</option>
                    <option value="Referral">Referral</option>
                  </Form.Control>
                </Form.Group>
                {couponType == "code" && (
                  <Form.Group>
                    <label>Code</label>
                    <Form.Control
                      type="text"
                      required
                      ref={editCodeInput}
                      value={code}
                      onChange={(e) => setCode(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please enter a coupon.
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
                <Row>
                  <Col md="6">
                    <Form.Group>
                      <label>Offer</label>
                      <Form.Control
                        type="text"
                        required
                        ref={editOfferInput}
                        value={offer}
                        onChange={(e) => setOffer(e.target.value)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please enter an offer value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Offer Type</Form.Label>
                      <Form.Control
                        as="select"
                        required
                        ref={editOfferTypeInput}
                        value={offerType}
                        onChange={(e) => setOffertype(e.target.value)}
                      >
                        <option value="amount">Amount</option>
                        <option value="percentage">Percentage</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group>
                      <label>Min Cart Amount</label>
                      <Form.Control
                        type="text"
                        // required
                        ref={editMinCartInput}
                        value={minCartAmount}
                        onChange={(e) => setMinCartAmount(e.target.value)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please enter a minimum cart amount value.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group>
                      <label>Cap</label>
                      <Form.Control
                        type="text"
                        // required
                        ref={editCapInput}
                        value={cap}
                        onChange={(e) => setCap(e.target.value)}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        Please enter a cap.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Validity</Form.Label>
                      <Form.Control
                        as="select"
                        required
                        ref={editValidityInput}
                        value={validity}
                        onChange={(e) => setvalidity(e.target.value)}
                      >
                        <option value="none">None</option>
                        <option value="1">1 day</option>
                        <option value="2">2 days</option>
                        <option value="3">3 days</option>
                        <option value="5">5 days</option>
                        <option value="7">7 days</option>
                        <option value="10">10 days</option>
                        <option value="12">12 days</option>
                        <option value="15">15 days</option>
                        <option value="30">30 days</option>
                        <option value="60">60 days</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        required
                        ref={editStatusInput}
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="active">Active</option>
                        <option value="disabled">Disable</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group>
                  <label>Description</label>
                  <Form.Control
                    as="textarea"
                    ref={editDescInput}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a description.
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  className="btn-fill form-control pull-right"
                  variant="primary"
                  type="submit"
                  disabled={editLoading}
                  style={{
                    background: CustomStyle.colors.primary,
                    borderColor: CustomStyle.colors.primary,
                  }}
                >
                  {editLoading ? (
                    <Loader
                      type="Oval"
                      color={CustomStyle.colors.white}
                      height={20}
                      width={20}
                    />
                  ) : (
                    "Update"
                  )}
                </Button>
              </Col>
              <Col md="6">
                <div>
                  <Form.Group>
                    <Form.Label>
                      Add Users coupon applies to (if any)
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search users you want to attach"
                      onChange={(e) => fetchUsers(e.target.value)}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please enter a coupon.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div>
                    {users.map((user) => (
                      <div
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 5,
                        }}
                        key={user._id.toString()}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: "normal",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {user.name}
                        </span>
                        {!attachedUsers.find(
                          (_user) => user._id == _user._id
                        ) && (
                          <Button onClick={() => attachUser(user)} size="sm">
                            Add
                          </Button>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div style={{ marginTop: 50 }}>
                  <hr />
                  <p>Attached Users</p>
                  <div>
                    {attachedUsers.map((user) => (
                      <div
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 5,
                        }}
                        key={user._id.toString()}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: "normal",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {user.name}
                        </span>
                        <Button onClick={() => attachUser(user)} size="sm">
                          {attachedUsers.includes(user) ? "Remove" : "Add"}
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>
      ) : null}

      {!!activeCoupon && !!viewCoupon ? (
        <Modal
          isOpen={true}
          onRequestClose={() => {
            setActiveCoupon(null);
            setViewCoupon(false);
          }}
          style={customStyles}
          contentLabel=" Modal"
        >
          <Form
            noValidate
            validated={formValidated}
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateSubmit(e);
            }}
          >
            <Row>
              <Col md="6">
                <Form.Group>
                  <label>Code</label>
                  <Form.Control
                    type="text"
                    disabled
                    ref={editCodeInput}
                    value={code}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please enter a coupon.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <label>Offer</label>
                  <Form.Control
                    type="text"
                    disabled
                    ref={editOfferInput}
                    value={offer}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please enter an offer value.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Offer Type</Form.Label>
                  <Form.Control
                    as="select"
                    disabled
                    ref={editOfferTypeInput}
                    value={offerType}
                  >
                    <option value="amount">Amount</option>
                    <option value="percentage">Percentage</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <label>Min Cart Amount</label>
                  <Form.Control
                    type="text"
                    disabled
                    ref={editMinCartInput}
                    value={minCartAmount}
                  ></Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please enter a minimum cart amount value.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Validity</Form.Label>
                  <Form.Control
                    as="select"
                    disabled
                    ref={editValidityInput}
                    value={validity}
                  >
                    <option value="none">None</option>
                    <option value="1">1 day</option>
                    <option value="2">2 days</option>
                    <option value="3">3 days</option>
                    <option value="5">5 days</option>
                    <option value="7">7 days</option>
                    <option value="10">10 days</option>
                    <option value="12">12 days</option>
                    <option value="15">15 days</option>
                    <option value="30">30 days</option>
                    <option value="60">60 days</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlSelect1">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    disabled
                    ref={editStatusInput}
                    value={status}
                  >
                    <option value="active">Active</option>
                    <option value="disabled">Disable</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <label>Description</label>
                  <Form.Control
                    as="textarea"
                    disabled
                    ref={editDescInput}
                    value={description}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter a description.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="6">
                <div>
                  <p>Attached Users</p>
                  <div>
                    {attachedUsers.map((user) => (
                      <div
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 5,
                        }}
                        key={user._id.toString()}
                      >
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: "normal",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {user.name}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal>
      ) : null}
    </>
  );
}

export default Coupons;
