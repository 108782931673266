import React, { useEffect, useState, useContext, useRef } from "react";
import Loader from "react-loader-spinner";
import moment from "moment";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import Modal from "react-modal";
import Axios from "../utils/axios";

import ReactPaginate from "react-paginate";

import { Helper } from "../helper";
import CustomStyle from "../styles";
import { AuthContext } from "../context/auth";
import { DashboardContext, SET_ACTIVE_FEED } from "../context/dashboard";
import { CSVLink, CSVDownload } from "react-csv";
import { showToast } from "utils/general";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // maxWidth: window.innerWidth - 20,
    width: "60%",
  },
};

Modal.setAppElement("#root");

const adminForm = {
  id: "",
  name: "",
  email: "",
  role: "",
  opassword: "",
  password: "",
  cpassword: "",
};
function Admins() {
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(0);
  const [users, setUsers] = useState({});
  const [results, setResults] = useState([]);
  const [limit, setLimit] = useState(50);
  const [keyword, setKeyword] = useState("");
  const [searching, setSearching] = useState(false);
  const [csvResults, setCsvResults] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [adminData, setAdminData] = useState(adminForm);

  const context = useContext(AuthContext);
  const dashboardContext = useContext(DashboardContext);

  const { activeFeed } = dashboardContext.dashboardState || {
    activeFeed: {},
  };

  useEffect(() => {
    // empty active user
    if (
      Helper.isPopulator(context.authState?.admin) ||
      Helper.isFinance(context.authState?.admin) ||
      Helper.isAdministrator(context.authState?.admin)
    ) {
      return false;
    }

    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [limit]);

  useEffect(() => {
    if (!!modalOpen?._id) {
      setAdminData({
        id: modalOpen?._id,
        name: modalOpen?.name,
        email: modalOpen?.email,
        role: modalOpen?.role
      });
    }
  }, [modalOpen]);

  useEffect(() => {
    prepareForCSV();
  }, [results]);

  const prepareForCSV = async () => {
    let newResults = [];
    results.forEach(async (r) => {
      r.referredByUser = r?.referredBy?.name ?? "";
      r.referredByEmail = r?.referredBy?.email ?? "";
      r.referredByPhone = r?.referredBy?.phone ?? "";

      newResults.push(r);
    });

    setCsvResults(newResults);
  };

  const fetchData = async (offSet = 1) => {
    const { token } = context.authState;
    setLoading(true);
    try {
      let usersResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/admins?limit=${limit}&page=${offSet}`,
        token
      );

      if (usersResponse && usersResponse?.data) {
        setResults(usersResponse?.data || []);
      }
      setLoading(false);
    } catch (error) {
      showToast(`Oops. failed to fetch users`);
      setLoading(false);
    } finally {
      setSearching(false);
    }
  };

  const toggleUserStatus = async (status, id) => {
    const formData = {
      status: status ? "active" : "inactive",
      id,
    };

    try {
      const { token } = context.authState;

      setSubmitLoading(id);
      let response = await Axios.patch(
        `${process.env.REACT_APP_API_V2}/admin/admins/status`,
        formData,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { data } = response?.data;

      await updateUserList(data)
      setSubmitLoading(0);
    } catch (error) {
      showToast(`Oops. something went wrong while saving item`);

      setSubmitLoading(0);
    }
  };

  const handleAdminUpdate = (key, value) => {
    setAdminData({ ...adminData, id: modalOpen?._id, [key]: value });
  };

  const handleUpdateSubmit = async (event) => {
    setModalLoading(true);
    try {
      const { token } = context.authState;
      let response = await Axios.patch(
        `${process.env.REACT_APP_API_V2}/admin/admins`,
        adminData,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { status, data } = response?.data;

      if (status && status == "success") {
        showToast("Update successful", "success");
        await updateUserList(data)
        closeModal();
      } else {
        showToast(`Oops. Failed to update item`);
      }
      setModalLoading(false);
    } catch (error) {
      showToast(`Oops. something went wrong while updating item`);
      setModalLoading(false);
    }
  };

  const updateUserList = async (user) => {
    const u = results.map(result => {
        console.log(user)
        if (result._id === user._id) {
        console.log(result._id, user._id)
        return user
      } else {
        console.log(result._id, user._id)
        return result
      }
    })

    setResults(u)
  }

  const closeModal = () => {
    setModalLoading(false);
    setModalOpen(false);
  };

  const handlePageClick = (data) => {
    let selected = data.selected + 1;
    fetchData(selected);
  };

  if (
    Helper.isPopulator(context.authState?.admin) ||
    Helper.isFinance(context.authState?.admin)  || Helper.isAdministrator(context.authState?.admin)
  ) {
    return (
      <div style={{ textAlign: "center" }}>
        <small>You are not permitted to view this page</small>
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-tasks">
              <Card.Body>
                {loading ? (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    <Loader
                      type="Oval"
                      color={CustomStyle.colors.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <div className="table-full-width">
                    <Table>
                      <tbody>
                        {results && results?.length > 0 ? (
                          results?.map((item, index) => {
                            return (
                              <tr>
                                <td>
                                  <div>
                                    <span>Name:</span>{" "}
                                    <b style={{ textTransform: "capitalize" }}>
                                      {item?.name}
                                    </b>
                                  </div>
                                  <div>
                                    <span>Email Address:</span>{" "}
                                    <b style={{ textTransform: "capitalize" }}>
                                      {item?.email}
                                    </b>
                                  </div>
                                  <div>
                                    <span>Role:</span>{" "}
                                    <b style={{ textTransform: "capitalize" }}>
                                      {item?.role}
                                    </b>
                                  </div>
                                  <small>
                                    Created {moment(item?.createdAt).fromNow()}{" "}
                                    ({item?.createdAt})
                                  </small>
                                </td>
                                <td className="td-actions text-right">
                                  <Button
                                    className="btn btn-warning btn-fill"
                                    type="button"
                                    variant="warning"
                                    onClick={() => setModalOpen(item)}
                                  >
                                    Update
                                  </Button>
                                  &nbsp;
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-938342127">
                                        Toggle user status
                                      </Tooltip>
                                    }
                                  >
                                    {item?.status == "active" ? (
                                      <Button
                                        className="btn btn-danger btn-fill"
                                        type="button"
                                        disabled={submitLoading == item?._id}
                                        variant="danger"
                                        onClick={async () =>
                                          toggleUserStatus(false, item?._id)
                                        }
                                      >
                                        {submitLoading == item?._id
                                          ? "Deactivating"
                                          : "Deactivate"}
                                      </Button>
                                    ) : (
                                      <Button
                                        className="btn btn-info btn-fill"
                                        type="button"
                                        variant="info"
                                        disabled={submitLoading == item?._id}
                                        onClick={async () =>
                                          toggleUserStatus(true, item?._id)
                                        }
                                      >
                                        {submitLoading == item?._id
                                          ? "Activating"
                                          : "Activate"}
                                      </Button>
                                    )}
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan="6"
                              style={{
                                textAlign: "center",
                                color: CustomStyle.colors.danger,
                              }}
                            >
                              <small>No user found</small>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card.Body>
              <Card.Footer>
                {users && users?.total ? (
                  <ReactPaginate
                    previousLabel={<i className="nc-icon nc-stre-left"></i>}
                    nextLabel={<i className="nc-icon nc-stre-right"></i>}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={users?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={!!modalOpen ? true : false}
        onRequestClose={() => closeModal()}
        style={customStyles}
        contentLabel="Update Admin Modal"
      >
        {modalOpen ? (
          <>
            <h5
              style={{
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
              }}
            >
              <b>Admin Update</b>
            </h5>
            <br />
            <Row>
              <Col className="" md="6">
                <Form.Group>
                  <label htmlFor="">Admin Role </label>
                  &nbsp;&nbsp;
                  <select
                    onChange={(e) => handleAdminUpdate("role", e.target.value)}
                    defaultValue={modalOpen.role}
                    disabled={modalOpen.role == 'super'}
                  >
                    <option value="super">Super Admin</option>
                    <option value="admin">Admin</option>
                    <option value="populator">Populator</option>
                    <option value="finance">Finance</option>
                  </select>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please enter a role.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <label htmlFor="">Name</label>
                  <Form.Control
                    defaultValue={modalOpen.name}
                    placeholder="Admin name label"
                    type="text"
                    required
                    onChange={(e) => handleAdminUpdate("name", e.target.value)}
                  ></Form.Control>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please enter name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <label htmlFor="">Email</label>
                  <Form.Control
                    defaultValue={modalOpen.email}
                    placeholder="Email address"
                    type="text"
                    required
                    onChange={(e) => handleAdminUpdate("email", e.target.value)}
                  ></Form.Control>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please enter email address.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="" md="6">
                <Form.Group>
                  <label htmlFor="">Old Password</label>
                  <Form.Control
                    placeholder="Old Password"
                    type="text"
                    onChange={(e) =>
                      handleAdminUpdate("opassword", e.target.value)
                    }
                  ></Form.Control>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please enter a old password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <label htmlFor="">Password</label>
                  <Form.Control
                    defaultValue={modalOpen.password}
                    placeholder="Password"
                    type="text"
                    onChange={(e) =>
                      handleAdminUpdate("password", e.target.value)
                    }
                  ></Form.Control>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please enter a password.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <label htmlFor="">Confirm Password</label>
                  <Form.Control
                    defaultValue={modalOpen.cpassword}
                    placeholder="Confirm Password"
                    type="text"
                    onChange={(e) =>
                      handleAdminUpdate("cpassword", e.target.value)
                    }
                  ></Form.Control>
                  <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    Please enter a password confirmation.
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <br />

            <Row>
              <Col className="" md="4" />
              <Col className="" md="4">
                <Form.Group>
                  <label htmlFor=""></label>
                  <Button
                    onClick={() => closeModal()}
                    className="btn-fill form-control pull-right"
                    variant="default"
                    type="button"
                  >
                    Close
                  </Button>
                </Form.Group>
              </Col>
              <Col className="" md="4">
                <Form.Group>
                  <label htmlFor=""></label>
                  <Button
                    className="btn-fill form-control pull-right"
                    variant="primary"
                    type="button"
                    disabled={modalLoading}
                    onClick={handleUpdateSubmit}
                  >
                    {modalLoading ? (
                      <Loader
                        type="Oval"
                        color={CustomStyle.colors.white}
                        height={20}
                        width={20}
                      />
                    ) : (
                      "Update"
                    )}
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </>
        ) : null}
      </Modal>
    </>
  );
}

export default Admins;
