import React, { createContext, useReducer, useEffect } from "react";
import { dashboardReducer } from "./reducer";

const DashboardContext = createContext();
const DashboardContextProvider = ({ children }) => {
  let initialState = localStorage.getItem("dashboard");

  if (initialState) {
    initialState = JSON.parse(initialState);
  } else {
    initialState = { warehouses: [], activeWarehouse: {}, activeFeed: {} };
  }

  const [dashboardState, dispatch] = useReducer(dashboardReducer, initialState);

  useEffect(() => {
    localStorage.setItem("dashboard", JSON.stringify(dashboardState));
  }, [dashboardState]);

  return (
    <DashboardContext.Provider value={{ dashboardState, dispatch }}>
      {children}
    </DashboardContext.Provider>
  );
};

export { DashboardContext, DashboardContextProvider };
