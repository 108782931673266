import React, { useState, useRef, useContext } from "react";

// react-bootstrap components
import {
	Badge,
	Button,
	Card,
	Form,
	Navbar,
	Nav,
	Container,
	Row,
	Col,
} from "react-bootstrap";

import { useHistory } from "react-router-dom";

import { toast } from "react-toastify";

import Loader from "react-loader-spinner";

import Axios from "../utils/axios";

import { AuthContext, LOGIN } from "../context/auth";

import CustomStyle from "../styles";

import image from "assets/img/sidebar-3.png";
import { getDYToken } from "services/dynamics";

function Login() {
	const [loading, setLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const { dispatch } = useContext(AuthContext);
	const history = useHistory();
	const emailInput = useRef(null);
	const passwordInput = useRef(null);

	const handleSubmit = async (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
			setValidated(false);
		} else {
			const email = emailInput ? emailInput.current.value : null;
			const password = passwordInput ? passwordInput.current.value : null;
			setLoading(true);
			try {
				const { data } = await Axios.post(
					`${process.env.REACT_APP_API_V2}/auth/admin/login`,
					{
						email,
						password,
					}
				);
				const { admin, token, erpToken } =
					data && data.data
						? data.data
						: {
								admin: null,
								token: null,
								erpToken: null
						  };

				// const { data: erpToken } = await getDYToken();

				

				if (admin && token && erpToken) {
					toast("Login Successful", {
						position: "top-right",
						type: "success",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					dispatch({
						type: LOGIN,
						payload: { admin, token, erpToken },
					});
					history.push("/admin/dashboard");
					setLoading(false);
				} else {
					toast("Oops!, You do not have access", {
						position: "top-right",
						type: "warning",
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					setLoading(false);
				}
			} catch (error) {
				toast("Credentials not found", {
					position: "top-right",
					type: "error",
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
				});
				setLoading(false);
			}
		}

		setValidated(true);
	};

	return (
		<>
			<Container fluid>
				<Row>
					<Col
						md="8"
						style={{
							backgroundImage: "url(" + image + ")",
							backgroundSize: "cover",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
						}}
					></Col>
					<Col md="4" style={{ padding: "30px 40px" }} å>
						<Card className="card-user">
							<div className="card-image">
								<img
									alt="..."
									src={
										require("assets/img/sidebar-4.png")
											.default
									}
								></img>
							</div>
							<Card.Body>
								<div className="author">
									<a
										href="#pablo"
										onClick={(e) => e.preventDefault()}
									>
										<img
											alt="..."
											className="avatar border-gray"
											src={
												require("assets/img/logo.png")
													.default
											}
										></img>
									</a>
									<h3 className="description">
										Welcome back.
									</h3>
								</div>
								<p className="description text-center">
									Log into your dashboard to resume <br />{" "}
									exploring our services
								</p>
								<Form
									noValidate
									validated={validated}
									onSubmit={(e) => {
										e.preventDefault();
										handleSubmit(e);
									}}
								>
									<Row>
										<Col className="" md="12">
											<Form.Group>
												<label htmlFor="exampleInputEmail1">
													Email address
												</label>
												<Form.Control
													placeholder="Email"
													type="email"
													required
													ref={emailInput}
												></Form.Control>
												<Form.Control.Feedback>
													Looks good!
												</Form.Control.Feedback>
												<Form.Control.Feedback type="invalid">
													Please enter a valid email
													address.
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col className="" md="12">
											<Form.Group>
												<label htmlFor="exampleInputEmail1">
													Password
												</label>
												<Form.Control
													placeholder="Password"
													type="password"
													required
													ref={passwordInput}
												></Form.Control>
												<Form.Control.Feedback type="invalid">
													Please enter your password.
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
									</Row>
									<Button
										className="btn-fill form-control pull-right"
										type="submit"
										variant="primary"
										disabled={loading}
										style={{
											background:
												CustomStyle.colors.primary,
											borderColor:
												CustomStyle.colors.primary,
										}}
									>
										{loading ? (
											<Loader
												type="Oval"
												color={CustomStyle.colors.white}
												height={20}
												width={20}
											/>
										) : (
											"Sign In"
										)}
									</Button>
									<div className="clearfix"></div>
									<br />
								</Form>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Login;
