import { Helper } from "../helper";

export const getDYStock = async () => {
	try {
		let response = await Helper.getDYData(
			`${process.env.REACT_APP_API_V2}/WarehouseInventoryStatusesOnHand?cross-company=true`
		);

		return { status: true, data: response.value };
	} catch (error) {
		return { status: false };
	}
};

export const getDYWarehouses = async (token, erpToken) => {
	console.log("erpToken >> ", erpToken);
	try {
		let { data } = await Helper.getData(
			`${process.env.REACT_APP_API_V2}/admin/warehouses/erp?erpToken=${erpToken}`,
			token
		);

		console.log("getDYWarehouses response >> ", data);

		return { status: true, data };
	} catch (error) {
		return { status: false };
	}
};

export const getDYCategories = async (token) => {
	try {
		let { data } = await Helper.getData(
			`${process.env.REACT_APP_API_V2}/admin/category/erp`,
			token
		);

		return { status: true, data };
	} catch (error) {
		return { status: false };
	}
};

export const getDYProducts = async () => {
	try {
		let response = await Helper.getDYData(
			`${process.env.REACT_APP_API_V2}/ItemDetails?cross-company=true`
		);

		return { status: true, data: response.value };
	} catch (error) {
		return { status: false };
	}
};
