import React, { useEffect, useState, useContext, useRef } from "react";
import Loader from "react-loader-spinner";
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import Axios from "../utils/axios";
import { Link, useLocation } from "react-router-dom";
import { Helper } from "../helper";
import CustomStyle from "../styles";
import { AuthContext } from "../context/auth";
import { showToast } from "utils/general";
import { getWarehouses } from "services/warehouseApis";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
  },
};

Modal.setAppElement("#root");

function Promos() {
  const [loading, setLoading] = useState(false);
  const [loadingPromos, setLoadingPromos] = useState(false);
  const [loadingPromoDelete, setLoadingPromoDelete] = useState(null);
  const [activePromo, setActivePromo] = useState(null);
  const [editLoading, setEditLoading] = useState(false);
  const [promos, setPromos] = useState({});
  const [results, setResults] = useState([]);
  const [limit, setLimit] = useState(10);
  const [processedWarehouses, setProcessedWarehouses] = useState([]);
  const context = useContext(AuthContext);
  const [datas, setDatas] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [validated, setValidated] = useState(false);
  const [currentWarehouse, setCurrentWarehouse] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaUrlType, setMediaUrlType] = useState("");
  const [imgIsFull, setImgIsFull] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState([]);

  const [offer, setOffer] = useState("");
  const [offerType, setOfferType] = useState("");

  const location = useLocation();

  let titleInput = useRef(null);
  let offerInput = useRef(null);
  let offerTypeInput = useRef(null);
  let bodyInput = useRef(null);
  let mediaInput = useRef(null);

  let editTitleInput = useRef(null);
  let editBodyInput = useRef(null);
  let editMediaInput = useRef(null);

  useEffect(() => {
    // if (Helper.isPopulator(context.authState?.admin)) {
    //   return false;
    // }

    fetchData();
    fetchPromos();
  }, []);

  useEffect(() => {
    if (activePromo && activePromo?._id) {
      setId(activePromo?._id || 0);
      setTitle(activePromo?.title || "");
      setOffer(activePromo?.offer || "");
      setOfferType(activePromo?.offerType || "");
      setBody(activePromo?.body || "");
      setMediaUrl(activePromo?.media || "");
      setMediaUrlType(activePromo?.mediaType || "");
      setFormValidated(false);
    } else {
      setId(0);
      setTitle("");
      setOffer("");
      setOfferType("");
      setBody("");
      setMediaUrl("");
      setMediaUrlType("");
      setFormValidated(false);
    }

    editMediaInput = null;
    setImgIsFull(false);
  }, [promos, activePromo]);

  const fetchData = async () => {
    const { token } = context.authState;
    setLoading(true);
    try {
      let processedWarehousesResponse = await getWarehouses(token);

      if (processedWarehousesResponse && processedWarehousesResponse?.data) {
        setProcessedWarehouses(processedWarehousesResponse?.data || []);
      }
      setLoading(false);
    } catch (error) {
      showToast("Oops. failed to fetch warehouses");
      setLoading(false);
    }
  };

  const fetchPromos = async (offSet = 1) => {
    const { token } = context.authState;
    setLoadingPromos(true);
    try {
      let promosResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/promos?limit=${limit}&page=${offSet}`,
        token
      );

      if (promosResponse && promosResponse?.data) {
        setPromos(promosResponse?.data || []);
        setResults(promosResponse?.data?.results || []);
      }
      setLoadingPromos(false);
    } catch (error) {
      showToast("Oops. failed to fetch promos");
      setLoadingPromos(false);
    }
  };

  const handleDeleteSubmit = async (item) => {
    setLoadingPromoDelete(item?._id);
    try {
      const { token } = context.authState;
      let response = await Axios.delete(
        `${process.env.REACT_APP_API_V2}/admin/promos/${item?._id}`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { status } = response?.data;

      if (status && status == "success") {
        showToast("Promo Deleted", "success");
        let array = [...results]; // make a separate copy of the array
        const index = results ? results?.indexOf(item) : -1;
        if (index !== -1) {
          array.splice(index, 1);
          setResults(array);
        }
      } else {
        showToast("Oops. Failed to delete promo");
      }
      setLoadingPromoDelete(null);
    } catch (error) {
      showToast("Oops. something went wrong while deleting promo");
      setLoadingPromoDelete(null);
    }
  };

  const handlePageClick = (data) => {
    let selected = data.selected + 1;

    fetchPromos(selected);
  };

  const fetchWarehousePromos = async (id) => {
    setFetchLoading(true);
    setCurrentWarehouse(id);
    setSelectedWarehouse([id]);
    try {
      let response = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/promos/warehouse/${id}`
      );

      setDatas(response.data.results);
    } catch (error) {}
    setFetchLoading(false);
  };

  const handleCheckbox = (item) => {
    if (selectedWarehouse.includes(item._id)) {
      let newWH = selectedWarehouse.filter((wid) => wid != item._id);
      setSelectedWarehouse(newWH);
    } else {
      setSelectedWarehouse([...selectedWarehouse, item._id]);
    }
  };

  const onFormSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
    } else {
      const formData = new FormData();
      formData.append("title", titleInput.current.value);
      formData.append("offer", offerInput.current.value);
      formData.append("offerType", offerTypeInput.current.value);
      formData.append("body", bodyInput.current.value);
      formData.append("media", mediaInput.current.files[0]);
      formData.append("warehouseId", JSON.stringify(selectedWarehouse));
      setSubmitLoading(true);

      try {
        const { token } = context.authState;
        await Axios.post(
          `${process.env.REACT_APP_API_V2}/admin/promos`,
          formData,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );

        fetchData();
        fetchPromos();
        !!currentWarehouse && fetchWarehousePromos(currentWarehouse);
        setIsOpen(false);
      } catch (error) {
        showToast(JSON.stringify(error.response.data.message));
      }

      setSubmitLoading(false);
    }
  };

  const handleUpdateSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setFormValidated(false);
    } else {
      setEditLoading(true);

      const formData = new FormData();
      formData.append("id", id);
      formData.append("title", title);
      formData.append("offer", offer);
      formData.append("offerType", offerType);
      formData.append("body", body || " ");

      if (editMediaInput.current && editMediaInput.current.files[0]) {
        formData.append("media", editMediaInput.current.files[0]);
      } else {
        formData.append("media", mediaUrl);
        formData.append("mediaType", mediaUrlType);
      }

      try {
        const { token } = context.authState;
        let response = await Axios.patch(
          `${process.env.REACT_APP_API_V2}/admin/promos/${activePromo?._id}`,
          formData,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );

        const { data } = response?.data;

        if (data && data?._id) {
          showToast("Promo Updated", "success");
          const index = results
            ? results?.findIndex((p) => p?._id == data?._id)
            : -1;
          if (index > -1) {
            const updatedPromos = results;
            const updated = {
              ...updatedPromos[index],
              title: data?.title,
              body: data?.body,
              media: data?.media,
              mediaType: data?.mediaType,
              updatedAt: data?.updatedAt,
            };
            updatedPromos[index] = updated;
            setResults([...updatedPromos]);
          }

          setActivePromo(null);
          setCurrentWarehouse("");
        } else {
          showToast("Oops. Failed to save item");
        }
        setEditLoading(false);
      } catch (error) {
        showToast("Oops. something went wrong while saving item");
        setEditLoading(false);
      }
    }

    setFormValidated(true);
  };

  if (
    Helper.isFinance(context.authState?.admin) ||
    // Helper.isPopulator(context.authState?.admin) ||
    Helper.isAdministrator(context.authState?.admin)
  ) {
    return (
      <div style={{ textAlign: "center" }}>
        <small>You are not permitted to view this page</small>
      </div>
    );
  }

  if (loading) {
    return (
      <div
        style={{
          flex: 1,
          textAlign: "center",
          padding: "20px 0px",
        }}
      >
        <Loader
          type="Oval"
          color={CustomStyle.colors.primary}
          height={50}
          width={50}
        />
      </div>
    );
  }

  return (
    <>
      <Container fluid id="MainArea">
        <Row>
          {currentWarehouse ? (
            <Col md="8">
              <div style={{ marginBottom: "10px" }}>
                <Button
                  className="btn-fill form-control"
                  type="submit"
                  variant="primary"
                  style={{
                    background: CustomStyle.colors.primary,
                    borderColor: CustomStyle.colors.primary,
                  }}
                  onClick={() => setCurrentWarehouse("")}
                >
                  All Promos
                </Button>
              </div>
              {fetchLoading ? (
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: "20px 0px",
                    display: "flex",
                    alignItems: "center",
                    height: "80px",
                    justifyContent: "center",
                  }}
                >
                  <Loader
                    type="Oval"
                    color={CustomStyle.colors.primary}
                    height={50}
                    width={50}
                  />
                </div>
              ) : (
                <Card className="card-tasks">
                  <Card.Header
                    style={{ display: "flex" }}
                    className="justify-content-md-between"
                  >
                    <Card.Title as={Col} flex="1">
                      Warehouse{" "}
                      {processedWarehouses.filter(
                        (itm) => itm._id == currentWarehouse
                      )[0]?.name || ""}{" "}
                      Promos
                    </Card.Title>
                    <Card.Title as={Col} md="3">
                      <Button
                        className="btn-fill form-control"
                        type="submit"
                        variant="primary"
                        style={{
                          background: CustomStyle.colors.primary,
                          borderColor: CustomStyle.colors.primary,
                        }}
                        onClick={() => setIsOpen(true)}
                      >
                        Add Promo
                      </Button>
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="table-full-width">
                      <Table className="table-hover table-striped">
                        <thead>
                          <tr>
                            <th className="border-0">Title</th>
                            <th className="border-0">Body</th>
                            <th className="border-0">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {datas.length > 0 ? (
                            datas.map((item, index) => (
                              <tr key={index} key={item._id}>
                                <td>
                                  <div style={{ display: "flex" }}>
                                    <img
                                      style={{ width: "80px" }}
                                      className="img-fluid rounded mr-2"
                                      src={item?.media}
                                    />
                                    {item.title}
                                  </div>
                                </td>
                                <td>{item.body}</td>
                                <td>
                                  <Button
                                    as={Link}
                                    to={`${location.pathname}/${item._id}`}
                                    className="btn-fill form-control mb-2"
                                    type="submit"
                                    variant="primary"
                                    style={{
                                      background: CustomStyle.colors.primary,
                                      borderColor: CustomStyle.colors.primary,
                                    }}
                                  >
                                    View
                                  </Button>
                                  <Button
                                    className="btn-fill form-control mb-2"
                                    type="submit"
                                    variant="info"
                                    style={{
                                      background: CustomStyle.colors.info,
                                      borderColor: CustomStyle.colors.info,
                                    }}
                                    onClick={() => setActivePromo(item)}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    className="btn-fill form-control"
                                    type="submit"
                                    variant="danger"
                                    style={{
                                      background: CustomStyle.colors.danger,
                                      borderColor: CustomStyle.colors.danger,
                                    }}
                                    onClick={() => {
                                      if (
                                        window.confirm(
                                          "Are you sure you want to delete promo?"
                                        )
                                      ) {
                                        handleDeleteSubmit(item);
                                      }
                                    }}
                                  >
                                    {loadingPromoDelete == item?._id ? (
                                      <Loader
                                        type="Oval"
                                        color={CustomStyle.colors.white}
                                        height={20}
                                        width={20}
                                      />
                                    ) : (
                                      "Delete"
                                    )}
                                  </Button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td
                                colSpan="2"
                                style={{
                                  textAlign: "center",
                                  color: CustomStyle.colors.danger,
                                }}
                              >
                                <small>No promo found</small>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Card>
              )}
            </Col>
          ) : (
            <Col md="8">
              {loadingPromos ? (
                <div
                  style={{
                    flex: 1,
                    textAlign: "center",
                    padding: "20px 0px",
                    display: "flex",
                    alignItems: "center",
                    height: "80px",
                    justifyContent: "center",
                  }}
                >
                  <Loader
                    type="Oval"
                    color={CustomStyle.colors.primary}
                    height={50}
                    width={50}
                  />
                </div>
              ) : (
                <Card className="card-tasks">
                  <Card.Header>
                    <Card.Title>All Promos</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="table-full-width">
                      <Table className="table-hover table-striped">
                        <thead>
                          <tr>
                            <th className="border-0">Title</th>
                            <th className="border-0">Body</th>
                            <th className="border-0">Warehouse</th>
                            <th className="border-0">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {results && results?.length > 0 ? (
                            results.map((item) => {
                              return (
                                <tr key={item._id}>
                                  <td>
                                    <div style={{ display: "flex" }}>
                                      <img
                                        style={{ width: "80px" }}
                                        className="rounded mr-2"
                                        src={item?.media}
                                      />
                                      {item.title}
                                    </div>
                                  </td>
                                  <td>{item.body}</td>
                                  <td>{item.warehouseId?.name || ""}</td>
                                  <td>
                                    <Button
                                      as={Link}
                                      to={`${location.pathname}/${item._id}`}
                                      className="btn-fill form-control mb-2"
                                      type="submit"
                                      variant="primary"
                                      style={{
                                        background: CustomStyle.colors.primary,
                                        borderColor: CustomStyle.colors.primary,
                                      }}
                                    >
                                      View
                                    </Button>
                                    <Button
                                      className="btn-fill form-control mb-2"
                                      type="submit"
                                      variant="info"
                                      style={{
                                        background: CustomStyle.colors.info,
                                        borderColor: CustomStyle.colors.info,
                                      }}
                                      onClick={() => setActivePromo(item)}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      className="btn-fill form-control"
                                      type="submit"
                                      variant="danger"
                                      style={{
                                        background: CustomStyle.colors.danger,
                                        borderColor: CustomStyle.colors.danger,
                                      }}
                                      onClick={() => {
                                        if (
                                          window.confirm(
                                            "Are you sure you want to delete promo?"
                                          )
                                        ) {
                                          handleDeleteSubmit(item);
                                        }
                                      }}
                                    >
                                      {loadingPromoDelete == item?._id ? (
                                        <Loader
                                          type="Oval"
                                          color={CustomStyle.colors.white}
                                          height={20}
                                          width={20}
                                        />
                                      ) : (
                                        "Delete"
                                      )}
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan="4"
                                style={{
                                  textAlign: "center",
                                  color: CustomStyle.colors.danger,
                                }}
                              >
                                <small>No promo found</small>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    {promos && promos?.total ? (
                      <ReactPaginate
                        previousLabel={<i className="nc-icon nc-stre-left"></i>}
                        nextLabel={<i className="nc-icon nc-stre-right"></i>}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={promos?.totalPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                      />
                    ) : null}
                  </Card.Footer>
                </Card>
              )}
            </Col>
          )}
          <Col md="4">
            <Card className="card-tasks">
              <Card.Header>
                <Card.Title as={Col}>
                  <Row>
                    <Col md="6">
                      <h5>All Warehouses</h5>
                    </Col>
                    <Col md="6">
                      <Button
                        className="btn-fill form-control"
                        type="submit"
                        variant="primary"
                        style={{
                          background: CustomStyle.colors.primary,
                          borderColor: CustomStyle.colors.primary,
                        }}
                        onClick={() => setIsOpen(true)}
                      >
                        Add Promo
                      </Button>
                    </Col>
                  </Row>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="table-full-width">
                  <Table>
                    <tbody>
                      {processedWarehouses?.length > 0 ? (
                        processedWarehouses?.map((item, index) => (
                          <tr key={index}>
                            <td
                              style={{
                                justifyContent: "space-between",
                                display: "flex",
                              }}
                            >
                              <div
                                onClick={() => fetchWarehousePromos(item._id)}
                                style={{
                                  cursor: "pointer",
                                  display: "flex",
                                  flex: 1,
                                }}
                              >
                                <b>{item?.name}</b>
                              </div>
                              <input
                                // checked={
                                //   selectedWarehouse.includes(item?._id)
                                //     ? true
                                //     : false
                                // }
                                type="checkbox"
                                onChange={() => handleCheckbox(item)}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="6"
                            style={{
                              textAlign: "center",
                              color: CustomStyle.colors.danger,
                            }}
                          >
                            <small>No warehouse found</small>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {selectedWarehouse.length > 0 && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={() => setIsOpen(false)}
          style={customStyles}
          contentLabel=" Modal"
        >
          <Form
            noValidate
            validated={validated}
            onSubmit={(e) => {
              e.preventDefault();
              onFormSubmit(e);
            }}
          >
            <Form.Group>
              <label>Title</label>
              <Form.Control
                type="text"
                required
                ref={titleInput}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Please enter a start value.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <label>Offer</label>
              <Form.Control
                type="text"
                required
                ref={offerInput}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Please enter an offer value.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Offer Type</Form.Label>
              <Form.Control as="select" required ref={offerTypeInput}>
                <option value="amount">Amount</option>
                <option value="percent">Percentage</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <label>Body</label>
              <Form.Control as="textarea" required ref={bodyInput} />

              <Form.Control.Feedback type="invalid">
                Please enter a start value.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <label>Image</label>
              <Form.File label="Select Image" custom ref={mediaInput} />

              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please upload image.
              </Form.Control.Feedback>
            </Form.Group>

            <Button
              className="btn-fill form-control pull-right"
              variant="primary"
              type="submit"
              disabled={submitLoading}
              style={{
                background: CustomStyle.colors.primary,
                borderColor: CustomStyle.colors.primary,
              }}
            >
              {submitLoading ? (
                <Loader
                  type="Oval"
                  color={CustomStyle.colors.white}
                  height={20}
                  width={20}
                />
              ) : (
                "Submit"
              )}
            </Button>
          </Form>
        </Modal>
      )}
      {activePromo && activePromo?._id ? (
        <Modal
          isOpen={true}
          onRequestClose={() => setActivePromo(null)}
          style={customStyles}
          contentLabel=" Modal"
        >
          <Form
            noValidate
            validated={formValidated}
            onSubmit={(e) => {
              e.preventDefault();
              handleUpdateSubmit(e);
            }}
          >
            <Form.Group>
              <label>Title</label>
              <Form.Control
                type="text"
                required
                ref={editTitleInput}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Please enter a start value.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <label>Offer</label>
              <Form.Control
                type="text"
                required
                value={offer}
                ref={offerInput}
              ></Form.Control>
              <Form.Control.Feedback type="invalid">
                Please enter an offer value.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="exampleForm.ControlSelect1">
              <Form.Label>Offer Type</Form.Label>
              <Form.Control
                as="select"
                required
                value={offerType}
                ref={offerTypeInput}
              >
                <option value="amount">Amount</option>
                <option value="percent">Percentage</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <label>Body</label>
              <Form.Control
                as="textarea"
                required
                ref={editBodyInput}
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a start value.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              {activePromo?.mediaType == "image" ? (
                <div className={`img-holder ${imgIsFull ? "full" : ""}`}>
                  <img src={activePromo?.media} />
                  <div className="control">
                    <Button
                      className="btn-fill pull-right"
                      variant="primary"
                      type="button"
                      style={{
                        background: CustomStyle.colors.primary,
                        borderColor: CustomStyle.colors.primary,
                      }}
                      onClick={() => {
                        imgIsFull ? setImgIsFull(false) : setImgIsFull(true);
                      }}
                    >
                      {imgIsFull ? (
                        <i className="nc-icon nc-stre-up"></i>
                      ) : (
                        <i className="nc-icon nc-stre-down"></i>
                      )}
                    </Button>
                  </div>
                </div>
              ) : null}
            </Form.Group>
            <Form.Group>
              <label>Image</label>
              <Form.File label="Select Image" custom ref={editMediaInput} />

              <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please upload image.
              </Form.Control.Feedback>
            </Form.Group>
            <Button
              className="btn-fill form-control pull-right"
              variant="primary"
              type="submit"
              disabled={editLoading}
              style={{
                background: CustomStyle.colors.primary,
                borderColor: CustomStyle.colors.primary,
              }}
            >
              {editLoading ? (
                <Loader
                  type="Oval"
                  color={CustomStyle.colors.white}
                  height={20}
                  width={20}
                />
              ) : (
                "Update"
              )}
            </Button>
          </Form>
        </Modal>
      ) : null}
    </>
  );
}

export default Promos;
