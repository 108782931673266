import React, { useEffect, useState, useContext, useRef } from "react";
import Loader from "react-loader-spinner";
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";
import Modal from "react-modal";
import Axios from "../../utils/axios";
import { Helper } from "helper";
import CustomStyle from "../../styles";
import { AuthContext } from "../../context/auth";
import { showToast } from "utils/general";
import { getCategories } from "services/ourProducts";
import {
  searchOurProducts,
  updateOurProduct,
  getCombos,
} from "services/ourProducts";
import Slider from "react-slick";
import { fillFields } from "services/icgProducts";
import { useProducts } from "./useProducts";
import { AddComboModal, AddComboProductModal } from "./utils";
import { setCombo } from "services/ourProducts";

const customStyles = {
  content: {
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
  },
};

const settings = {
  items: 1,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

Modal.setAppElement("#root");

function ComboProducts() {
  const context = useContext(AuthContext);

  const {
    handleModalChange,
    processedWarehouses,
    modalFormData,
    setModalFormData,
    fetchWarehouseData,
    productIsUpdate,
  } = useProducts();

  // console.log("processedWarehouses >>> ", processedWarehouses);

  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [limit, setLimit] = useState(10);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenProductModal, setIsOpenProductModal] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [modalFetchLoading, setModalFetchLoading] = useState(false);
  const [modalValidated, setModalValidated] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [warehousesStock, setWarehousesStock] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isAllProducts, setIsAllProducts] = useState("false");
  const [totalProductAmount, setTotalProductAmount] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchValidated, setSearchValidated] = useState(false);
  const [combos, setCombos] = useState([]);

  let frontimageFileInput = useRef(null);
  let backimageFileInput = useRef(null);
  let keywordInput = useRef(null);

  useEffect(() => {
    fetchWarehouseData();
    getComboProducts();
    // interpolateStock([]);
  }, []);

  useEffect(() => {
    interpolateStock([]);
  }, [processedWarehouses]);

  useEffect(() => {
    getTotalProductValue();
  }, [selectedProducts]);

  const getComboProducts = async (offSet = 1, S = "", E = "") => {
    setLoading(true);
    const { token } = context.authState;
    await getCombos(
      token,
      offSet,
      limit,
      (res) => {
        setLoading(false);
        if (!res?.status) {
          showToast(
            `Oops. failed to fetch combo products from ${start} to ${end}`
          );
          return;
        }

        console.log("COMBS: ", res?.data?.results);

        setCombos(res?.data?.results || []);
        // setProducts(res?.data?.results || []);
      },
      S,
      E
    );
  };

  const searchProducts = async (keyword) => {
    setLoading(true);
    const { token } = context.authState;
    await searchOurProducts(token, keyword, (res) => {
      setLoading(false);
      if (!res?.status) {
        showToast(`Oops. failed to fetch products with keyword ${keyword}`);
        return;
      }

      setResults(res?.data || []);
      setProducts(res?.data?.results || []);
    });
  };

  const interpolateStock = async (stocks) => {
    const warehouses = processedWarehouses.map((warehouse) => {
      const stockRes = stocks.find(
        (stock) => stock.warehouseCode == warehouse.code
      );
      warehouse = {
        Qty: stockRes?.quantity ?? 0,
        name: warehouse.name,
        WarehouseCode: warehouse.code,
      };
      return warehouse;
    });
    console.log(
      "interpolateStock warehouses >> ",
      processedWarehouses,
      warehouses
    );
    setWarehousesStock(warehouses);
  };

  const closeModal = () => {
    setModalLoading(false);
    setModalValidated(false);
    fillModalInputFields(currentProduct, "reset");
    setIsOpen(false);
  };

  const updateStockValue = async (warehouse, quantity) => {
    // console.log("Update Stock value >>>> ", warehouse, quantity);
    const warehouses = warehousesStock.map((pWarehouse) => {
      // console.log("pWarehouse >>>> ", pWarehouse);
      if (pWarehouse.WarehouseCode === warehouse.WarehouseCode) {
        pWarehouse.Qty = parseInt(quantity == "" ? 0 : quantity);
      }
      return pWarehouse;
    });
    setWarehousesStock(warehouses);
  };

  const handleSearch = async (event) => {
    const keyword = keywordInput ? keywordInput.current.value : null;
    searchProducts(keyword);
  };

  const setComboList = async () => {
    const deptData = [
      ...selectedProducts.map((product) => product?.ItemDeptCode),
    ];

    const deptIDs = Array.from(new Set(deptData));

    // console.log("IDSSS >>> ", deptIDs);

    modalFormData.comboSet = deptIDs;

    const { token } = context.authState;

    console.log("IDSSS >>> ", deptIDs);
    try {
      const payload = { comboSet: deptIDs };

      let response = await Helper.postData(
        `${process.env.REACT_APP_API_V2}/admin/products/`,
        token,
        payload
      );
      console.log("RESPONSE: ", response);
      setIsOpenProductModal(false);
    } catch (error) {
      console.log("ERR: ", error);
    }

    // setIsOpenProductModal(false);
  };

  const addProducts = (selectedOptions) => {
    setSelectedProducts([selectedOptions, ...selectedProducts]);
  };

  const removeProducts = (selectedOptions) => {
    const products = selectedProducts.filter(
      (product) => product._id != selectedOptions._id
    );
    setSelectedProducts(products);
  };

  const getTotalProductValue = () => {
    const value = selectedProducts.reduce(
      (accumulator, product) => accumulator + parseFloat(product.SellingPrice),
      0
    );
    modalFormData.sellingPrice = value;
    setTotalProductAmount(value);
  };

  const showAddComboProductsModal = async () => {
    const { token } = context.authState;
    await getCategories(token, async (res) => {
      if (!!res.status) {
        const categories = res.data.map((cate) => {
          const tree = {
            label: cate.Description,
            value: cate.DeptID,
          };

          return tree;
        });

        setCategories(categories);
      }
    });
  };

  const createComboProducts = async (event) => {
    const form = event.currentTarget;

    if (selectedProducts.length == 0) {
      showToast("Kindly add one or more products to this combo");
      return false;
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setModalValidated(false);
      showToast(
        "Kindly add the required (*) information in the fields provided."
      );
      return false;
    }

    const confirmStock = confirm(
      "Make sure you have added the correct stock to the correct warehouses"
    );

    if (!confirmStock) {
      return;
    }

    if (!modalFormData?.itemid) {
      const theHash = Helper.makeHash(12);
      modalFormData.itemid = theHash;
      modalFormData.reference = theHash;
      modalFormData.gtin = theHash;
    }

    modalFormData.itemDescription = modalFormData.name;
    modalFormData.itemDeptCode = "combo";
    modalFormData.itemDeptName = "combo";
    modalFormData.itemSectionCode = "combo";
    modalFormData.itemSectionName = "combo";

    setModalLoading(true);

    modalFormData.stock = warehousesStock;

    const { token } = context.authState;
    const images = { frontimageFileInput, backimageFileInput };
    await updateOurProduct(combos, modalFormData, images, token, (res) => {
      console.log("Update successful ? ", res?.data);
      setModalLoading(false);
      setModalValidated(true);
      if (!res?.status) {
        showToast(`Oops. Failed to save item`);
        return;
      }

      getComboProducts();
      setCombos([]);
      closeModal();
      showToast("Product created Successful", "success");
    });
  };

  const fetchWarehouseStocks = async (productID) => {
    const { token } = context.authState;
    // setLoading(true);
    try {
      let stockResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/products/stock/${productID}`,
        token
      );
      console.log("STOCK RES:", stockResponse);

      if (stockResponse && stockResponse?.data) {
        // setStocks(stockResponse?.data || []);
        interpolateStock(stockResponse?.data || []);
      }
      // setLoading(false);
    } catch (error) {
      // showToast(`Oops. failed to fetch warehouses`);
      // setLoading(false);
    }
  };

  const editProduct = async (item) => {
    console.log("EDIT ITEM: ", item);
    // pop up edit modal
    setCurrentProduct(item);
    setModalLoading(false);
    setModalValidated(false);
    setIsOpen(true);
  };

  const showProductDetailsModal = async () => {
    if (!!currentProduct) {
      fillModalInputFields(currentProduct, "item");
      await fetchWarehouseStocks(currentProduct?._id);
    }
  };

  const fillModalInputFields = async (data, type) => {
    await fillFields(currentProduct, data, type, async (res) => {
      setModalFormData(res.data?.newFormData);
    });
  };

  const deleteProduct = async (id) => {
    const confirmStock = confirm(
      "Are you sure you want to delete this product?"
    );

    if (!confirmStock) {
      return;
    }
    setSubmitLoading(true);

    try {
      const { token } = context.authState;
      await Axios.delete(
        `${process.env.REACT_APP_API_V2}/admin/products/${id}`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      getComboProducts();
      //   setIsOpen(false);
    } catch (error) {
      showToast(JSON.stringify(error?.response?.data.message));
    }
    setSubmitLoading(false);
  };

  if (loading) {
    return (
      <div
        style={{
          flex: 1,
          textAlign: "center",
          padding: "20px 0px",
        }}
      >
        <Loader
          type="Oval"
          color={CustomStyle.colors.primary}
          height={50}
          width={50}
        />
      </div>
    );
  }

  // console.log("COMBOS: ", combos);
  return (
    <>
      <Container fluid id="MainArea">
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Row>
                  <Col md="9">
                    <p className="card-category">
                      View and modify all products{" "}
                      {/* <span className="text-info">
												(Showing products from {start}{" "}
												to {end})
											</span> */}
                    </p>
                  </Col>
                  <Col md="3">
                    <Button
                      className="btn-fill form-control"
                      type="submit"
                      variant="primary"
                      style={{
                        background: CustomStyle.colors.primary,
                        borderColor: CustomStyle.colors.primary,
                      }}
                      //   add combo will not work if !current prod. which is set only on edit btn click
                      onClick={() => {
                        // setCurrentProduct(combos[0]);
                        // setIsOpen(true);
                        // setIsOpenProductModal

                        setIsOpenProductModal(true);
                      }}
                    >
                      Add Combo
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                {loading ? (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    <Loader
                      type="Oval"
                      color={CustomStyle.colors.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <Table className="table-hover table-striped">
                    <thead>
                      <tr>
                        <th className="border-0">ID</th>
                        <th className="border-0">Name</th>
                        <th className="border-0">Barcode</th>
                        <th className="border-0">Price</th>
                        <th className="border-1"></th>
                        <th className="border-1"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {combos.length > 0 ? (
                        combos.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item?.ItemID}</td>
                              <td>{item?.ItemDescription}</td>
                              <td>{item?.Barcode}</td>
                              <td>
                                {Helper.formatToNaira(item?.SellingPrice)}
                              </td>
                              <td>
                                <Button
                                  className="btn-fill form-control"
                                  type="submit"
                                  variant="primary"
                                  style={{
                                    background: CustomStyle.colors.primary,
                                    borderColor: CustomStyle.colors.primary,
                                  }}
                                  onClick={() => editProduct(item)}
                                >
                                  <i className="nc-icon nc-preferences-circle-rotate"></i>
                                </Button>
                              </td>
                              <td>
                                <Button
                                  onClick={() => deleteProduct(item?._id)}
                                  className="btn-fill form-control"
                                  type="submit"
                                  variant="danger"
                                >
                                  {submitLoading ? (
                                    <Loader
                                      type="Oval"
                                      color={CustomStyle.colors.white}
                                      height={20}
                                      width={20}
                                    />
                                  ) : (
                                    <i className="nc-icon nc-simple-remove"></i>
                                  )}
                                </Button>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td
                            colSpan="6"
                            style={{
                              textAlign: "center",
                              color: CustomStyle.colors.danger,
                            }}
                          >
                            <small>No product found</small>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={showProductDetailsModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Product view Modal"
      >
        <AddComboModal
          currentProduct={currentProduct}
          modalFormData={modalFormData}
          closeModal={closeModal}
          modalLoading={modalLoading}
          handleModalChange={handleModalChange}
          frontimageFileInput={frontimageFileInput}
          backimageFileInput={backimageFileInput}
          modalFetchLoading={modalFetchLoading}
          modalValidated={modalValidated}
          setIsOpenProductModal={setIsOpenProductModal}
          warehousesStock={warehousesStock}
          selectedProducts={selectedProducts}
          updateStockValue={updateStockValue}
          createComboProducts={createComboProducts}
          productIsUpdate={productIsUpdate}
        />
      </Modal>

      <Modal
        isOpen={modalIsOpenProductModal}
        onAfterOpen={() => showAddComboProductsModal()}
        onRequestClose={() => setIsOpenProductModal(false)}
        style={customStyles}
        contentLabel="Add Products to Combo Modal"
      >
        <AddComboProductModal
          totalProductAmount={totalProductAmount}
          selectedProducts={selectedProducts}
          removeProducts={removeProducts}
          setIsOpenProductModal={setIsOpenProductModal}
          submitLoading={submitLoading}
          setComboList={setComboList}
          searchValidated={searchValidated}
          handleSearch={handleSearch}
          keywordInput={keywordInput}
          isAllProducts={isAllProducts}
          loading={loading}
          products={products}
          addProducts={addProducts}
        />
      </Modal>
    </>
  );
}

export default ComboProducts;
