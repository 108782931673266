import React, { useContext, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Loader from "react-loader-spinner";
import Modal from "react-modal";
import { AuthContext } from "../../context/auth";
import { Helper } from "../../helper";
import CustomStyle from "../../styles";
import Axios from "../../utils/axios";
import { showToast } from "utils/general";

const customStyles = {
	content: {
		top: "50%",
		left: "50%",
		right: "auto",
		bottom: "auto",
		marginRight: "-50%",
		transform: "translate(-50%, -50%)",
	},
};

Modal.setAppElement("#root");

export function OwnerDropdown({ item }) {
	const [isToggled, setIsToggled] = useState(false);
	return (
		<>
			<b
				className="text-info ml-1"
				role="button"
				onClick={() => {
					isToggled ? setIsToggled(false) : setIsToggled(true);
				}}
				style={{
					alignItems: "center",
					textTransform: "capitalize",
				}}
			>
				{item?.userId?.name}{" "}
				{isToggled ? (
					<i className="nc-icon ml-1 nc-stre-up"></i>
				) : (
					<i className="nc-icon ml-1 nc-stre-down"></i>
				)}
			</b>
			<div className={`collapse ${isToggled ? "show" : ""}`}>
				<div className="card card-body">
					<div>
						<span>Email: </span>
						<b>{item?.userId?.email || "unknown"}</b>
					</div>
					<div>
						<span>Phone: </span>
						<b>{item?.userId?.phone || "unknown"}</b>
					</div>
				</div>
			</div>
		</>
	);
}

export function ProductDropdown({ item }) {
	const [isToggled, setIsToggled] = useState(false);
	return (
		<>
			<b
				className="text-info ml-1"
				role="button"
				onClick={() => {
					isToggled ? setIsToggled(false) : setIsToggled(true);
				}}
				style={{
					alignItems: "center",
				}}
			>
				{item?.cart.length} Product
				{item?.cart.length > 1 ? "s " : " "}
				{isToggled ? (
					<i className="nc-icon ml-1 nc-stre-up"></i>
				) : (
					<i className="nc-icon ml-1 nc-stre-down"></i>
				)}
			</b>
			<div className={`collapse ${isToggled ? "show" : ""}`}>
				<div className="card card-body p-0">
					<ul className="list-group">
						{item?.cart.map((itm, i) => {
							return (
								<li
									key={i.toString()}
									className="list-group-item d-flex justify-content-between align-items-center"
								>
									<div className="media">
										<img
											src={itm?.productImg}
											style={{ width: 50, height: 50 }}
										/>
										<div className="media-content pl-2">
											<div>
												<span>Name: </span>
												<b>
													{itm?.productName ||
														"unknown"}
												</b>
											</div>
											<div>
												<span>Barcode: </span>
												<b>{itm?.barcode}</b>
											</div>
											<div>
												<span>Quantity: </span>
												<b>x{itm?.quantity || 0}</b>
											</div>
											<div>
												<span>Price: </span>
												<b>
													{Helper.formatToNaira(
														itm?.price || 0
													)}
												</b>
											</div>
										</div>
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</>
	);
}

export function Remark({ user }) {
	const [deleteData, setDeleteData] = useState({});
	const [modalLoading, setModalLoading] = useState(false);
	const context = useContext(AuthContext);

	let remarkInput = useRef(null);

	const sendRemark = (user) => {
		const remark = remarkInput ? remarkInput.current.value : null;
		if (!remark || remark == "") {
			showToast("Please write a remark to send");
			return false;
		}

		setDeleteData(user);
	};

	const handleRemarkSubmit = async (event) => {
		// send data
		setModalLoading(true);
		try {
			const { token } = context.authState;
			let response = await Axios.post(
				`${process.env.REACT_APP_API_V2}/admin/users/remark`,
				{
					users: [deleteData?._id],
					remark:
						remarkInput &&
						remarkInput.current &&
						remarkInput.current.value
							? remarkInput.current.value
							: "",
				},
				{
					headers: {
						"x-access-token": token,
					},
				}
			);

			const { status } = response?.data;

			if (status && status == "success") {
				showToast("Remark sent successfully", "success");
				closeModal();
				remarkInput.current.value = "";
			} else {
				showToast("Oops. Failed to delete item");
			}
			setModalLoading(false);
		} catch (error) {
			showToast("`Oops. something went wrong while deleting item");
			setModalLoading(false);
		}
	};

	const closeModal = () => {
		setModalLoading(false);
		setDeleteData({});
	};

	if (!user || !user?._id) {
		return <></>;
	}

	return (
		<>
			<Container fluid>
				<Row>
					<Col md="12">
						<Form.Group>
							<Form.Control
								cols="80"
								placeholder="Write your remarks here"
								rows="4"
								as="textarea"
								ref={remarkInput}
							></Form.Control>
						</Form.Group>
						<Button
							className="btn btn-primary btn-fill"
							type="button"
							variant="primary"
							style={{
								background: CustomStyle.colors.primary,
								borderColor: CustomStyle.colors.primary,
							}}
							onClick={async () => sendRemark(user)}
						>
							Send Remark
						</Button>
					</Col>
				</Row>
			</Container>
			<Modal
				isOpen={deleteData && deleteData?._id ? true : false}
				onRequestClose={() => closeModal()}
				style={customStyles}
				contentLabel="Delete Modal"
			>
				{deleteData ? (
					<>
						<h5
							style={{
								textTransform: "capitalize",
								display: "flex",
								alignItems: "center",
							}}
						>
							<b>Send Remark</b>
						</h5>
						<br />
						<p className="pb-3">
							Are you sure you want to send{" "}
							<b>
								"
								{remarkInput &&
								remarkInput.current &&
								remarkInput.current.value
									? remarkInput.current.value
									: ""}
							</b>
							" to{" "}
							<b style={{ textTransform: "capitalize" }}>
								{" "}
								{deleteData?.name}
							</b>
							?
						</p>
						<Row>
							<Col className="" md="4" />
							<Col className="" md="4">
								<Form.Group>
									<label htmlFor=""></label>
									<Button
										onClick={() => closeModal()}
										className="btn-fill form-control pull-right"
										variant="default"
										type="button"
									>
										Close
									</Button>
								</Form.Group>
							</Col>
							<Col className="" md="4">
								<Form.Group>
									<label htmlFor=""></label>
									<Button
										className="btn-fill form-control pull-right"
										variant="primary"
										type="button"
										disabled={modalLoading}
										onClick={handleRemarkSubmit}
									>
										{modalLoading ? (
											<Loader
												type="Oval"
												color={CustomStyle.colors.white}
												height={20}
												width={20}
											/>
										) : (
											"Send"
										)}
									</Button>
								</Form.Group>
							</Col>
						</Row>
					</>
				) : null}
			</Modal>
		</>
	);
}
