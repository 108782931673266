import React, { useEffect, useState, useContext, useRef } from "react";
import Loader from "react-loader-spinner";
import moment from "moment";
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import Modal from "react-modal";
import Axios from "../utils/axios";

import ReactPaginate from "react-paginate";

import { Helper } from "../helper";
import CustomStyle from "../styles";
import { AuthContext } from "../context/auth";
import { DashboardContext, SET_ACTIVE_FEED } from "../context/dashboard";
import { showToast } from "utils/general";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
function Feeds() {
  const [loading, setLoading] = useState(true);
  const [imgIsFull, setImgIsFull] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [feeds, setFeeds] = useState({});
  const [results, setResults] = useState([]);
  const [id, setId] = useState(0);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaUrlType, setMediaUrlType] = useState("");
  const [limit, setLimit] = useState(10);
  const [formValidated, setFormValidated] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const { dispatch } = useContext(DashboardContext);
  const context = useContext(AuthContext);
  const dashboardContext = useContext(DashboardContext);

  const { activeFeed } = dashboardContext.dashboardState || {
    activeFeed: {},
  };

  // for inputs
  let titleInput = useRef(null);
  let bodyInput = useRef(null);
  let mediaInput = useRef(null);

  useEffect(() => {
    // empty active feed
    // if (Helper.isPopulator(context.authState?.admin)) {
    //   return false;
    // }

    dispatch({
      type: SET_ACTIVE_FEED,
      payload: {},
    });

    // get data from Our database
    fetchData();
  }, []);

  useEffect(() => {
    if (activeFeed && activeFeed?._id) {
      setId(activeFeed?._id || 0);
      setTitle(activeFeed?.title || "");
      setBody(activeFeed?.body || "");
      setMediaUrl(activeFeed?.media || "");
      setMediaUrlType(activeFeed?.mediaType || "");

      setFormValidated(false);
    } else {
      setId(0);
      setTitle("");
      setBody("");
      setMediaUrl("");
      setMediaUrlType("");
      setFormValidated(false);
    }

    mediaInput = null;
    setImgIsFull(false);
  }, [feeds, activeFeed]);

  const fetchData = async (offSet = 1) => {
    const { token } = context.authState;
    setLoading(true);
    try {
      let feedsResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/feeds?limit=${limit}&page=${offSet}`,
        token
      );

      if (feedsResponse && feedsResponse?.data) {
        setFeeds(feedsResponse?.data || []);
        setResults(feedsResponse?.data?.results || []);
      }
      setLoading(false);
    } catch (error) {
      showToast("Oops. failed to fetch feeds");
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setFormValidated(false);
    } else {
      // send data
      setSubmitLoading(true);
      const formData = new FormData();

      formData.append("title", title);
      formData.append("body", body || " ");

      if (mediaInput.current && mediaInput.current.files[0]) {
        formData.append("media", mediaInput.current.files[0]);
      }

      try {
        const { token } = context.authState;
        let response = await Axios.post(
          `${process.env.REACT_APP_API_V2}/admin/feeds`,
          formData,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );

        const { data } = response?.data;

        if (data && data?._id) {
          showToast("Feed Created", "success");
          const updatedFeeds = [data].concat(results); // [ 4, 3, 2, 1 ]
          setResults(updatedFeeds);
        } else {
          showToast("Oops. Failed to save item");
        }
        setSubmitLoading(false);
      } catch (error) {
        showToast("Oops. something went wrong while saving item");
        setSubmitLoading(false);
      }
    }

    setFormValidated(true);
  };

  const handleUpdateSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setFormValidated(false);
    } else {
      // send data
      setSubmitLoading(true);
      const formData = new FormData();

      formData.append("id", id);
      formData.append("title", title);
      formData.append("body", body || " ");

      if (mediaInput.current && mediaInput.current.files[0]) {
        formData.append("media", mediaInput.current.files[0]);
      } else {
        formData.append("media", mediaUrl);
        formData.append("mediaType", mediaUrlType);
      }

      try {
        const { token } = context.authState;
        let response = await Axios.patch(
          `${process.env.REACT_APP_API_V2}/admin/feeds`,
          formData,
          {
            headers: {
              "x-access-token": token,
            },
          }
        );

        const { data } = response?.data;

        if (data && data?._id) {
          showToast("Feed Updated", "success");
          const index = results
            ? results?.findIndex((p) => p?._id == data?._id)
            : -1;
          if (index > -1) {
            const updatedFeeds = results;
            const updated = {
              ...updatedFeeds[index],
              title: data?.title,
              body: data?.body,
              media: data?.media,
              mediaType: data?.mediaType,
              mediaType: data?.mediaType,
              updatedAt: data?.updatedAt,
            };
            updatedFeeds[index] = updated;
            setResults([...updatedFeeds]);
            dispatch({
              type: SET_ACTIVE_FEED,
              payload: data,
            });
          }
        } else {
          showToast("Oops. Failed to save item");
        }
        setSubmitLoading(false);
      } catch (error) {
        showToast("Oops. something went wrong while saving item");
        setSubmitLoading(false);
      }
    }

    setFormValidated(true);
  };

  const handleDeleteSubmit = async (event) => {
    // send data
    setModalLoading(true);
    try {
      const { token } = context.authState;
      let response = await Axios.delete(
        `${process.env.REACT_APP_API_V2}/admin/feeds/${deleteData?._id}`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { status } = response?.data;

      if (status && status == "success") {
        showToast("Feed Deleted", "success");

        let array = [...results]; // make a separate copy of the array
        const index = results ? results?.indexOf(deleteData) : -1;
        if (index !== -1) {
          array.splice(index, 1);
          setResults(array);
        }
        closeModal();
      } else {
        showToast("Oops. Failed to delete item");
      }
      setModalLoading(false);
    } catch (error) {
      showToast("Oops. something went wrong while deleting item");
      setModalLoading(false);
    }
  };

  const handlePageClick = (data) => {
    let selected = data.selected + 1;

    fetchData(selected);
  };

  const closeModal = () => {
    setModalLoading(false);
    setDeleteData({});
  };

  if (
    Helper.isFinance(context.authState?.admin) ||
    // Helper.isPopulator(context.authState?.admin) ||
    Helper.isAdministrator(context.authState?.admin)
  ) {
    return (
      <div style={{ textAlign: "center" }}>
        <small>You are not permitted to view this page</small>
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          {activeFeed && activeFeed?._id ? (
            <Col md="6">
              <Form
                noValidate
                validated={formValidated}
                onSubmit={(event) => {
                  event.preventDefault();
                  handleUpdateSubmit(event);
                }}
              >
                <Card className="card-tasks">
                  {activeFeed?.mediaType == "image" ? (
                    <div className={`img-holder ${imgIsFull ? "full" : ""}`}>
                      <img src={activeFeed?.media} />
                      <div className="control">
                        <Button
                          className="btn-fill pull-right"
                          variant="primary"
                          type="button"
                          style={{
                            background: CustomStyle.colors.primary,
                            borderColor: CustomStyle.colors.primary,
                          }}
                          onClick={() => {
                            imgIsFull
                              ? setImgIsFull(false)
                              : setImgIsFull(true);
                          }}
                        >
                          {imgIsFull ? (
                            <i className="nc-icon nc-stre-up"></i>
                          ) : (
                            <i className="nc-icon nc-stre-down"></i>
                          )}
                        </Button>
                      </div>
                    </div>
                  ) : activeFeed?.mediaType == "video" ? (
                    <div className={`img-holder ${imgIsFull ? "full" : ""}`}>
                      <video controls src={activeFeed?.media} />
                      <div className="control">
                        <Button
                          className="btn-fill pull-right"
                          variant="primary"
                          type="button"
                          style={{
                            background: CustomStyle.colors.primary,
                            borderColor: CustomStyle.colors.primary,
                          }}
                          onClick={() => {
                            imgIsFull
                              ? setImgIsFull(false)
                              : setImgIsFull(true);
                          }}
                        >
                          {imgIsFull ? (
                            <i className="nc-icon nc-stre-up"></i>
                          ) : (
                            <i className="nc-icon nc-stre-down"></i>
                          )}
                        </Button>
                      </div>
                    </div>
                  ) : null}
                  <Card.Header>
                    <Card.Title as="h4">
                      Edit{" "}
                      <b style={{ textTransform: "capitalize" }}>
                        {activeFeed?.title}
                      </b>
                    </Card.Title>
                    <p className="card-category">
                      <small className="text-muted">
                        Updated {moment(activeFeed?.updatedAt).fromNow()}
                      </small>
                    </p>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label htmlFor="">Feed Title</label>
                          <Form.Control
                            placeholder="Title"
                            type="text"
                            required
                            ref={titleInput}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          ></Form.Control>
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Please enter feed title
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label htmlFor="">Feed Content</label>
                          <Form.Control
                            placeholder="Write here.."
                            type="text"
                            ref={bodyInput}
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                            rows="4"
                            as="textarea"
                          ></Form.Control>
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Please write some content
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label htmlFor="">Media</label>
                          <Form.File
                            label="Attach A Media File (Image, Video, Gif, etc)"
                            custom
                            ref={mediaInput}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Please upload media.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <Button
                      className="btn-fill form-control pull-right"
                      variant="info"
                      type="submit"
                      disabled={submitLoading}
                    >
                      {submitLoading ? (
                        <Loader
                          type="Oval"
                          color={CustomStyle.colors.white}
                          height={20}
                          width={20}
                        />
                      ) : (
                        "Update"
                      )}
                    </Button>
                    <Button
                      className="btn mt-2 py-1 form-control pull-right"
                      variant="dark"
                      type="button"
                      style={{
                        background: "transparent",
                        borderColor: "transparent",
                      }}
                      onClick={() => {
                        dispatch({
                          type: SET_ACTIVE_FEED,
                          payload: {},
                        });
                      }}
                    >
                      Or Create A Feed
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          ) : (
            <Col md="6">
              <Form
                noValidate
                validated={formValidated}
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit(event);
                }}
              >
                <Card className="card-tasks">
                  <Card.Header>
                    <Card.Title as="h4">Create A Feed</Card.Title>
                    <p className="card-category"></p>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="12">
                        <Form.Group>
                          <label htmlFor="">Title</label>
                          <Form.Control
                            placeholder="Title"
                            type="text"
                            required
                            ref={titleInput}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          ></Form.Control>
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Please enter feed title
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label htmlFor="">Content</label>
                          <Form.Control
                            placeholder="Write here.."
                            type="text"
                            ref={bodyInput}
                            value={body}
                            onChange={(e) => setBody(e.target.value)}
                            rows="4"
                            as="textarea"
                          ></Form.Control>
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Please write some content
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col md="12">
                        <Form.Group>
                          <label htmlFor="">Media</label>
                          <Form.File
                            label="Attach A Media File (Image, Video, Gif, etc)"
                            custom
                            ref={mediaInput}
                          />
                          <Form.Control.Feedback>
                            Looks good!
                          </Form.Control.Feedback>
                          <Form.Control.Feedback type="invalid">
                            Please upload media.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <Button
                      className="btn-fill form-control pull-right"
                      variant="primary"
                      type="submit"
                      disabled={submitLoading}
                      style={{
                        background: CustomStyle.colors.primary,
                        borderColor: CustomStyle.colors.primary,
                      }}
                    >
                      {submitLoading ? (
                        <Loader
                          type="Oval"
                          color={CustomStyle.colors.white}
                          height={20}
                          width={20}
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          )}
          <Col md="6">
            <Card className="card-tasks">
              <Card.Header>
                <Card.Title as="h4">All feeds</Card.Title>
                <p className="card-category">Backend development</p>
              </Card.Header>
              <Card.Body>
                {loading ? (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    <Loader
                      type="Oval"
                      color={CustomStyle.colors.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <div className="table-full-width">
                    <Table>
                      <tbody>
                        {results && results?.length > 0 ? (
                          results?.map((item, index) => {
                            return (
                              <tr>
                                <td>
                                  <b style={{ textTransform: "capitalize" }}>
                                    {item?.title}
                                  </b>
                                  <br />
                                  {Helper.truncateWords(item?.body, 9)}{" "}
                                </td>
                                <td className="td-actions text-right">
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-938342127">
                                        Edit Feed
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className="btn-simple btn-link p-1"
                                      type="button"
                                      variant="info"
                                      onClick={async () => {
                                        await dispatch({
                                          type: SET_ACTIVE_FEED,
                                          payload: {},
                                        });
                                        dispatch({
                                          type: SET_ACTIVE_FEED,
                                          payload: item,
                                        });
                                      }}
                                    >
                                      <i className="fas fa-edit"></i>
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-938342127">
                                        Delete Feed
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className="btn-simple btn-link p-1"
                                      type="button"
                                      variant="danger"
                                      onClick={async () => {
                                        setDeleteData(item);
                                      }}
                                    >
                                      <i className="fas fa-trash"></i>
                                    </Button>
                                  </OverlayTrigger>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan="6"
                              style={{
                                textAlign: "center",
                                color: CustomStyle.colors.danger,
                              }}
                            >
                              <small>No feed found</small>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card.Body>
              <Card.Footer>
                {feeds && feeds?.total ? (
                  <ReactPaginate
                    previousLabel={<i className="nc-icon nc-stre-left"></i>}
                    nextLabel={<i className="nc-icon nc-stre-right"></i>}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={feeds?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={deleteData && deleteData?._id ? true : false}
        onRequestClose={() => closeModal()}
        style={customStyles}
        contentLabel="Delete Modal"
      >
        {deleteData ? (
          <>
            <h5
              style={{
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
              }}
            >
              <i className="fas fa-trash"></i>{" "}
              <b className="ml-2">Delete "{deleteData?.title}"</b>
            </h5>
            <br />
            <p className="pb-3">
              Are you sure you want to delete {deleteData?.title}?
            </p>
            <Row>
              <Col className="" md="4" />
              <Col className="" md="4">
                <Form.Group>
                  <label htmlFor=""></label>
                  <Button
                    onClick={() => closeModal()}
                    className="btn-fill form-control pull-right"
                    variant="default"
                    type="button"
                  >
                    Close
                  </Button>
                </Form.Group>
              </Col>
              <Col className="" md="4">
                <Form.Group>
                  <label htmlFor=""></label>
                  <Button
                    className="btn-fill form-control pull-right"
                    variant="danger"
                    type="button"
                    disabled={modalLoading}
                    onClick={handleDeleteSubmit}
                  >
                    {modalLoading ? (
                      <Loader
                        type="Oval"
                        color={CustomStyle.colors.white}
                        height={20}
                        width={20}
                      />
                    ) : (
                      "Delete"
                    )}
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </>
        ) : null}
      </Modal>
    </>
  );
}

export default Feeds;
