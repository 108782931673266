import axios from "axios";
import { toast } from "react-toastify";

// const instance = axios.create({
//   baseURL: `${process.env.REACT_APP_API_V2}`,
// });
const instance = axios.create();

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // check if not authenticated and not on login page
    if (
      error?.response?.status === 401 &&
      window.location.href.indexOf("login") <= -1
    ) {
      window.location = "/admin";

      toast("Oops!, Session as ended", {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      localStorage.setItem("auth", "");
    }
    return Promise.reject(error);
  }
);

export default instance;
