import React, { useContext } from "react";
import Dashboard from "views/Dashboard.js";
import Products from "views/Products";
import Orders from "views/Orders";
import Remarks from "views/Remarks.js";
import Users from "views/Users.js";
import Warehouses from "views/Warehouses.js";
import Feeds from "views/Feeds.js";
import Promos from "views/Promos.js";
import Promo from "views/Promo.js";
import Preferences from "views/Preferences.js";
import Loyalty from "views/Loyalty.js";
import Categories from "views/Categories";
import Cart from "views/Cart";
import Coupons from "views/Coupons";
import Finance from "views/Finance";
import SingleFinance from "views/SingleFinance";
import Admins from "views/Admins";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-36",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/admins",
    name: "Admins",
    icon: "nc-icon nc-circle-09",
    component: Admins,
    layout: "/admin",
  },
  {
    path: "/users",
    name: "Users",
    icon: "nc-icon nc-circle-09",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "nc-icon nc-delivery-fast",
    component: Orders,
    layout: "/admin",
  },
  {
    path: "/finance",
    name: "Finance",
    icon: "nc-icon nc-money-coins",
    component: Finance,
    layout: "/admin",
  },
  {
    path: "/finance/:id",
    name: "Finance",
    icon: "nc-icon nc-money-coins",
    component: SingleFinance,
    layout: "/admin",
  },
  {
    path: "/remarks/:id",
    name: "Remarks",
    icon: "nc-icon nc-send",
    component: Remarks,
    layout: "/admin",
  },
  {
    path: "/remarks",
    name: "Remarks",
    icon: "nc-icon nc-send",
    component: Remarks,
    layout: "/admin",
  },
  {
    path: "/products",
    name: "Products",
    icon: "nc-icon nc-layers-3",
    component: Products,
    layout: "/admin",
  },
  {
    path: "/categories",
    name: "Categories",
    icon: "nc-icon nc-app",
    component: Categories,
    layout: "/admin",
  },
  {
    path: "/warehouses",
    name: "Warehouses",
    icon: "nc-icon nc-square-pin",
    component: Warehouses,
    layout: "/admin",
  },
  {
    path: "/cart",
    name: "Abandoned Cart",
    icon: "nc-icon nc-chart-pie-36",
    component: Cart,
    layout: "/admin",
  },
  {
    path: "/feeds",
    name: "Feeds",
    icon: "nc-icon nc-single-copy-04",
    component: Feeds,
    layout: "/admin",
  },
  {
    path: "/coupons",
    name: "Coupons",
    icon: "nc-icon nc-support-17",
    component: Coupons,
    layout: "/admin",
  },
  {
    path: "/promos",
    name: "Promos",
    icon: "nc-icon nc-spaceship",
    component: Promos,
    layout: "/admin",
  },
  {
    path: "/promos/:id",
    name: "Promo",
    icon: "nc-icon nc-spaceship",
    component: Promo,
    layout: "/admin",
  },
  {
    path: "/loyalty",
    name: "Loyalty",
    icon: "nc-icon nc-layers-3",
    component: Loyalty,
    layout: "/admin",
  },
  {
    path: "/preferences",
    name: "Preferences",
    icon: "nc-icon nc-preferences-circle-rotate",
    component: Preferences,
    layout: "/admin",
  },
];

export default dashboardRoutes;
