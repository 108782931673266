import { SET_WAREHOUSES, SET_ACTIVE_WAREHOUSE, SET_ACTIVE_FEED } from "./types";

// Reducer
export const dashboardReducer = (state, action) => {
	switch (action.type) {
		case SET_WAREHOUSES:
			return {
				...state,
				warehouses: action.payload,
			};
		case SET_ACTIVE_WAREHOUSE:
			return {
				...state,
				activeWarehouse: action.payload,
			};
		case SET_ACTIVE_FEED:
			return {
				...state,
				activeFeed: action.payload,
			};
		default:
			return state;
	}
};
