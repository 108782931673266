import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import moment from "moment";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import Axios from "../utils/axios";

import { Helper } from "../helper";
import CustomStyle from "../styles";
import { AuthContext } from "../context/auth";
import { DashboardContext } from "../context/dashboard";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Preferences() {
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [searchValidated, setSearchValidated] = useState(false);
  const [appData, setAppData] = useState({});
  const context = useContext(AuthContext);
  const dashboardContext = useContext(DashboardContext);
  let keyInput = useRef(null);

  useEffect(() => {
    // get data from Our database
    if (
      Helper.isPopulator(context.authState?.admin) ||
      Helper.isFinance(context.authState?.admin) ||
      Helper.isAdministrator(context.authState?.admin)
    ) {
      return false;
    }

    fetchData();
  }, []);

  const fetchData = async (offSet = 1) => {
    const { token } = context.authState;
    setLoading(true);
    try {
      let appDatasResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/app-data`,
        token
      );

      if (appDatasResponse && appDatasResponse?.data) {
        setAppData(appDatasResponse?.data || []);
      }
      setLoading(false);
    } catch (error) {
      toast(`Oops. failed to fetch appDatas`, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  const handleSearch = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setSearchValidated(false);
    } else {
      const key = keyInput ? keyInput.current.value : null;
      handleUpdate(key);
    }

    setSearchValidated(true);
  };

  const handleUpdate = async (string) => {
    try {
      const { token } = context.authState;

      setSubmitLoading(true);
      let response = await Axios.post(
        `${process.env.REACT_APP_API_V2}/admin/app-data`,
        { paystackPk: string },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { data } = response?.data;

      if (data) {
        toast("Paystack key updated", {
          position: "top-right",
          type: "success",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setAppData(data);
      } else {
        toast(`Oops. Failed to save item`, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setSubmitLoading(false);
    } catch (error) {
      toast(`Oops. something went wrong while saving item`, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setSubmitLoading(false);
    }
  };

  if (
    Helper.isPopulator(context.authState?.admin) ||
    Helper.isFinance(context.authState?.admin) ||
    Helper.isAdministrator(context.authState?.admin)
  ) {
    return (
      <div style={{ textAlign: "center" }}>
        <small>You are not permitted to view this page</small>
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-tasks">
              <Card.Header>
                <Card.Title as="h4">Preferences</Card.Title>
                <p className="card-category">Backend development</p>
              </Card.Header>
              <Card.Body>
                {loading ? (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    <Loader
                      type="Oval"
                      color={CustomStyle.colors.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <div className="">
                    <Form
                      className="container pb-2 3"
                      noValidate
                      validated={searchValidated}
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSearch(e);
                      }}
                    >
                      <small>
                        Paystack Public Key:{"  "}
                        <b
                          className="text-muted"
                          style={{ textTransform: "lowercase" }}
                        >
                          {!!appData?.paystackPk
                            ? String(
                                Helper.truncateString(
                                  appData?.paystackPk,
                                  appData?.paystackPk.length - 20,
                                  "********************"
                                )
                              )
                            : null}
                        </b>
                      </small>
                      {Helper.isAdministrator(
                        context.authState?.admin
                      ) ? null : (
                        <Row>
                          <Col md="6">
                            <Form.Group>
                              <Form.Control
                                placeholder="Enter New Paystack Key"
                                type="text"
                                required
                                ref={keyInput}
                              ></Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please enter a key.
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col md="1">
                            <Form.Group>
                              {submitLoading ? (
                                <Loader
                                  type="Oval"
                                  color={CustomStyle.colors.primary}
                                  height={40}
                                  width={40}
                                />
                              ) : (
                                <Button
                                  className="btn-fill form-control"
                                  type="submit"
                                  variant="primary"
                                  style={{
                                    background: CustomStyle.colors.primary,
                                    borderColor: CustomStyle.colors.primary,
                                  }}
                                >
                                  <i className="nc-icon nc-send"></i>
                                </Button>
                              )}
                            </Form.Group>
                          </Col>
                        </Row>
                      )}
                    </Form>
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Preferences;
