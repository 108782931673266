import React, { useEffect, useState, useContext } from "react";
import { useLocation, Route, Switch } from "react-router-dom";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import Promo from "../views/Promo.js";
import { Helper } from "../helper";
import AllRoutes from "../routes";

import { AuthContext } from "../context/auth";

import sidebarImage from "assets/img/sidebar-1.png";

function Admin() {
  const context = useContext(AuthContext);

  const [routes, setRoutes] = useState(
    AllRoutes.filter((itm) => !itm?.path.includes(":id")).map((itm, i) => {
      let isDisabled = true;

      // give super permissions
      if (Helper.isSuper(context.authState?.admin)) {
        isDisabled = false;
      }

      // give admin permissions
      if (Helper.isAdministrator(context.authState?.admin)) {
        // if (itm?.name == "Admins" || itm?.name == "Users") {
        //   isDisabled = true;
        // } else {
        //   isDisabled = false;
        // }

        if (itm?.name == "Finance" || itm?.name == "Orders") {
          isDisabled = false;
        }
      }

      // give populator permissions
      if (Helper.isPopulator(context.authState?.admin)) {
        // give only products permission
        if (
          itm?.name == "Products" ||
          itm?.name == "Feeds" ||
          itm?.name == "Promos" ||
          itm?.name == "Users" ||
          itm?.name == "Dashboard" ||
          itm?.name == "Orders"
        ) {
          // if (itm?.name == "Products") {
          isDisabled = false;
        }
      }

      // HERE
      if (Helper.isFinance(context.authState?.admin)) {
        // give only products permission
        if (
          itm?.name == "Finance" ||
          itm?.name == "Orders" ||
          itm?.name == "Users"
        ) {
          isDisabled = false;
        }
      }

      if (Helper.isAnalyst(context.authState?.admin)) {
        if (
          itm?.name == "Products" ||
          itm?.name == "Orders" ||
          itm?.name == "Users"
        ) {
          isDisabled = false;
        }
      }

      return {
        path: itm?.path,
        name: itm?.name,
        icon: itm?.icon,
        component: itm?.component,
        layout: itm?.layout,
        disabled: isDisabled,
      };
    })
  );
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const location = useLocation();
  const mainPanel = React.useRef(null);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            exact
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      var element = document.getElementById("bodyClick");
      element.parentNode.removeChild(element);
    }
  }, [location]);
  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ""} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Switch>
              {getRoutes(AllRoutes)}
              {/* <Route
                exact
                path="/admin/promo/:id"
                render={(props) => <Promo {...props} />}
              /> */}
            </Switch>
          </div>
          <Footer />
        </div>
      </div>
      <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      />
    </>
  );
}

export default Admin;
