import { showToast } from "utils/general";
import { Helper } from "../helper";

export const getICGProducts = async (sD, eD, callback) => {
	try {
		let response = await Helper.getData(
			`${process.env.REACT_APP_API_ICG}/GetAllItemsWithNewDetails/${sD}/${eD}`
		);

		callback({ status: true, data: response });
	} catch (error) {
		callback({ status: false });
	}
};

export const searchICGProducts = async (keyword, callback) => {
	try {
		let response = await Helper.getData(
			`${process.env.REACT_APP_API_ICG}/SearchItemWithNoelPhinData/${keyword}`
		);

		callback({ status: true, data: response });
	} catch (error) {
		callback({ status: false });
	}
};

export const fillFields = (currentProduct, data, type, callback) => {
	// console.log("currentProduct?.Categories >>. ", currentProduct)
	let newFormData = {};
	if (type == "item") {
		newFormData = {
			id: currentProduct?._id,
			itemid: currentProduct?.ItemID,
			name: currentProduct?.Name,
			itemDescription: currentProduct?.ItemDescription,
			gtin: currentProduct?.Barcode,
			brandname: currentProduct?.BrandName,
			category: currentProduct?.Category,
			categories: currentProduct?.Categories,
			package: currentProduct?.Package,
			labeldescription: currentProduct?.LabelDescription,
			marketinginformation: currentProduct?.MarketingInformation,
			frontimage: currentProduct?.FrontImage,
			backimage: currentProduct?.BackImage,
			unitofmeasurement: currentProduct?.UnitOfMeasurement,
			grossweight: currentProduct?.GrossWeight,
			netweight: currentProduct?.NetWeight,
			expiration: currentProduct?.Expiration,
			brandOwner: currentProduct?.BrandOwner,
			address: currentProduct?.Address,
			moName: currentProduct?.MoName,
			sellingPrice: currentProduct?.SellingPrice,
			reference: currentProduct?.Reference,
			itemDeptCode: currentProduct?.ItemDeptCode,
			itemDeptName: currentProduct?.ItemDeptName,
			itemSectionCode: currentProduct?.ItemSectionCode,
			itemSectionName: currentProduct?.ItemSectionName,
		};
	} else if (type == "gs1") {
		newFormData = {
			itemid: currentProduct?.ItemID,
			name: data?.name,
			gtin: currentProduct?.Barcode,
			brandname: data?.brandname,
			category: data?.category,
			package: data?.package,
			labeldescription: data?.labeldescription,
			marketinginformation: data?.marketinginformation,
			frontimage: data?.frontimage,
			backimage: data?.backimage,
			unitofmeasurement: data?.unitofmeasurement,
			grossweight: data?.grossweight,
			netweight: data?.netweight,
			expiration: data?.expiration,
			brandOwner: data?.brandOwner,
			address: data?.address,
			moName: data?.moName,
			sellingPrice: data?.SellingPrice,
			reference: data?.Reference,
			itemDeptCode: currentProduct?.ItemDeptCode,
			itemDeptName: currentProduct?.ItemDeptName,
			itemSectionCode: currentProduct?.ItemSectionCode,
			itemSectionName: currentProduct?.ItemSectionName,
		};
	} else if (type == "reset") {
		newFormData = {
			itemid: currentProduct?.ItemID,
			name: "",
			gtin: "",
			brandname: "",
			category: "",
			package: "",
			labeldescription: "",
			marketinginformation: "",
			frontimage: "",
			backimage: "",
			unitofmeasurement: "",
			grossweight: "",
			netweight: "",
			expiration: "",
			brandOwner: "",
			address: "",
			moName: "",
			sellingPrice: "",
			reference: "",
			itemDeptCode: "",
			itemDeptName: "",
			itemSectionCode: "",
			itemSectionName: "",
		};
	}

	console.log("Form Data >> ", newFormData);

	callback({
		status: true,
		data: {
			newFormData,
			frontimage: data?.frontimage,
			backimage: data?.backimage,
		},
	});
};

export const getICGWarehouses = async (callback) => {
	try {
		let response = await Helper.getData(
			`${process.env.REACT_APP_API_ICG}/GetAllWarehouse`
		);

		callback({ status: true, data: response });
	} catch (error) {
		callback({ status: false });
	}
};
