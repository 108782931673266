import {
  LOGIN,
  LOGOUT,
  SIGNUP,
  UPDATE,
  ADMIN_GET_USER_CAMPAIGN,
} from './types';

// Reducer
export const authReducer = (state, action) => {
  switch (action.type) {
    case SIGNUP:
      return action.payload;
    case LOGIN:
      return action.payload;
    // case UPDATE:
    //   return {
    //     ...state,
    //     user: {
    //       ...state.user,
    //       ...action.payload,
    //     },
    //   }
    case UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case LOGOUT:
      return null;
    case ADMIN_GET_USER_CAMPAIGN:
      return {
        ...state,
        userCampaigns: {
          username: action.payload.username,
          campaigns: action.payload.campaigns,
        },
      };

      case SET_DYNAMICS_TOKEN:
        return {
          ...state,
          erpToken: action.payload,
        };
    default:
      return state;
  }
};
