import React, { useEffect, useState, useContext, useRef } from "react";
import Loader from "react-loader-spinner";
import moment from "moment";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
} from "react-bootstrap";

import Modal from "react-modal";
import Axios from "../utils/axios";

import ReactPaginate from "react-paginate";

import { Helper } from "../helper";
import CustomStyle from "../styles";
import { AuthContext } from "../context/auth";
import { DashboardContext, SET_ACTIVE_FEED } from "../context/dashboard";
import { CSVLink, CSVDownload } from "react-csv";
import { Link } from "react-router-dom";
import { showToast } from "utils/general";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");
function Finance() {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [allData, setAllData] = useState({});
  const [results, setResults] = useState([]);
  const [limit, setLimit] = useState(50);
  const [searchKey, setSearchKey] = useState("Users");
  const [keyword, setKeyword] = useState("");
  const [searching, setSearching] = useState(false);
  const [csvResults, setCsvResults] = useState([]);

  const context = useContext(AuthContext);
  const dashboardContext = useContext(DashboardContext);

  useEffect(() => {
    // empty active user
    if (Helper.isPopulator(context.authState?.admin)) {
      return false;
    }

    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [limit]);

  useEffect(() => {
    prepareForCSV();
  }, [results]);

  const prepareForCSV = async () => {
    let newResults = [];
    results.forEach(async (r) => {
      r.userName = r?.user?.name ?? "";
      r.userEmail = r?.user?.email ?? "";
      r.userPhone = r?.user?.phone ?? "";
      r.reference = r?._id?.reference ?? "";

      newResults.push(r);
    });

    setCsvResults(newResults);
  };

  const fetchData = async (offSet = 1) => {
    const { token } = context.authState;
    setLoading(true);
    try {
      let usersResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/finance?limit=${limit}&page=${offSet}`,
        token
      );

      if (usersResponse && usersResponse?.data) {
        setAllData(usersResponse?.data || []);
        setResults(usersResponse?.data?.results || []);
      }
      setLoading(false);
    } catch (error) {
      showToast(`Oops. failed to fetch users`);
      setLoading(false);
    } finally {
      setSearching(false);
    }
  };

  const searchByKey = async () => {
    if (searchKey == "Users") {
      await searchUsers();
    } else if (searchKey == "Reference") {
      await searchFinance();
    }
  };

  const searchUsers = async () => {
    try {
      const { token } = context.authState;

      setLoading(true);
      let response = await Axios.get(
        `${process.env.REACT_APP_API_V2}/admin/users/search/${keyword}`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { data } = response?.data;
      setUsers(data || []);
      setSearching(true);
      setKeyword("");

      setLoading(false);
    } catch (error) {
      showToast(`Oops. something went wrong while searching user`);
      setLoading(false);
    }
  };

  const searchFinance = async () => {
    try {
      const { token } = context.authState;

      setLoading(true);
      let usersResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/finance/reference/${keyword}`,
        token
      );

      if (usersResponse && usersResponse?.data) {
        setAllData(usersResponse?.data || []);
        setResults(usersResponse?.data?.results || []);
      }
      setLoading(false);
    } catch (error) {
      showToast(`Oops. something went wrong while searching user`);
      setLoading(false);
    }
  };

  const handlePageClick = (data) => {
    let selected = data.selected + 1;

    fetchData(selected);
  };

  if (Helper.isPopulator(context.authState?.admin)) {
    return (
      <div style={{ textAlign: "center" }}>
        <small>You are not permitted to view this page</small>
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-tasks">
              <Card.Header>
                <Row>
                  <Col>
                    <span style={{ fontSize: 10 }}>Showing: </span>
                    <Row style={{ marginLeft: 2 }}>
                      <select onChange={(e) => setLimit(e.target.value)}>
                        <option>50</option>
                        <option>100</option>
                        <option>1000</option>
                        <option>5000</option>
                      </select>
                      <div style={{ marginLeft: 2 }}></div>
                      <Button className="btn-sm">
                        <CSVLink data={csvResults} className="card-category">
                          Export CSV
                        </CSVLink>
                      </Button>
                    </Row>
                  </Col>
                  <Col>
                    <Form
                      className="container"
                      noValidate
                      // validated={searchValidated}
                      onSubmit={(e) => {
                        e.preventDefault();
                        searchByKey();
                      }}
                    >
                      <Row>
                        <select
                          style={{ height: 40, marginTop: 6 }}
                          onChange={(e) => setSearchKey(e.target.value)}
                        >
                          <option>Users</option>
                          <option>Reference</option>
                        </select>
                        <Col md="6">
                          <Form.Group>
                            {/* <label>Keyword</label> */}
                            <Form.Control
                              placeholder={`Enter Search ${searchKey}`}
                              type="text"
                              required
                              onChange={(e) => setKeyword(e.target.value)}
                              value={keyword}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please enter a keyword.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md="2">
                          <Form.Group>
                            {/* <label>Search</label> */}
                            <Button
                              className="btn-fill form-control"
                              type="submit"
                              variant="primary"
                              style={{
                                background: CustomStyle.colors.primary,
                                borderColor: CustomStyle.colors.primary,
                              }}
                            >
                              <i className="nc-icon nc-zoom-split"></i>
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {loading ? (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    <Loader
                      type="Oval"
                      color={CustomStyle.colors.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <div className="table-full-width">
                    <Table>
                      <tbody>
                        {results && results?.length > 0 ? (
                          results?.map((item, index) => {
                            // const typeOfTx = [...new Set(item?.typeOfTx)]
                            // const paymentType = [...new Set(item?.paymentType)]
                            const typeOfTx = item?.typeOfTx;
                            const paymentType = item?.paymentType;
                            const tx = item?.transactions;
                            return (
                              <tr key={index.toString()}>
                                <td>
                                  <div>
                                    <span>Type:</span>{" "}
                                    <b style={{ textTransform: "capitalize" }}>
                                      {new Set(item?.txChannel)}
                                    </b>{" "}
                                    <small>({item?._id?.status})</small>
                                  </div>
                                  <div>
                                    <span>In:</span>{" "}
                                    <b style={{ textTransform: "capitalize" }}>
                                      {Helper.formatToNaira(item?.credit)}
                                    </b>{" "}
                                    | <span>Out:</span>{" "}
                                    <b style={{ textTransform: "capitalize" }}>
                                      {Helper.formatToNaira(item?.debit)}
                                    </b>
                                    <BreakdownDropdown item={tx} />
                                  </div>
                                  <div>
                                    <span>Reference:</span>{" "}
                                    <b style={{ textTransform: "capitalize" }}>
                                      {item?._id?.reference}
                                    </b>
                                  </div>
                                  <div>
                                    <span>User:</span>{" "}
                                    <b style={{ textTransform: "capitalize" }}>
                                      <Link to={"finance/" + item?.user?._id}>
                                        {item?.user?.name}
                                      </Link>
                                    </b>{" "}
                                    <small>
                                      (
                                      <Link to={"finance/" + item?.user?._id}>
                                        {item?.user?.email}
                                      </Link>{" "}
                                      )
                                    </small>
                                  </div>
                                  <small>
                                    Created {moment(item?.createdAt).fromNow()}{" "}
                                    (
                                    {moment(item?.createdAt).format(
                                      "DD/MM/YYYY"
                                    )}
                                    )
                                  </small>
                                </td>
                                {/* <td className="td-actions text-right">
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-938342127">
                                        Toggle user status
                                      </Tooltip>
                                    }
                                  >
                                    {item?.status == "success" && (
                                      <Button
                                        className="btn btn-info btn-fill"
                                        type="button"
                                        variant="info"
                                        disabled={submitLoading == item?._id}
                                        onClick={async () =>
                                          toggleUserStatus(true, item?._id)
                                        }
                                      >
                                        {submitLoading == item?._id
                                          ? "Confirming"
                                          : "Confirm Payments"}
                                      </Button>
                                    )}
                                  </OverlayTrigger>
                                </td> */}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan="6"
                              style={{
                                textAlign: "center",
                                color: CustomStyle.colors.danger,
                              }}
                            >
                              <small>No transaction found</small>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card.Body>
              <Card.Footer>
                {allData && allData?.total ? (
                  <ReactPaginate
                    previousLabel={<i className="nc-icon nc-stre-left"></i>}
                    nextLabel={<i className="nc-icon nc-stre-right"></i>}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={allData?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Card.Footer>
            </Card>
          </Col>
          {users && users?.length > 0 && (
            <Col
              md="4"
              style={{ position: "absolute", right: 0, top: 0, bottom: 0 }}
            >
              <Card className="card-tasks">
                <Card.Header>
                  <Card.Title as={"h4"}>Users</Card.Title>
                </Card.Header>
                <Card.Body>
                  <div className="table-full-width">
                    <Table>
                      <tbody>
                        {users && users?.length > 0 ? (
                          users?.map((item, index) => {
                            return (
                              <tr>
                                <td>
                                  <div>
                                    <b style={{ textTransform: "capitalize" }}>
                                      <Link
                                        to={
                                          "finance/" + item._id + "?type=user"
                                        }
                                      >
                                        {item?.name}
                                      </Link>
                                    </b>
                                  </div>
                                  <div>
                                    <b style={{ textTransform: "capitalize" }}>
                                      {item?.email}
                                    </b>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan="6"
                              style={{
                                textAlign: "center",
                                color: CustomStyle.colors.danger,
                              }}
                            >
                              <small>No user found</small>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
}

function BreakdownDropdown({ item }) {
  const [isToggled, setIsToggled] = useState(false);
  return (
    <>
      <b
        class="text-info ml-1"
        role="button"
        onClick={() => {
          isToggled ? setIsToggled(false) : setIsToggled(true);
        }}
        style={{
          alignItems: "center",
          textTransform: "capitalize",
        }}
      >
        {isToggled ? (
          <i className="nc-icon ml-1 nc-stre-up"></i>
        ) : (
          <i className="nc-icon ml-1 nc-stre-down"></i>
        )}
      </b>
      <div class={`collapse ${isToggled ? "show" : ""}`}>
        {item.map((tx, index) => (
          <div class="card card-body">
            <div>
              <span>type: </span>
              <b>{tx?.typeOfTx}</b>
            </div>
            <div>
              <span>Amount: </span>
              <b>{Helper.formatToNaira(tx?.amount)}</b>
            </div>
            <div>
              <span>Paid with: </span>
              <b>{tx?.paymentType}</b>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Finance;
