import React, { useEffect, useState, useContext, useRef } from "react";
import Loader from "react-loader-spinner";
import moment from "moment";
import {
	Button,
	Card,
	Table,
	Container,
	Row,
	Col,
	Form,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";

import Axios from "../utils/axios";

import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import { Helper } from "../helper";
import CustomStyle from "../styles";
import { AuthContext } from "../context/auth";
import {
	DashboardContext,
	SET_WAREHOUSES,
	SET_ACTIVE_WAREHOUSE,
} from "../context/dashboard";
import { showToast } from "utils/general";
import { updateWarehouse, getWarehouses } from "services/warehouseApis";
import { getDYWarehouses } from "services/dynamics";

function Warehouses() {
	const [loading, setLoading] = useState(true);
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [gmapsLoaded, setGmapsLoaded] = useState(false);
	const [processedWarehouses, setProcessedWarehouses] = useState([]);
	const [icgWarehouses, setIcgWarehouses] = useState([]);
	const [warehouseId, setWarehouseId] = useState(0);
	const [name, setName] = useState("");
	const [description, setDescription] = useState("");
	const [deliveryFee, setDeliveryFee] = useState(0);
	const [lat, setLat] = useState("");
	const [lng, setLng] = useState("");
	const [status, setStatus] = useState("active");
	const [address, setAddress] = useState("");
	const [map, setMap] = useState(null);
	const [formValidated, setFormValidated] = useState(false);

	const [supName, setSupName] = useState("");
	const [supEmail, setSupEmail] = useState("");
	const [supPhone, setSupPhone] = useState("");

	const { dispatch } = useContext(DashboardContext);
	const context = useContext(AuthContext);
	const dashboardContext = useContext(DashboardContext);

	const { activeWarehouse } = dashboardContext.dashboardState || {
		activeWarehouse: {},
	};

	// for inputs
	let nameInput = useRef(null);
	let descriptionInput = useRef(null);
	let deliveryFeeInput = useRef(null);
	let addressInput = useRef(null);
	let latInput = useRef(null);
	let lngInput = useRef(null);

	let supNameInput = useRef(null);
	let supPhoneInput = useRef(null);
	let supEmailInput = useRef(null);

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		if (activeWarehouse && activeWarehouse?.WarehouseId) {
			const newLat =
					processedWarehouses &&
					processedWarehouses?.filter(
						(itm) => itm.code == activeWarehouse?.WarehouseId
					)[0]
						? processedWarehouses?.filter(
								(itm) =>
									itm.code == activeWarehouse?.WarehouseId
						  )[0]?.location?.coordinates[0]
						: "",
				newLng =
					processedWarehouses &&
					processedWarehouses?.filter(
						(itm) => itm.code == activeWarehouse?.WarehouseId
					)[0]
						? processedWarehouses?.filter(
								(itm) =>
									itm.code == activeWarehouse?.WarehouseId
						  )[0]?.location?.coordinates[1]
						: "",
				newDeliveryFee =
					processedWarehouses &&
					processedWarehouses?.filter(
						(itm) => itm.code == activeWarehouse?.WarehouseId
					)[0]
						? processedWarehouses?.filter(
								(itm) =>
									itm.code == activeWarehouse?.WarehouseId
						  )[0]?.deliveryFee
						: 0,
				id =
					processedWarehouses &&
					processedWarehouses?.filter(
						(itm) => itm.code == activeWarehouse?.WarehouseId
					)[0]
						? processedWarehouses?.filter(
								(itm) =>
									itm.code == activeWarehouse?.WarehouseId
						  )[0]?._id
						: 0,
				name =
					processedWarehouses &&
					processedWarehouses?.filter(
						(itm) => itm.code == activeWarehouse?.WarehouseId
					)[0]
						? processedWarehouses?.filter(
								(itm) =>
									itm.code == activeWarehouse?.WarehouseId
						  )[0]?.name
						: "",
				info =
					processedWarehouses &&
					processedWarehouses?.filter(
						(itm) => itm.code == activeWarehouse?.WarehouseId
					)[0]
						? processedWarehouses?.filter(
								(itm) =>
									itm.code == activeWarehouse?.WarehouseId
						  )[0]?.description
						: "",
				supervisor =
					processedWarehouses &&
					processedWarehouses?.filter(
						(itm) => itm.code == activeWarehouse?.WarehouseId
					)[0]
						? processedWarehouses?.filter(
								(itm) =>
									itm.code == activeWarehouse?.WarehouseId
						  )[0]?.supervisor
						: "",
				address =
					processedWarehouses &&
					processedWarehouses?.filter(
						(itm) => itm.code == activeWarehouse?.WarehouseId
					)[0]
						? processedWarehouses?.filter(
								(itm) =>
									itm.code == activeWarehouse?.WarehouseId
						  )[0]?.address
						: "";
			setWarehouseId(id);
			setName(name);
			setDescription(info);
			setDeliveryFee(newDeliveryFee);
			setLat(newLat);
			setLng(newLng);
			setAddress(address);
			setFormValidated(false);
			setMap(null);
			setSupEmail(supervisor?.email);
			setSupPhone(supervisor?.phone);
			setSupName(supervisor?.name);
		}
	}, [processedWarehouses, activeWarehouse]);

	const fetchData = async () => {
		let warehouseResponse;
		const { token, erpToken } = context.authState;
		const { warehouses } = dashboardContext.dashboardState || {
			warehouses: [],
		};
		setLoading(true);
		try {
			let processedWarehousesResponse = await getWarehouses(token);
			if (warehouses && warehouses?.length > 0) {
				warehouseResponse = warehouses;
			} else {
				warehouseResponse = await getDYWarehouses(token, erpToken);
			}

			if (
				processedWarehousesResponse &&
				processedWarehousesResponse?.data
			) {
				setProcessedWarehouses(processedWarehousesResponse?.data || []);
				setIcgWarehouses(warehouseResponse || []);
				dispatch({
					type: SET_WAREHOUSES,
					payload: warehouseResponse || [],
				});
			}
			setLoading(false);
		} catch (error) {
			showToast("Oops. failed to fetch warehouses");
			setLoading(false);
		}
	};

	const handleSubmit = async (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
			setFormValidated(false);
		} else {
			// send data
			setSubmitLoading(true);

			if (!activeWarehouse || !activeWarehouse?.WarehouseId) {
				showToast("Oops! Unknown warehouse");
				return false;
			}

			try {
				const { token } = context.authState;
				let formData;

				formData = {
					name,
					code: activeWarehouse?.WarehouseId,
					description,
					deliveryFee,
					address,
					location: {
						type: "Point",
						coordinates: [Number(lat), Number(lng)],
					},
					status,
					supervisor: {
						name: supName,
						phone: supPhone,
						email: supEmail,
					},
				};
				const { data } = await updateWarehouse(token, formData);

				if (data && data?.code) {
					showToast("Update Successful", "success");

					const index = processedWarehouses
						? processedWarehouses?.findIndex(
								(p) => p?.code == activeWarehouse?.WarehouseId
						  )
						: -1;
					if (index > -1) {
						const updatedWarehouse = processedWarehouses;
						const updated = {
							...updatedWarehouse[index],
							name: data?.name,
							code: data?.code,
							description: data?.description || "",
							address: data?.address || "",
							deliveryFee: data?.deliveryFee || "",
							location: {
								type: "Point",
								coordinates: [
									data?.location?.coordinates[0],
									data?.location?.coordinates[1],
								],
							},
						};
						updatedWarehouse[index] = updated;
						setProcessedWarehouses([...updatedWarehouse]);
					} else {
						const updatedWarehouse = [data].concat(
							processedWarehouses
						);
						setProcessedWarehouses(updatedWarehouse);
					}
				} else {
					showToast("Oops. Failed to save item");
				}
				setSubmitLoading(false);
			} catch (error) {
				console.log(error.message);
				showToast(
					"Oops. something went wrong while saving item" +
						error.message
				);
				setSubmitLoading(false);
			}
		}

		setFormValidated(true);
	};

	const deleteWarehouse = async (id) => {
		setDeleteLoading(true);

		if (id == 0) {
			showToast("Oops! Unknown warehouse");
			return false;
		}

		try {
			const { token } = context.authState;
			let response;

			if (
				processedWarehouses &&
				processedWarehouses?.filter(
					(itm) => itm.code == activeWarehouse?.WarehouseId
				)[0]
			) {
				response = await deleteWarehouse(id, token);
			}

			const { status } = response;

			if (!!status) {
				showToast("Delete Successful", "success");

				const index = processedWarehouses
					? processedWarehouses?.findIndex(
							(p) => p?.code == activeWarehouse?.WarehouseId
					  )
					: -1;
				if (index > -1) {
					const updatedWarehouse = processedWarehouses;
					updatedWarehouse.splice(index, 1);
					setProcessedWarehouses([...updatedWarehouse]);
				}
			} else {
				showToast("Oops. Failed to delete item");
			}
			setDeleteLoading(false);
		} catch (error) {
			showToast("Oops. something went wrong while deleting item");
			setDeleteLoading(false);
		}
	};

	if (
		Helper.isPopulator(context.authState?.admin) ||
		Helper.isFinance(context.authState?.admin) ||
		Helper.isAdministrator(context.authState?.admin)
	) {
		return (
			<div style={{ textAlign: "center" }}>
				<small>You are not permitted to view this page</small>
			</div>
		);
	}

	if (loading) {
		return (
			<div
				style={{
					flex: 1,
					textAlign: "center",
					padding: "20px 0px",
				}}
			>
				<Loader
					type="Oval"
					color={CustomStyle.colors.primary}
					height={50}
					width={50}
				/>
			</div>
		);
	}

	return (
		<>
			<Container fluid id="MainArea">
				<Row>
					{activeWarehouse && activeWarehouse?.WarehouseId ? (
						<Col md="6">
							<Form
								noValidate
								validated={formValidated}
								onSubmit={(event) => {
									event.preventDefault();
									handleSubmit(event);
								}}
							>
								<Card className="card-tasks">
									{processedWarehouses &&
									processedWarehouses?.filter(
										(itm) =>
											itm.code ==
											activeWarehouse?.WarehouseId
									)[0] ? (
										<></>
									) : null}
									<Card.Header>
										<Row>
											<Col md="10">
												<Card.Title as="h4">
													Edit{" "}
													<b>
														(
														{
															activeWarehouse?.WarehouseId
														}
														)
													</b>{" "}
													{activeWarehouse?.Name}
												</Card.Title>
												<p className="card-category">
													{processedWarehouses &&
													processedWarehouses?.filter(
														(itm) =>
															itm.code ==
															activeWarehouse?.WarehouseId
													)[0] ? (
														<small className="text-success">
															Added to database
														</small>
													) : (
														<small className="text-warning">
															Pending
														</small>
													)}
												</p>
											</Col>
											<Col md="2">
												{processedWarehouses &&
												processedWarehouses?.filter(
													(itm) =>
														itm.code ==
														activeWarehouse?.WarehouseId
												)[0] ? (
													deleteLoading ? (
														<Loader
															type="Oval"
															color={
																CustomStyle
																	.colors
																	.danger
															}
															height={30}
															width={30}
														/>
													) : (
														<Button
															title="Delete warehouse"
															className="btn-fill form-control"
															type="button"
															variant="danger"
															onClick={() =>
																deleteWarehouse(
																	warehouseId
																)
															}
														>
															<i className="fa fa-trash"></i>
														</Button>
													)
												) : null}
											</Col>
										</Row>
									</Card.Header>
									<Card.Body>
										<Row>
											<Col md="7">
												<Form.Group>
													<label htmlFor="">
														Warehouse Name
													</label>
													<Form.Control
														placeholder="Name"
														type="text"
														required
														ref={nameInput}
														value={name}
														onChange={(e) =>
															setName(
																e.target.value
															)
														}
													></Form.Control>
													<Form.Control.Feedback>
														Looks good!
													</Form.Control.Feedback>
													<Form.Control.Feedback type="invalid">
														Please enter warehouse
														name
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col md="5">
												<Form.Group>
													<label htmlFor="">
														Delivery Fee Per KM{" "}
														<b>(₦)</b>
													</label>
													<Form.Control
														placeholder="Delivery Fee"
														type="number"
														required
														ref={deliveryFeeInput}
														value={deliveryFee}
														onChange={(e) =>
															setDeliveryFee(
																e.target.value
															)
														}
													></Form.Control>
													<Form.Control.Feedback>
														Looks good!
													</Form.Control.Feedback>
													<Form.Control.Feedback type="invalid">
														Please enter warehouse
														delivery fee per
														kilometer (KM)
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col md="12">
												<Form.Group>
													<label htmlFor="">
														Warehouse Description
													</label>
													<Form.Control
														placeholder="Description"
														type="text"
														required
														ref={descriptionInput}
														value={description}
														onChange={(e) =>
															setDescription(
																e.target.value
															)
														}
													></Form.Control>
													<Form.Control.Feedback>
														Looks good!
													</Form.Control.Feedback>
													<Form.Control.Feedback type="invalid">
														Please enter warehouse
														description
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col md="12">
												<Form.Group>
													<label htmlFor="">
														Warehouse Address
														(Google map recognised
														address)
													</label>
													<Form.Control
														placeholder="Address"
														type="text"
														required
														ref={addressInput}
														value={address}
														onChange={(e) =>
															setAddress(
																e.target.value
															)
														}
													></Form.Control>
													<Form.Control.Feedback>
														Looks good!
													</Form.Control.Feedback>
													<Form.Control.Feedback type="invalid">
														Please enter warehouse
														address
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col md="6">
												<Form.Group>
													<label htmlFor="">
														Address Latitude
													</label>
													<Form.Control
														placeholder="Latitude"
														type="text"
														required
														ref={latInput}
														value={lat}
														onChange={(e) =>
															setLat(
																e.target.value
															)
														}
													></Form.Control>
													<Form.Control.Feedback>
														Looks good!
													</Form.Control.Feedback>
													<Form.Control.Feedback type="invalid">
														Please enter warehouse
														address latitude
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col md="6">
												<Form.Group>
													<label htmlFor="">
														Address Longitude
													</label>
													<Form.Control
														placeholder="Longitude"
														type="text"
														required
														ref={lngInput}
														value={lng}
														onChange={(e) =>
															setLng(
																e.target.value
															)
														}
													></Form.Control>
													<Form.Control.Feedback>
														Looks good!
													</Form.Control.Feedback>
													<Form.Control.Feedback type="invalid">
														Please enter warehouse
														address longitude
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col md="12">
												<Form.Group>
													<label htmlFor="">
														Status
													</label>{" "}
													<select
														aria-label="Status"
														defaultValue="active"
														onChange={(e) =>
															setStatus(
																e.target.value
															)
														}
													>
														<option value="active">
															Active
														</option>
														<option value="disabled">
															Disable
														</option>
													</select>
													<Form.Control.Feedback>
														Looks good!
													</Form.Control.Feedback>
													<Form.Control.Feedback type="invalid">
														Please enter warehouse
														description
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>

										<hr />

										<Row>
											<Col md="7">
												<Form.Group>
													<label htmlFor="">
														Supervisor Name
													</label>
													<Form.Control
														placeholder="Name"
														type="text"
														required
														ref={supNameInput}
														value={supName}
														onChange={(e) =>
															setSupName(
																e.target.value
															)
														}
													></Form.Control>
													<Form.Control.Feedback>
														Looks good!
													</Form.Control.Feedback>
													<Form.Control.Feedback type="invalid">
														Please enter Supervisor
														name
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col md="5">
												<Form.Group>
													<label htmlFor="">
														Supervisor Phone number
													</label>
													<Form.Control
														placeholder="Phone number"
														type="text"
														ref={supPhoneInput}
														value={supPhone}
														onChange={(e) =>
															setSupPhone(
																e.target.value
															)
														}
													></Form.Control>
													<Form.Control.Feedback>
														Looks good!
													</Form.Control.Feedback>
													<Form.Control.Feedback type="invalid">
														Please enter Supervisor
														phone
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col md="12">
												<Form.Group>
													<label htmlFor="">
														Supervisor Email
													</label>
													<Form.Control
														placeholder="Email"
														type="text"
														required
														ref={supEmailInput}
														value={supEmail}
														onChange={(e) =>
															setSupEmail(
																e.target.value
															)
														}
													></Form.Control>
													<Form.Control.Feedback>
														Looks good!
													</Form.Control.Feedback>
													<Form.Control.Feedback type="invalid">
														Please enter Supervisor
														email
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
										</Row>
									</Card.Body>
									<Card.Footer>
										<Button
											className="btn-fill form-control pull-right"
											variant="primary"
											type="submit"
											disabled={submitLoading}
											style={{
												background:
													CustomStyle.colors.primary,
												borderColor:
													CustomStyle.colors.primary,
											}}
										>
											{submitLoading ? (
												<Loader
													type="Oval"
													color={
														CustomStyle.colors.white
													}
													height={20}
													width={20}
												/>
											) : (
												"Submit"
											)}
										</Button>
									</Card.Footer>
								</Card>
							</Form>
						</Col>
					) : null}
					<Col md="6">
						<Card className="card-tasks">
							<Card.Header>
								<Card.Title as="h4">
									All ICG Warehouses
								</Card.Title>
								<p className="card-category">
									Backend development
								</p>
							</Card.Header>
							<Card.Body>
								<div className="table-full-width">
									<Table>
										<tbody>
											{icgWarehouses?.length > 0 ? (
												icgWarehouses?.map(
													(item, index) => {
														return (
															<tr
																key={index.toString()}
															>
																<td>
																	<b>
																		(
																		{
																			item?.WarehouseId
																		}
																		)
																	</b>{" "}
																	{item?.Name}{" "}
																	{processedWarehouses &&
																	processedWarehouses?.filter(
																		(itm) =>
																			itm.code ==
																			item?.WarehouseId
																	)[0] ? (
																		<small className="text-success">
																			Processed
																		</small>
																	) : (
																		<small className="text-warning">
																			Pending
																		</small>
																	)}
																</td>
																<td className="td-actions text-right">
																	<OverlayTrigger
																		overlay={
																			<Tooltip id="tooltip-938342127">
																				Edit
																				Warehouse
																			</Tooltip>
																		}
																	>
																		<Button
																			className="btn-simple btn-link p-1"
																			type="button"
																			variant="info"
																			onClick={async () => {
																				await dispatch(
																					{
																						type: SET_ACTIVE_WAREHOUSE,
																						payload:
																							{},
																					}
																				);
																				dispatch(
																					{
																						type: SET_ACTIVE_WAREHOUSE,
																						payload:
																							item,
																					}
																				);
																				document
																					.getElementById(
																						"MainArea"
																					)
																					.scrollIntoView();
																			}}
																		>
																			<i className="fas fa-edit"></i>
																		</Button>
																	</OverlayTrigger>
																</td>
															</tr>
														);
													}
												)
											) : (
												<tr>
													<td
														colSpan="6"
														style={{
															textAlign: "center",
															color: CustomStyle
																.colors.danger,
														}}
													>
														<small>
															No product found
														</small>
													</td>
												</tr>
											)}
										</tbody>
									</Table>
								</div>
							</Card.Body>
							{processedWarehouses &&
							processedWarehouses?.length > 0 ? (
								<Card.Footer>
									<hr></hr>
									<div className="stats">
										<i className="now-ui-icons loader_refresh spin"></i>
										Updated{" "}
										{moment(
											processedWarehouses.reverse()[0]
												?.createdAt
										).fromNow()}
									</div>
								</Card.Footer>
							) : null}
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Warehouses;
