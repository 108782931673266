import React, { useEffect, useState, useContext, useRef } from "react";
import Loader from "react-loader-spinner";
import {
	Button,
	Card,
	Table,
	Col,
	Row,
	Form,
	Container,
} from "react-bootstrap";
import Modal from "react-modal";
import Axios from "../utils/axios";
import { Helper } from "../helper";
import CustomStyle from "../styles";
import { AuthContext } from "../context/auth";
import { showToast } from "utils/general";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { getCategories } from "services/ourProducts";
import { searchOurProducts } from "services/ourProducts";
import ReactPaginate from "react-paginate";
import { useParams, useHistory, Link, useLocation } from "react-router-dom";

const customStyles = {
	content: {
		top: "5%",
		left: "5%",
		right: "5%",
		bottom: "5%",
		// marginRight: "-50%",
		// transform: "translate(-50%, -50%)",
	},
};

const Promo = () => {
	const history = useHistory();

	const [loading, setLoading] = useState(true);
	const [datas, setDatas] = useState([]);
	const [modalIsOpen, setIsOpen] = React.useState(false);
	const [validated, setValidated] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [searchValidated, setSearchValidated] = useState(false);

	const [isAllProducts, setIsAllProducts] = useState("false");
	const [categories, setCategories] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [selectedCategoryValues, setSelectedCategoryValues] = useState([]);
	const [selectedProducts, setSelectedProducts] = useState([]);
	const [products, setProducts] = useState([]);
	const [results, setResults] = useState({});

	const context = useContext(AuthContext);
	const { id } = useParams();

	let keywordInput = useRef(null);

	useEffect(() => {
		// if (Helper.isPopulator(context.authState?.admin)) {
		//   return false;
		// }
		fetchData();
	}, []);

	const fetchData = async () => {
		const { token } = context.authState;
		setLoading(true);
		try {
			let { data } = await Helper.getData(
				`${process.env.REACT_APP_API_V2}/admin/promos/${id}/products?page=1&price=desc&limit=20`,
				token
			);

			setDatas(data.results);

			setLoading(false);
		} catch (error) {
			showToast(`Oops. failed to fetch warehouses`);
			setLoading(false);
		}
	};

	const afterOpenOurProductsModal = async () => {
		const { token } = context.authState;
		await getCategories(token, async (res) => {
			if (!!res.status) {
				const categories = res.data.map((cate) => {
					const tree = {
						label: cate.Description,
						value: cate.DeptID,
					};

					return tree;
				});

				setCategories(categories);
			}
		});
	};

	const onFormSubmit = async (e) => {
		const form = e.currentTarget;
		if (
			isAllProducts == "true" &&
			(selectedProducts.length > 0 || selectedCategoryValues.length > 0)
		) {
			showToast(
				"You cannot add 'all products' and manually add individual products or categories"
			);
			return;
		}

		console.log(isAllProducts, selectedProducts, selectedCategoryValues);

		if (
			isAllProducts == "false" &&
			selectedProducts.length == 0 &&
			selectedCategoryValues.length == 0
		) {
			showToast("Kindly select a promo product");
			return;
		}

		if (form.checkValidity() === false) {
			e.preventDefault();
			e.stopPropagation();
			setValidated(true);
		} else {
			const formData = {
				promoId: id,
				products: selectedProducts.map((product) => product?._id),
				categories: selectedCategoryValues,
			};
			setSubmitLoading(true);
			try {
				const { token } = context.authState;
				await Axios.post(
					`${process.env.REACT_APP_API_V2}/admin/promos/products`,
					formData,
					{
						headers: {
							"x-access-token": token,
						},
					}
				);

				setSelectedProducts([]);
				setSelectedCategoryValues([]);
				setSelectedCategories([]);

				fetchData();
				setIsOpen(false);
			} catch (error) {
				showToast(JSON.stringify(error.response.data.message));
			}

			setSubmitLoading(false);
		}
	};

	const deleteProduct = async (id) => {
		setSubmitLoading(true);
		try {
			const { token } = context.authState;
			await Axios.delete(
				`${process.env.REACT_APP_API_V2}/admin/promos/products/${id}`,
				{
					headers: {
						"x-access-token": token,
					},
				}
			);

			fetchData();
			setIsOpen(false);
		} catch (error) {
			showToast(JSON.stringify(error?.response?.data.message));
		}
		setSubmitLoading(false);
	};

	const onChangeCategories = (selectedOptions) => {
		const value = selectedOptions.map((option) => option.value);
		setSelectedCategoryValues(value);
		setSelectedCategories(selectedOptions);
	};

	const handleSearch = async (event) => {
		const keyword = keywordInput ? keywordInput.current.value : null;
		searchProducts(keyword);
	};

	const handlePageClick = (data) => {
		let selected = data.selected + 1;
		getProducts(selected);
	};

	const getProducts = async (offSet = 1) => {
		setLoading(true);
		const { token } = context.authState;
		await getOurProducts(token, offSet, limit, (res) => {
			setLoading(false);
			if (!res?.status) {
				showToast(
					`Oops. failed to fetch products from ${start} to ${end}`
				);
				return;
			}

			setResults(res?.data || []);
			setProducts(res?.data?.results || []);
		});
	};

	const searchProducts = async (keyword) => {
		setLoading(true);
		const { token } = context.authState;
		await searchOurProducts(token, keyword, (res) => {
			setLoading(false);
			if (!res?.status) {
				showToast(
					`Oops. failed to fetch products with keyword ${keyword}`
				);
				return;
			}

			setResults(res?.data || []);
			setProducts(res?.data?.results || []);
		});
	};

	const addProducts = (selectedOptions) => {
		setSelectedProducts([selectedOptions, ...selectedProducts]);
	};

	const removeProducts = (selectedOptions) => {
		const products = selectedProducts.filter(
			(product) => product._id != selectedOptions._id
		);
		setSelectedProducts(products);
	};

	const getDiscountAmount = (amount, promoData) => {
		let discount = 0;
		if (promoData.offerType == "amount") {
			let subAmount = amount - promoData.offer;
			discount = subAmount > 0 ? subAmount : 0;
		} else {
			discount = amount - (amount * promoData.offer) / 100;
		}

		return discount;
	};

	if (
		Helper.isFinance(context.authState?.admin) ||
		// Helper.isPopulator(context.authState?.admin) ||
		Helper.isAdministrator(context.authState?.admin)
	) {
		return (
			<div style={{ textAlign: "center" }}>
				<small>You are not permitted to view this page</small>
			</div>
		);
	}

	if (loading) {
		return (
			<div
				style={{
					flex: 1,
					textAlign: "center",
					padding: "20px 0px",
				}}
			>
				<Loader
					type="Oval"
					color={CustomStyle.colors.primary}
					height={50}
					width={50}
				/>
			</div>
		);
	}

	return (
		<>
			<Container fluid id="MainArea">
				<div
					style={{ marginBottom: 10, cursor: "pointer" }}
					onClick={() => history.goBack()}
				>
					<i
						className="nc-icon nc-stre-left"
						style={{ color: "#000000" }}
					></i>{" "}
					<span>Go Back</span>
				</div>
				<Card className="card-tasks">
					<Card.Header
						style={{ display: "flex" }}
						className="justify-content-md-between"
					>
						<Card.Title as={Col} flex="1">
							All Promos
						</Card.Title>
						<Card.Title as={Col} md="3">
							<Button
								className="btn-fill form-control"
								type="submit"
								variant="primary"
								style={{
									background: CustomStyle.colors.primary,
									borderColor: CustomStyle.colors.primary,
								}}
								onClick={() => setIsOpen(true)}
							>
								Add Product
							</Button>
						</Card.Title>
					</Card.Header>
					<Card.Body>
						<div className="table-full-width">
							<Table className="table-hover table-striped">
								<thead>
									<tr>
										<th className="border-0">Name</th>
										<th className="border-0">Price</th>
										<th className="border-0">Offer</th>
										<th className="border-0">
											Discounted Price
										</th>
										<th className="border-0"></th>
									</tr>
								</thead>
								<tbody>
									{datas.map((item) => (
										<tr key={item._id}>
											<td>
												<div
													style={{ display: "flex" }}
												>
													<img
														style={{
															width: "80px",
														}}
														className="img-fluid rounded mr-2"
														src={
															item?.productId
																?.FrontImage
														}
													/>
													{item.productId?.Name}
												</div>
											</td>
											<td>
												{Helper.formatToNaira(
													item?.productId
														?.SellingPrice
												)}
											</td>
											<td>
												{item?.promoId?.offerType ==
												"amount"
													? Helper.formatToNaira(
															item?.promoId
																?.offer
													  )
													: item?.promoId?.offer +
													  " %"}
											</td>
											<td>
												{Helper.formatToNaira(
													getDiscountAmount(
														item?.productId
															?.SellingPrice,
														item?.promoId
													)
												)}
											</td>
											<td>
												<Button
													onClick={() =>
														deleteProduct(item._id)
													}
													className="btn-fill form-control"
													type="submit"
													variant="danger"
												>
													{submitLoading ? (
														<Loader
															type="Oval"
															color={
																CustomStyle
																	.colors
																	.white
															}
															height={20}
															width={20}
														/>
													) : (
														<i className="nc-icon nc-simple-remove"></i>
													)}
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
					</Card.Body>
				</Card>
				<Modal
					isOpen={modalIsOpen}
					onAfterOpen={() => afterOpenOurProductsModal()}
					onRequestClose={() => setIsOpen(false)}
					style={customStyles}
					contentLabel="Example Modal"
				>
					<Row>
						<Col md={4} style={{ borderRightWidth: 1 }}>
							<Form
								noValidate
								validated={validated}
								onSubmit={(e) => {
									e.preventDefault();
									onFormSubmit(e);
								}}
							>
								<Form.Group controlId="exampleForm.ControlSelect1">
									<Form.Label>Add to all Products</Form.Label>
									<Form.Control
										as="select"
										defaultValue={isAllProducts}
										onChange={(e) =>
											setIsAllProducts(e.target.value)
										}
										disabled={true}
									>
										<option value={false}>No</option>
										<option value={true}>Yes</option>
									</Form.Control>
								</Form.Group>

								<div style={{ marginTop: 15 }}>
									<label>Categories</label>
									<Select
										value={selectedCategories}
										isMulti
										name="categories"
										options={categories}
										onChange={onChangeCategories}
										className="basic-cate-select"
										classNamePrefix="select"
										isDisabled={
											isAllProducts == "true"
												? true
												: false
										}
										// cacheOptions defaultOptions loadOptions={fetchCategories}
									/>
								</div>

								<div
									style={{ marginTop: 15, marginBottom: 45 }}
								>
									<label>Selected Products</label>
									<Table className="table-hover table-striped">
										<tbody>
											{selectedProducts.length > 0 ? (
												selectedProducts.map(
													(item, index) => {
														return (
															<tr key={index}>
																<td>
																	{
																		item.ItemDescription
																	}
																</td>
																<td>
																	<Button
																		className="btn-fill form-control btn-sm"
																		type="button"
																		variant="primary"
																		style={{
																			background:
																				CustomStyle
																					.colors
																					.primary,
																			borderColor:
																				CustomStyle
																					.colors
																					.primary,
																		}}
																		onClick={() =>
																			removeProducts(
																				item
																			)
																		}
																	>
																		-
																	</Button>
																</td>
															</tr>
														);
													}
												)
											) : (
												<tr>
													<td
														colSpan="6"
														style={{
															textAlign: "center",
															color: CustomStyle
																.colors.danger,
														}}
													>
														<small>
															No product added
														</small>
													</td>
												</tr>
											)}
										</tbody>
									</Table>
								</div>

								<Button
									className="btn-fill form-control pull-right"
									variant="primary"
									type="submit"
									disabled={submitLoading}
									style={{
										background: CustomStyle.colors.primary,
										borderColor: CustomStyle.colors.primary,
									}}
								>
									{submitLoading ? (
										<Loader
											type="Oval"
											color={CustomStyle.colors.white}
											height={20}
											width={20}
										/>
									) : (
										"Create"
									)}
								</Button>
							</Form>
						</Col>
						<Col md={8}>
							<Form
								className="container"
								noValidate
								validated={searchValidated}
								onSubmit={(e) => {
									e.preventDefault();
									handleSearch(e);
								}}
							>
								<Row>
									<Col md="10">
										<Form.Group>
											<Form.Control
												placeholder="Search and Add Products"
												type="text"
												required
												ref={keywordInput}
												disabled={
													isAllProducts == "true"
														? true
														: false
												}
											></Form.Control>
											<Form.Control.Feedback type="invalid">
												Please enter a keyword.
											</Form.Control.Feedback>
										</Form.Group>
									</Col>
									<Col md="2">
										<Form.Group>
											<Button
												className="btn-fill form-control"
												type="submit"
												disabled={
													isAllProducts == "true"
														? true
														: false
												}
												variant="primary"
												style={{
													background:
														CustomStyle.colors
															.primary,
													borderColor:
														CustomStyle.colors
															.primary,
												}}
											>
												<i className="nc-icon nc-zoom-split"></i>
											</Button>
										</Form.Group>
									</Col>
								</Row>
							</Form>
							<div>
								{loading ? (
									<div
										style={{
											flex: 1,
											textAlign: "center",
											padding: "20px 0px",
										}}
									>
										<Loader
											type="Oval"
											color={CustomStyle.colors.primary}
											height={50}
											width={50}
										/>
									</div>
								) : (
									<Table className="table-hover table-striped">
										<thead>
											<tr>
												<th className="border-0">
													Name
												</th>
												<th className="border-0">
													Barcode
												</th>
												<th className="border-0">
													Price
												</th>
												<th className="border-1"></th>
											</tr>
										</thead>
										<tbody>
											{products.length > 0 ? (
												products.map((item, index) => {
													return (
														<tr key={index}>
															<td>
																{
																	item.ItemDescription
																}
															</td>
															<td>
																{item.Barcode}
															</td>
															<td>
																{Helper.formatToNaira(
																	item.SellingPrice
																)}
															</td>
															<td>
																<Button
																	className="btn-fill form-control btn-sm"
																	type="submit"
																	variant="primary"
																	style={{
																		background:
																			CustomStyle
																				.colors
																				.primary,
																		borderColor:
																			CustomStyle
																				.colors
																				.primary,
																	}}
																	onClick={() =>
																		addProducts(
																			item
																		)
																	}
																	disabled={selectedProducts.includes(
																		item
																	)}
																>
																	+
																</Button>
															</td>
														</tr>
													);
												})
											) : (
												<tr>
													<td
														colSpan="6"
														style={{
															textAlign: "center",
															color: CustomStyle
																.colors.danger,
														}}
													>
														<small>
															No product found
														</small>
													</td>
												</tr>
											)}
										</tbody>
									</Table>
								)}
							</div>
							{results && results?.total ? (
								<ReactPaginate
									previousLabel={
										<i className="nc-icon nc-stre-left"></i>
									}
									nextLabel={
										<i className="nc-icon nc-stre-right"></i>
									}
									breakLabel={"..."}
									breakClassName={"break-me"}
									pageCount={results?.totalPages}
									marginPagesDisplayed={2}
									pageRangeDisplayed={5}
									onPageChange={handlePageClick}
									containerClassName={"pagination"}
									activeClassName={"active"}
								/>
							) : null}
						</Col>
					</Row>
				</Modal>
			</Container>
		</>
	);
};

export default Promo;
