import { toast } from "react-toastify";
import Axios from "./utils/axios";

export const Helper = {
  isAdmin(user) {
    const role = user?.role;

    // return role == "super" || role == "admin" ? true : false;
    return role == "super" ? true : false;
  },

  isSuper(user) {
    const role = user?.role;

    return role == "super" ? true : false;
  },

  isAdministrator(user) {
    const role = user?.role;

    return role == "admin" ? true : false;
  },

  isPopulator(user) {
    const role = user?.role;

    return role == "populator" ? true : false;
  },

  isFinance(user) {
    const role = user?.role;

    return role == "finance" ? true : false;
  },

  isAnalyst(user) {
    const role = user?.role;

    return role == "analyst" ? true : false;
  },

  makeHash(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },

  formatToNaira(amount) {
    amount = parseFloat(amount);

    const pieces = amount.toFixed(0).replace(".", ".").split("");

    let ii = pieces.length - (0 ? 0 + 1 : 0);

    while ((ii -= 3) > 0) {
      pieces.splice(ii, 0, ",");
    }

    return "₦" + pieces.join("");
  },

  formatToMoney(amount) {
    amount = parseFloat(amount);

    const pieces = amount.toFixed(0).replace(".", ".").split("");

    let ii = pieces.length - (0 ? 0 + 1 : 0);

    while ((ii -= 3) > 0) {
      pieces.splice(ii, 0, ",");
    }

    return pieces.join("");
  },

  truncateWords(sentence, amount, tail = "...") {
    const words = sentence.split(" ");

    if (amount >= words.length) {
      return sentence;
    }

    const truncated = words.slice(0, amount);
    return `${truncated.join(" ")}${tail}`;
  },

  truncateString(sentence, amount, tail = "...") {
    const words = sentence.split("");

    if (amount >= words.length) {
      return sentence;
    }

    const truncated = words.slice(0, amount);
    return `${truncated.join("")}${tail}`;
  },

  durationToTime(millis) {
    if (!millis) {
      return "00:00";
    }

    var seconds = ((millis % 60000) / 1000).toFixed(0);
    // multiply by 1000 because Date() requires miliseconds
    var date = new Date(seconds * 1000);
    var hh = date.getUTCHours();
    var mm = date.getUTCMinutes();
    var ss = date.getSeconds();
    // If you were building a timestamp instead of a duration, you would uncomment the following line to get 12-hour (not 24) time
    // if (hh > 12) {hh = hh % 12;}
    // These lines ensure you have two-digits
    if (hh < 10) {
      hh > 0 ? (hh = "0" + hh) : (hh = "");
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (ss < 10) {
      ss = "0" + ss;
    }
    // This formats your string to HH:MM:SS
    return `${hh != "" ? hh + ":" : ""}${mm != "" ? mm + ":" : ""}${
      ss != "" ? ss : ""
    }`;
  },

  async getData(url, accessToken = null) {
    try {
      let response;
      if (accessToken) {
        response = await Axios.get(url, {
          headers: {
            "x-access-token": accessToken,
          },
        });
      } else {
        response = await Axios.get(url);
      }
      return response.data;
    } catch (err) {
      if (err.response) {
        if (err.response.data.errors) {
          for (var key in err.response.data.errors) {
            if (err.response.data.errors.hasOwnProperty(key)) {
              toast(`${err.response.data.errors[key] || "An error occured"}`, {
                position: "top-right",
                type: "error",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        } else {
          toast(
            `${
              err.response && err.response.data.message
                ? err.response.data.message
                : "Internal Server Error"
            }`,
            {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      } else {
        toast(`${err.response ? err.response.data.message : "Network Error"}`, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      return [];
    }
  },

  async postData(url, accessToken = null, formData = {}) {
    try {
      let response = await Axios.post(url, formData, {
        headers: {
          "x-access-token": accessToken,
        },
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        if (err.response.data.errors) {
          for (var key in err.response.data.errors) {
            if (err.response.data.errors.hasOwnProperty(key)) {
              toast(`${err.response.data.errors[key] || "An error occured"}`, {
                position: "top-right",
                type: "error",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        } else {
          toast(
            `${
              err.response && err.response.data.message
                ? err.response.data.message
                : "Internal Server Error"
            }`,
            {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      } else {
        toast(`${err.response ? err.response.data.message : "Network Error"}`, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      return [];
    }
  },

  async putData(url, accessToken = null, formData = {}) {
    try {
      let response = await Axios.put(url, formData, {
        headers: {
          "x-access-token": accessToken,
        },
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        if (err.response.data.errors) {
          for (var key in err.response.data.errors) {
            if (err.response.data.errors.hasOwnProperty(key)) {
              toast(`${err.response.data.errors[key] || "An error occured"}`, {
                position: "top-right",
                type: "error",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        } else {
          if (
            err.response.data.status === "Error" &&
            Array.isArray(err.response.data.data)
          ) {
            const errors = err.response.data.data;

            errors.map((err) => {
              return toast(err, {
                position: "top-right",
                type: "error",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
          } else {
            toast(
              `${
                err.response && err.response.data.message
                  ? err.response.data.message
                  : "Internal Server Error"
              }`,
              {
                position: "top-right",
                type: "error",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        }
      } else {
        toast(`${err.response ? err.response.data.message : "Network Error"}`, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      return [];
    }
  },

  async deleteData(url, accessToken = null) {
    try {
      let response = await Axios.delete(url, {
        headers: {
          "x-access-token": accessToken,
        },
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        if (err.response.data.errors) {
          for (var key in err.response.data.errors) {
            if (err.response.data.errors.hasOwnProperty(key)) {
              toast(`${err.response.data.errors[key] || "An error occured"}`, {
                position: "top-right",
                type: "error",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        } else {
          toast(
            `${
              err.response && err.response.data.message
                ? err.response.data.message
                : "Internal Server Error"
            }`,
            {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      } else {
        toast(`${err.response ? err.response.data.message : "Network Error"}`, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      return [];
    }
  },

  async postFormData(url, formData = {}) {
    try {
      let response = await Axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "User-Agent": "PostmanRuntime/7.36.0",
        },
      });
      return response.data;
    } catch (err) {
      if (err.response) {
        if (err.response.data.errors) {
          for (var key in err.response.data.errors) {
            if (err.response.data.errors.hasOwnProperty(key)) {
              toast(`${err.response.data.errors[key] || "An error occured"}`, {
                position: "top-right",
                type: "error",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        } else {
          toast(
            `${
              err.response && err.response.data.message
                ? err.response.data.message
                : "Internal Server Error"
            }`,
            {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      } else {
        toast(`${err.response ? err.response.data.message : "Network Error"}`, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      return [];
    }
  },

  async getDYData(url, accessToken = null) {
    try {
      let response;
      if (accessToken) {
        response = await Axios.get(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      } else {
        response = await Axios.get(url);
      }
      return response.data;
    } catch (err) {
      if (err.response) {
        if (err.response.data.errors) {
          for (var key in err.response.data.errors) {
            if (err.response.data.errors.hasOwnProperty(key)) {
              toast(`${err.response.data.errors[key] || "An error occured"}`, {
                position: "top-right",
                type: "error",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        } else {
          toast(
            `${
              err.response && err.response.data.message
                ? err.response.data.message
                : "Internal Server Error"
            }`,
            {
              position: "top-right",
              type: "error",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      } else {
        toast(`${err.response ? err.response.data.message : "Network Error"}`, {
          position: "top-right",
          type: "error",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      return [];
    }
  },
};
