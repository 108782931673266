import React, { useState, useEffect, useRef, useContext } from "react";
import Axios from "../../utils/axios";
import { AuthContext, LOGIN } from "../../context/auth";
import { showToast } from "utils/general";
import {
	getOurProducts,
	updateOurProduct,
	searchOurProducts,
} from "services/ourProducts";
import {
	searchICGProducts,
	fillFields,
	getICGProducts,
} from "services/icgProducts";

import { getWarehouses } from "services/warehouseApis";
import { Helper } from "helper";
import { getOurInactiveProducts } from "services/ourProducts";

export const useProducts = () => {
	const [loading, setLoading] = useState(true);
	const [modalFetchLoading, setModalFetchLoading] = useState(false);
	const [modalLoading, setModalLoading] = useState(false);
	const [syncLoading, setSyncLoading] = useState(false);
	const [validated, setValidated] = useState(false);
	const [searchValidated, setSearchValidated] = useState(false);
	const [modalValidated, setModalValidated] = useState(false);
	const [keyword, setKeyword] = useState("");
	const [start, setStart] = useState(1);
	const [end, setEnd] = useState(10);
	const [S, setS] = useState("");
	const [E, setE] = useState("");
	const [modalIsOpen, setIsOpen] = useState(false);
	const [isGs1FrontImage, setIsGs1FrontImage] = useState(false);
	const [isGs1BackImage, setIsGs1BackImage] = useState(false);
	const [icgProducts, setICGProducts] = useState([]);
	const [products, setProducts] = useState([]);
	const [currentProduct, setCurrentProduct] = useState(null);
	const [productIsUpdate, setProductIsUpdate] = useState(false);
	const [modalFormData, setModalFormData] = useState({});
	const [results, setResults] = useState({});
	const [limit] = useState(10);
	const [stocks, setStocks] = useState([]);
	const [processedWarehouses, setProcessedWarehouses] = useState([]);
	const [warehousesStock, setWarehousesStock] = useState([]);

	const [navState, setNavState] = useState("our-products");

	const context = useContext(AuthContext);
	let keywordInput = useRef(null);
	let startInput = useRef(null);
	let endInput = useRef(null);
	// for inputs
	let frontimageFileInput = useRef(null);
	let backimageFileInput = useRef(null);

	if (
		Helper.isFinance(context.authState?.admin) ||
		Helper.isAdministrator(context.authState?.admin)
	) {
		return (
			<div style={{ textAlign: "center" }}>
				<small>You are not permitted to view this page</small>
			</div>
		);
	}

	const fetchICGProducts = async (sD, eD) => {
		setLoading(true);
		await getICGProducts(sD, eD, (res) => {
			setLoading(false);
			if (!res?.status) {
				showToast(`Oops. failed to fetch products from ${sD} to ${eD}`);
				return;
			}

			setICGProducts(res.data);
		});
	};

	const getProducts = async (offSet = 1, S = "", E = "") => {
		setLoading(true);
		const { token } = context.authState;
		await getOurProducts(
			token,
			offSet,
			limit,
			(res) => {
				setLoading(false);
				if (!res?.status) {
					showToast(
						`Oops. failed to fetch products from ${start} to ${end}`
					);
					return;
				}

				setResults(res?.data || []);
				setProducts(res?.data?.results || []);
			},
			S,
			E
		);
	};

	const getInactiveProducts = async (offSet = 1, S = "", E = "") => {
		setLoading(true);
		const { token } = context.authState;
		await getOurInactiveProducts(
			token,
			offSet,
			limit,
			(res) => {
				setLoading(false);
				if (!res?.status) {
					showToast(
						`Oops. failed to fetch products from ${start} to ${end}`
					);
					return;
				}

				setResults(res?.data || []);
				setProducts(res?.data?.results || []);
			},
			S,
			E
		);
	};

	const handlePageClick = (data) => {
		let selected = data.selected + 1;
		getProducts(selected, S, E);
	};

    const handleInactivePagination = (data) => {
		let selected = data.selected + 1;
		getInactiveProducts(selected, S, E);
	};

	const searchICG = async (keyword) => {
		setLoading(true);
		const { token } = context.authState;
		await searchICGProducts(keyword, (res) => {
			setLoading(false);
			if (!res?.status) {
				showToast(
					`Oops. failed to fetch products with keyword ${keyword}`
				);
				return;
			}

			setICGProducts(res.data);
		});
	};

	const searchProducts = async (keyword) => {
		setLoading(true);
		const { token } = context.authState;
		await searchOurProducts(token, keyword, (res) => {
			setLoading(false);
			if (!res?.status) {
				showToast(
					`Oops. failed to fetch products with keyword ${keyword}`
				);
				return;
			}

			setResults(res?.data || []);
			setProducts(res?.data?.results || []);
		});
	};

	const checkStatus = (data) => {
		const isUpdated =
			data.Address ||
			data.Category ||
			data.Package ||
			data.FrontImage ||
			data.BackImage
				? true
				: false;

		return isUpdated;
	};

	const checkImageStatus = (data) => {
		const isUpdated = data.FrontImage || data.BackImage ? true : false;

		return isUpdated;
	};

	const handleSubmit = async (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
			setValidated(false);
		} else {
			const startValue = startInput ? startInput.current.value : null;
			const endValue = endInput ? endInput.current.value : null;
			setStart(startValue);
			setEnd(endValue);
			fetchICGProducts(startValue, endValue);
		}

		setValidated(true);
	};

	const handleRangeSearch = async (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
			setValidated(false);
		} else {
			const startValue = startInput ? startInput.current.value : null;
			const endValue = endInput ? endInput.current.value : null;
			setS(startValue);
			setE(endValue);
			getProducts(1, startValue, endValue);
		}

		setValidated(true);
	};

	const handleSearch = async (event) => {
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
			setSearchValidated(false);
		} else {
			const keyword = keywordInput ? keywordInput.current.value : null;
			setKeyword(keyword);
			if (navState == "our-products") {
				searchProducts(keyword);
			} else {
				searchICG(keyword);
			}
		}

		setSearchValidated(true);
	};

	// Set data
	const handleModalChange = (key, value) => {
		console.log("handleModalChange >>> ", key, value);
		return setModalFormData({ ...modalFormData, [key]: value });
	};

	const updateICGProducts = async (event) => {
		console.log("updateICGProducts >>> ", modalFormData);
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			console.log(
				"checkValidity >>> ",
				form.checkValidity(),
				event.currentTarget
			);
			showToast("All fields are required");
			event.preventDefault();
			event.stopPropagation();
			setModalValidated(false);
			return false;
		}

		setModalLoading(true);
		if (!modalFormData?.itemid) {
			showToast("This product is not available anymore");
			closeModal();
			return false;
		}

		const { token } = context.authState;
		const images = { frontimageFileInput, backimageFileInput };
		await updateOurProduct(
			products,
			modalFormData,
			images,
			token,
			(res) => {
				setModalLoading(false);
				setModalValidated(true);
				if (!res?.status) {
					showToast(`Oops. Failed to save item`);
					return;
				}

				// setProducts(res.data);
				getProducts()
				getInactiveProducts()
				closeModal();
				showToast("Update Successful", "success");
			}
		);
	};

	const editProduct = async (item) => {
		// pop up edit modal
		setCurrentProduct(item);
		setModalLoading(false);
		setModalValidated(false);
		setIsOpen(true);
	};

	const closeModal = () => {
		setModalLoading(false);
		setModalValidated(false);
		setIsGs1FrontImage(false);
		setIsGs1BackImage(false);
		fillModalInputFields(currentProduct, "reset");
		setIsOpen(false);
	};

	const afterOpenModal = async () => {
		if (checkStatus(currentProduct)) {
			setProductIsUpdate(true);
			fillModalInputFields(currentProduct, "item");
			return false;
		}
		// fetch info fron GS1
		setProductIsUpdate(false);
		if (!currentProduct || !currentProduct.Barcode) {
			setModalFetchLoading(false);
			showToast("Failed to get selected product item. Try again");
			return false;
		}

		setModalFetchLoading(true);
		try {
			let response = await Helper.getData(
				`${process.env.REACT_APP_API_GS1}?gtin=${currentProduct.Barcode}`
			);

			if (response && response.returnedObject) {
				fillModalInputFields(response.returnedObject, "gs1");
				setModalFetchLoading(false);
			} else {
				setModalFetchLoading(false);
				fillModalInputFields(
					{
						ItemID: currentProduct?.ItemID,
						Barcode: currentProduct.Barcode,
					},
					"gs1"
				);
			}
		} catch (error) {
			setModalFetchLoading(false);
		}
	};

	const afterOpenOurProductsModal = async () => {
		setProductIsUpdate(true);
		await fillModalInputFields(currentProduct, "item");
		// await fetchWarehouseStocks(currentProduct?._id);
	};

	const fillModalInputFields = async (data, type) => {
		await fillFields(currentProduct, data, type, async (res) => {
			configureImages(res.data?.frontimage, res.data?.backimage);
			setModalFormData(res.data?.newFormData);
		});
	};

	const interpolateStock = async (stocks) => {
		const warehouses = processedWarehouses.map((warehouse) => {
			const stockRes = stocks.find(
				(stock) => stock.warehouseCode == warehouse.code
			);
			warehouse = {
				Qty: stockRes?.quantity ?? 0,
				name: warehouse.name,
				WarehouseCode: warehouse.code,
			};
			return warehouse;
		});
		// console.log("warehouses >> ", warehouses);
		setWarehousesStock(warehouses);
	};

	const updateStockValue = async (warehouse, quantity) => {
		const warehouses = warehousesStock.map((pWarehouse) => {
			if (pWarehouse.WarehouseId === warehouse.WarehouseId) {
				pWarehouse.Qty = parseInt(quantity == "" ? 0 : quantity);
			}
			return pWarehouse;
		});
		setWarehousesStock(warehouses);
	};

	const configureImages = (frontimage, backimage) => {
		frontimage && frontimage != ""
			? setIsGs1FrontImage(true)
			: setIsGs1FrontImage(false);

		backimage && backimage != ""
			? setIsGs1BackImage(true)
			: setIsGs1BackImage(false);
	};

	const fetchWarehouseData = async () => {
		const { token } = context.authState;
		setLoading(true);
		try {
			let processedWarehousesResponse = await getWarehouses(token);

			console.log("processedWarehousesResponse >>> ", processedWarehousesResponse)

			if (
				processedWarehousesResponse &&
				processedWarehousesResponse?.data
			) {
				setProcessedWarehouses(processedWarehousesResponse?.data || []);
			}
			setLoading(false);
		} catch (error) {
			showToast(`Oops. failed to fetch warehouses`);
			setLoading(false);
		}
	};

	const fetchWarehouseStocks = async (productID) => {
		const { token } = context.authState;
		setLoading(true);
		try {
			let stockResponse = await Helper.getData(
				`${process.env.REACT_APP_API_V2}/admin/products/stock/${productID}`,
				token
			);

			if (stockResponse && stockResponse?.data) {
				setStocks(stockResponse?.data || []);
				interpolateStock(stockResponse?.data || []);
			}
			setLoading(false);
		} catch (error) {
			showToast(`Oops. failed to fetch warehouses`);
			setLoading(false);
		}
	};

	const syncStock = async (warehouse) => {
		const { token } = context.authState;
		setLoading(true);
		try {
			let processedWarehousesResponse = await Helper.putData(
				`${process.env.REACT_APP_API_V2}/admin/products/sync/stock/${warehouse}`,
				token
			);
			setLoading(false);
		} catch (error) {
			showToast(`Oops. failed to fetch warehouses`);
			setLoading(false);
		}
	};

	const updateStock = async (warehouse) => {
		const { token } = context.authState;
		setLoading(true);
		try {
			await Helper.putData(
				`${process.env.REACT_APP_API_V2}/admin/products/stock/${currentProduct?._id}/update`,
				token,
				warehousesStock
			);
			setLoading(false);
		} catch (error) {
			showToast(`Oops. failed to fetch warehouses`);
			setLoading(false);
		}
	};

	return {
		frontimageFileInput,
		backimageFileInput,
		handlePageClick,
		checkImageStatus,
		handleSubmit,
		handleRangeSearch,
		handleSearch,
		handleModalChange,
		updateICGProducts,
		editProduct,
		afterOpenModal,
		afterOpenOurProductsModal,
		updateStockValue,
		syncStock,
		updateStock,
		loading,
		modalFetchLoading,
		modalLoading,
		syncLoading,
		validated,
		searchValidated,
		modalValidated,
		keyword,
		modalIsOpen,
		isGs1FrontImage,
		isGs1BackImage,
		icgProducts,
		productIsUpdate,
		results,
		stocks,
		navState,
		start,
		end,
		S,
		E,
		keywordInput,
		startInput,
		endInput,
		processedWarehouses,
		warehousesStock,
		products,
		currentProduct,
		modalFormData,
		limit,
		setNavState,
		setModalFormData,
		fetchICGProducts,
		getProducts,
		closeModal,
		fetchWarehouseData,
		getInactiveProducts,
        handleInactivePagination
	};
};
