import React, { useState, useEffect, useRef, useContext } from "react";
import {
	Button,
	Card,
	Form,
	Table,
	Container,
	Row,
	Col,
	Nav,
} from "react-bootstrap";

import Modal from "react-modal";
import Loader from "react-loader-spinner";
import Axios from "../utils/axios";

import { AuthContext, LOGIN } from "../context/auth";

import { Helper } from "../helper";
import CustomStyle from "../styles";
import { showToast } from "utils/general";
import { getDYCategories } from "services/dynamics";

Modal.setAppElement("#root");

function Categories() {
	const context = useContext(AuthContext);

	const [loading, setLoading] = useState(true);
	const [start, setStart] = useState(1);
	const [end, setEnd] = useState(10);
	const [erpCategories, setERPCategories] = useState([]);
	const [categories, setCategories] = useState([]);
	const [newCategories, setNewCategories] = useState([]);
	const [navState, setNavState] = useState("erp-categories");

	useEffect(() => {
		getERPCategories(start, end);
	}, []);

	useEffect(() => {
		if (erpCategories?.length > 0) getCategories();
	}, [erpCategories]);

	useEffect(() => {
		intepolateCategories();
	}, [categories]);

	const getERPCategories = async (sD, eD) => {
		setLoading(true);
		try {
			const { token } = context.authState;
			let { data } = await getDYCategories(token);

			console.log("ERP categories >>> ", data);

			setERPCategories(data);
			setLoading(false);
		} catch (error) {
			showToast(
				`Oops. failed to fetch categories from ${start} to ${end}`
			);
			setLoading(false);
		}
	};

	const getCategories = async () => {
		setLoading(true);
		try {
			const { token } = context.authState;
			let response = await Helper.getData(
				`${process.env.REACT_APP_API_V2}/admin/category`,
				token
			);

			if (response) {
				setCategories(response.data);
			}
			setLoading(false);
		} catch (error) {
			showToast(
				`Oops. failed to fetch categories from ${start} to ${end}`
			);
			setLoading(false);
		}
	};

	const intepolateCategories = async () => {
		const newCategories = erpCategories.map((category) => {
			// const cate = categories.find((cate) => cate.DeptID === category.DeptID);
			const cate = categories.find(
				(cate) => cate.DeptID === category.Category.toUpperCase()
			);
			if (!!cate) {
				category.status = true;
				category.banner = cate?.banner;
			} else {
				category.status = false;
				category.banner = "";
			}
			return category;
		});

		setNewCategories(newCategories);
	};

	const updateCategoryStatus = async (data) => {
		const { token } = context.authState;

		const formData = { ...data, status: !data.status };

		setLoading(true);
		try {
			let response = Axios.post(
				`${process.env.REACT_APP_API_V2}/admin/category`,
				formData,
				{
					headers: {
						"x-access-token": token,
					},
				}
			);

			if (response) {
				getERPCategories(start, end);
			}
			setLoading(false);
		} catch (error) {
			showToast(
				`Oops. failed to fetch categories from ${start} to ${end}`
			);
			setLoading(false);
		}
	};

	const updatePosition = async (e, item) => {
		const { token } = context.authState;

		const formData = { position: e.target.value, itemID: item._id };

		setLoading(true);
		try {
			let response = Axios.patch(
				`${process.env.REACT_APP_API_V2}/admin/category/position`,
				formData,
				{
					headers: {
						"x-access-token": token,
					},
				}
			);

			// if (response) {
			// 	getERPCategories(start, end);
			// }
			setLoading(false);
		} catch (error) {
			showToast(
				`Oops. failed to fetch categories from ${start} to ${end}`
			);
			setLoading(false);
		}
	};

	const handleUpdatebanner = async (event, item) => {
		const { token } = context.authState;
		const formData = new FormData();
		formData.append("media", event.target.files[0]);
		setLoading(true);

		try {
			let response = await Axios.put(
				// `${process.env.REACT_APP_API_V2}/admin/category/banner/${item.DeptID}`,
				`${process.env.REACT_APP_API_V2}/admin/category/banner/${item._id}`,
				formData,
				{
					headers: {
						"x-access-token": token,
					},
				}
			);

			await getCategories();
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	};

	if (Helper.isFinance(context.authState?.admin)) {
		return (
			<div style={{ textAlign: "center" }}>
				<small>You are not permitted to view this page</small>
			</div>
		);
	}

	return (
		<>
			<Container fluid>
				<Nav variant="tabs" defaultActiveKey="erp-categories">
					<Nav.Item>
						<Nav.Link
							eventKey="erp-categories"
							onClick={() => setNavState("erp-categories")}
						>
							By ERP
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey="synced-categories"
							onClick={() => setNavState("synced-categories")}
						>
							By Synced
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<Row>
					<Col md="12">
						<Card className="strpied-tabled-with-hover">
							<Card.Header>
								<Row>
									<Col md="6">
										{/* <i
											className="nc-icon nc-app"
											style={{ fontSize: 100 }}
										></i> */}
										<Card.Title as="h4">
											Categories Table
										</Card.Title>
										<p className="card-category">
											View and modify all categories{" "}
											<span className="text-info">
												(Showing all categories)
											</span>
										</p>
									</Col>
								</Row>
							</Card.Header>
							<Card.Body className="table-full-width table-responsive px-0">
								{loading ? (
									<div
										style={{
											flex: 1,
											textAlign: "center",
											padding: "20px 0px",
										}}
									>
										<Loader
											type="Oval"
											color={CustomStyle.colors.primary}
											height={50}
											width={50}
										/>
									</div>
								) : (
									<>
										{navState == "erp-categories" && (
											<Table className="table-hover table-striped">
												<thead>
													<tr>
														<th className="border-0">
															ID
														</th>
														<th className="border-0">
															Name
														</th>
														<th className="border-0">
															Status
														</th>
														<th className="border-1"></th>
													</tr>
												</thead>
												<tbody>
													{erpCategories?.length >
													0 ? (
														erpCategories.map(
															(item, index) => {
																return (
																	<tr
																		key={
																			index
																		}
																	>
																		<td>
																			{
																				// item.DeptID
																				index +
																					1
																			}
																		</td>
																		<td>
																			{
																				item.Category
																			}
																		</td>
																		<td>
																			{!!item?.status ? (
																				<small className="text-success">
																					Active
																				</small>
																			) : (
																				<small className="text-warning">
																					Inactive
																				</small>
																			)}
																		</td>
																		<td>
																			<Button
																				className="btn-fill form-control"
																				type="submit"
																				variant="primary"
																				style={{
																					background:
																						CustomStyle
																							.colors
																							.primary,
																					borderColor:
																						CustomStyle
																							.colors
																							.primary,
																				}}
																				onClick={() =>
																					updateCategoryStatus(
																						item
																					)
																				}
																			>
																				<i className="nc-icon nc-preferences-circle-rotate"></i>
																			</Button>
																		</td>
																	</tr>
																);
															}
														)
													) : (
														<tr>
															<td
																colSpan="6"
																style={{
																	textAlign:
																		"center",
																	color: CustomStyle
																		.colors
																		.danger,
																}}
															>
																<small>
																	No product
																	found
																</small>
															</td>
														</tr>
													)}
												</tbody>
											</Table>
										)}

										{navState == "synced-categories" && (
											<Table className="table-hover table-striped">
												<thead>
													<tr>
														<th className="border-0">
															ID
														</th>
														<th className="border-0">
															Name
														</th>
														<th className="border-0">
															Position
														</th>
														<th className="border-0">
															Image
														</th>
														<th className="border-0">
															Status
														</th>
													</tr>
												</thead>
												<tbody>
													{categories?.length > 0 ? (
														categories.map(
															(item, index) => {
																return (
																	<tr
																		key={
																			index
																		}
																	>
																		<td>
																			{
																				// item.DeptID
																				index +
																					1
																			}
																		</td>
																		<td>
																			{
																				item.Description
																			}
																		</td>
																		{/* <td>{item?.position}</td> */}
																		<td>
																			<input
																				name="position"
																				defaultValue={
																					item?.position ??
																					1
																				}
																				type="number"
																				onChange={(
																					e
																				) =>
																					updatePosition(
																						e,
																						item
																					)
																				}
																			/>
																		</td>
																		<td>
																			{!!item?.status ? (
																				!!item?.banner ? (
																					<>
																						<a
																							href={
																								item?.banner
																							}
																							target={
																								"_blank"
																							}
																							rel="noreferrer noopener"
																						>
																							<small
																								className="text-success"
																								style={{
																									fontSize: 11,
																								}}
																							>
																								View
																							</small>
																						</a>
																						<small>
																							{" "}
																							|{" "}
																						</small>
																						<Form.Label>
																							<small
																								className="text-primary"
																								style={{
																									textTransform:
																										"none",
																								}}
																							>
																								Update
																							</small>
																							<Form.File
																								style={{
																									display:
																										"none",
																								}}
																								label="Add Banner"
																								onChange={(
																									e
																								) =>
																									handleUpdatebanner(
																										e,
																										item
																									)
																								}
																							/>
																						</Form.Label>
																					</>
																				) : (
																					<Form.Group>
																						<Form.Label>
																							<small
																								className="text-primary"
																								style={{
																									textTransform:
																										"none",
																								}}
																							>
																								Add
																								Banner
																							</small>
																							<Form.File
																								style={{
																									display:
																										"none",
																								}}
																								label="Add Banner"
																								onChange={(
																									e
																								) =>
																									handleUpdatebanner(
																										e,
																										item
																									)
																								}
																							/>
																						</Form.Label>
																					</Form.Group>
																				)
																			) : (
																				<span>
																					...
																				</span>
																			)}
																		</td>
																		<td>
																			{!!item?.status ? (
																				<small className="text-success">
																					Active
																				</small>
																			) : (
																				<small className="text-warning">
																					Inactive
																				</small>
																			)}
																		</td>
																	</tr>
																);
															}
														)
													) : (
														<tr>
															<td
																colSpan="6"
																style={{
																	textAlign:
																		"center",
																	color: CustomStyle
																		.colors
																		.danger,
																}}
															>
																<small>
																	No product
																	found
																</small>
															</td>
														</tr>
													)}
												</tbody>
											</Table>
										)}
									</>
								)}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Categories;
