import { useContext, useRef, useState } from "react";
import { AuthContext } from "../../context/auth";
import { showToast } from "../../utils/general";
import { Helper } from "../../helper";
import Axios from "../../utils/axios";
import moment from "moment";
import { getWarehouses } from "services/warehouseApis";

const STORE_INFO = {
  contactName: "Mr. Adeniyi",
  contactNumber: "08053244030",
};

export function useOrders() {
  const [loading, setLoading] = useState(true);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [shipLoading, setShipLoading] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [orders, setOrders] = useState({});
  const [status, setStatus] = useState("Pending");
  const [results, setResults] = useState([]);
  const [csvResults, setCsvResults] = useState([]);
  const [limit, setLimit] = useState(50);
  const [searchValidated, setSearchValidated] = useState(false);
  const [confirmData, setConfirmData] = useState({});
  const [shipData, setShipData] = useState({});
  const [shipInterstateData, setShipInterstateData] = useState({});
  const [completeData, setCompleteData] = useState({});
  const [declineData, setDeclineData] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const [processedWarehouses, setProcessedWarehouses] = useState([]);
  const [currentWarehouse, setCurrentWarehouse] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedOrderIDs, setSelectedOrderIDs] = useState([]);
  const [selectedDeliveryAddresses, setSelectedDeliveryAddresses] = useState(
    []
  );

  const [selectedForInterStateDelivery, setSelectedForInterStateDelivery] =
    useState([]);
  const [navState, setNavState] = useState("pickup");
  const [interstateDeliveryResults, setInterstateDeliveryResults] = useState(
    []
  );
  const [deliveryResults, setDeliveryResults] = useState([]);
  const [pickupResults, setPickupResults] = useState([]);
  const [selectedForDelivery, setSelectedForDelivery] = useState([]);
  const [productsForDelivery, setProductsForDelivery] = useState([]);
  const [deliveryModalOpen, setDeliveryModalOpen] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(1);
  const [quote, setQuote] = useState(null);
  const [storeInfo, setStoreInfo] = useState(STORE_INFO);

  const context = useContext(AuthContext);
  let keywordInput = useRef(null);
  let trackInput = useRef(null);

  const prepareForCSV = async () => {
    let newResults = [];

    results.forEach(async (r) => {
      r.customer = r.userId?.name ?? r.customer;
      r.customerEmail = r.userId?.email ?? r.customerEmail;
      r.customerPhone = r.userId?.phone;
      r.warehouse = r.deliveryAddress.warehouse.name;
      r.cart.map((cart, index) => {
        r[`item${index}`] = cart.productName;
        r[`itemQty${index}`] = cart.quantity;
        r[`itemPrice${index}`] = cart.price;
        r[`itemCat${index}`] = cart.category;
      });
      newResults.push(r);
    });

    setCsvResults(newResults);
  };

  const fetchWarehouseData = async () => {
    const { token } = context.authState;
    setLoading(true);
    try {
      let processedWarehousesResponse = await getWarehouses(token);

      if (processedWarehousesResponse && processedWarehousesResponse?.data) {
        setProcessedWarehouses(processedWarehousesResponse?.data || []);
      }
      setLoading(false);
    } catch (error) {
      showToast(`Oops. failed to fetch warehouses`);
      setLoading(false);
    }
  };

  const fetchWarehouseOrders = async (warehouse) => {
    const { token } = context.authState;
    const id = warehouse._id;
    setLoading(true);

    setCurrentWarehouse(warehouse);

    try {
      let ordersResponse = await Helper.getData(
        `${
          process.env.REACT_APP_API_V2
        }/admin/orders/warehouse/${id}?deliveryOption=${navState}&status=${status.toLowerCase()}`,
        token
      );

      setOrders(ordersResponse?.data || []);
      setResults(ordersResponse?.data?.results || []);
      // await separateResults(ordersResponse?.data?.results || []);
      setLoading(false);
      setSelectedOrders([]);
      setSelectedForDelivery([]);
      setProductsForDelivery([]);
      setSelectedOrderIDs([]);
      setSelectedDeliveryAddresses([]);
    } catch (error) {
      showToast(`Oops. failed to fetch orders`);
      setLoading(false);
    }
  };

  const fetchData = async (
    offSet = 1,
    endPoint = `${
      process.env.REACT_APP_API_V2
    }/admin/orders?createdAt=desc&limit=${limit}&page=${offSet}&deliveryOption=${navState}&status=${status.toLowerCase()}`
    // params
  ) => {
    setResults([]);
    separateResults([]);
    const { token } = context.authState;
    setLoading(true);
    setCurrentWarehouse(null);
    try {
      // let endPoint = `${
      //   process.env.REACT_APP_API_V2
      // }/admin/orders?createdAt=desc&limit=${limit}&page=${offSet}&deliveryOption=${navState}&status=${status.toLowerCase()}`;

      let ordersResponse = await Helper.getData(endPoint, token);

      setOrders(ordersResponse?.data || []);

      setResults(ordersResponse?.data?.results || []);
      // await separateResults(ordersResponse?.data?.results || []);
      setLoading(false);

      setSelectedOrders([]);
      setSelectedForDelivery([]);
      setProductsForDelivery([]);
      setSelectedOrderIDs([]);
      setSelectedDeliveryAddresses([]);
    } catch (error) {
      showToast(`Oops. failed to fetch orders`);
      setLoading(false);
    }
  };

  const separateResults = async (results) => {
    const interState = results.filter(
      (result) => result.deliveryOption == "delivery" && !!result.isInterState
    );
    const intraState = results.filter(
      (result) => result.deliveryOption == "delivery" && !result.isInterState
    );
    const pickupResult = results.filter(
      (result) => result.deliveryOption != "delivery"
    );

    // console.log("Pickup result >>> ", pickupResult)
    setInterstateDeliveryResults(interState);
    setDeliveryResults(intraState);
    setPickupResults(pickupResult);
  };

  const arrangePackages = (item) => {
    return {
      PackageRef: item?.id,
      PackageDescription: item?.cart
        .map((itm) => itm?.productName ?? "TEST_DESC")
        .toString(),
      DeliveryContactName: item?.userId?.name,
      DeliveryContactNumber: item?.userId?.phone ?? "08128383838",
      DeliveryGooglePlaceAddress:
        item?.deliveryAddress?.userLocation?.address ?? "",
      DeliveryLandmark: "",
    };
  };

  const selectPendingDelivery = (e) => {
    try {
      if (!currentWarehouse) {
        throw {
          message:
            "You must select a particular warehouse to process a delivery.",
        };
      }

      if (!!e.target.checked) {
        deliveryResults.forEach((result) => {
          if (result?.status == "confirmed") {
            setSelectedForDelivery((prev) => [
              ...prev,
              arrangePackages(result),
            ]);
            setProductsForDelivery((prev) => [...prev, ...result.cart]);
            setSelectedOrders((prev) => [...prev, result]);
            setSelectedOrderIDs((prev) => [...prev, result._id]);
            setSelectedDeliveryAddresses((prev) => [
              ...prev,
              result?.deliveryAddress?.userLocation?.address,
            ]);
          }
        });
      } else {
        setSelectedOrders([]);
        setSelectedForDelivery([]);
        setProductsForDelivery([]);
        setSelectedOrderIDs([]);
        setSelectedDeliveryAddresses([]);
      }
    } catch (error) {
      const errorMessage = error?.message ?? error?.response?.data?.message;
      console.log("selectPendingDelivery Error >>> ", errorMessage);
      showToast("Oops. " + errorMessage);
    }
  };

  const handleSearch = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setSearchValidated(false);
    } else {
      const keyword = keywordInput ? keywordInput.current.value : null;
      searchData(keyword);
    }

    setSearchValidated(true);
  };

  const searchData = async (keyword) => {
    const result = [];
    const { token } = context.authState;
    setLoading(true);
    try {
      let ordersResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/orders/${keyword}`,
        token
      );

      if (ordersResponse && ordersResponse?.data) {
        result.push(ordersResponse?.data);
        setOrders([]);
        setResults(result);
      }
      setLoading(false);
    } catch (error) {
      showToast(`Oops. failed to fetch orders`);
      setLoading(false);
    }
  };

  const declineOrder = async (item) => {
    setDeclineData(item);
  };

  const declineOrderSubmit = async (orderId) => {
    setDeclineLoading(true);

    try {
      const { token } = context.authState;
      let response = await Axios.put(
        `${process.env.REACT_APP_API_V2}/admin/orders/decline/${orderId}`,
        {},
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { data } = response?.data;

      showToast(`Order Declined`, "success");
      fetchData();

      setDeclineData({});
      setDeclineLoading(false);
    } catch (error) {
      showToast(`Oops. something went wrong while saving item`);
      setDeclineLoading(false);
    }
  };

  const confirmOrder = async (item) => {
    setConfirmData(item);
  };

  const confirmOrderSubmit = async (orderId) => {
    setConfirmLoading(true);

    try {
      const { token } = context.authState;
      let response = await Axios.put(
        `${process.env.REACT_APP_API_V2}/admin/orders/confirm/${orderId}`,
        {},
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { data } = response?.data;

      showToast(`Order Confirmed`, "success");
      fetchData();
      setConfirmData({});
      setConfirmLoading(false);
    } catch (error) {
      showToast("Oops. something went wrong while saving item");
      setConfirmLoading(false);
    }
  };

  const shipOrder = async (item) => {
    setShipData(item);
  };
  const shipInterstataOrder = (item) => setShipInterstateData(item);
  const shipOrderSubmit = async (orderId) => {
    const trackId = trackInput?.current?.value ?? "";
    setShipLoading(true);

    try {
      const { token } = context.authState;
      let response = await Axios.put(
        `${process.env.REACT_APP_API_V2}/admin/orders/ship/${orderId}`,
        { trackId },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { data } = response?.data;

      showToast("Order Shipped", "success");
      fetchData();

      setShipData({});
      setShipLoading(false);
    } catch (error) {
      showToast("Oops. something went wrong while saving item");
      setShipLoading(false);
    }
  };

  const completeOrder = async (item) => {
    if (item?.paymentStatus != "success") {
      showToast(ToastPaymentMessage, "info");
      return false;
    }
    setCompleteData(item);
  };

  const completeOrderSubmit = async (orderId) => {
    setCompleteLoading(true);

    try {
      const { token } = context.authState;
      let response = await Axios.put(
        `${process.env.REACT_APP_API_V2}/admin/orders/complete/${orderId}`,
        {},
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { data } = response?.data;

      showToast("Order Completed", "success");
      fetchData();
      setCompleteData({});
      setCompleteLoading(false);
    } catch (error) {
      showToast("Oops. something went wrong while saving item");
      setCompleteLoading(false);
    }
  };

  const paymentOrder = async (item) => {
    setPaymentData(item);
  };

  const paymentOrderSubmit = async (orderId) => {
    setPaymentLoading(true);

    try {
      const { token } = context.authState;
      let response = await Axios.put(
        `${process.env.REACT_APP_API_V2}/admin/orders/toggle-payment/${orderId}?paymentStatus=success`,
        {},
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { data } = response?.data;

      if (data && data?._id) {
        showToast("Order Payment Confirmed", "success");
        const index = results
          ? results?.findIndex((p) => p?._id == data?._id)
          : -1;
        if (index > -1) {
          const updatedOrders = results;
          const updated = {
            ...updatedOrders[index],
            paymentStatus: data?.paymentStatus,
          };
          updatedOrders[index] = updated;
          setResults([...updatedOrders]);
        }
      } else {
        showToast("Oops. Failed to save item");
      }

      setPaymentData({});
      setPaymentLoading(false);
    } catch (error) {
      showToast("Oops. something went wrong while saving item");
      setPaymentLoading(false);
    }
  };

  const handlePageClick = (data) => {
    let selected = data.selected + 1;
    fetchData(selected);
  };

  const getOrderValue = (cart) => {
    return cart.reduce(
      (acc, item) => acc + Number(item.price) * item.quantity,
      0
    );
  };

  const handleCheckbox = (item) => {
    try {
      if (!currentWarehouse) {
        throw {
          message:
            "You must select a particular warehouse to process a delivery.",
        };
      }
      if (selectedOrderIDs.includes(item._id)) {
        setSelectedForDelivery([]);
        setSelectedOrderIDs();
        setProductsForDelivery([]);
        setSelectedDeliveryAddresses([]);

        selectedOrders.forEach((result) => {
          if (result?._id != item._id) {
            setSelectedForDelivery((prev) => [
              ...prev,
              arrangePackages(result),
            ]);
            setProductsForDelivery((prev) => [...prev, ...result.cart]);
            setSelectedOrderIDs((prev) => [...prev, result._id]);
            setSelectedDeliveryAddresses((prev) => [
              ...prev,
              result?.deliveryAddress?.userLocation?.address,
            ]);
          }
        });

        const newOrders = selectedOrders.filter((i) => i._id != item._id);
        setSelectedOrderIDs(newOrders);
      } else {
        setSelectedForDelivery((prev) => [...prev, arrangePackages(item)]);
        setProductsForDelivery((prev) => [...prev, ...item.cart]);
        setSelectedOrderIDs((prev) => [...prev, item._id]);
        setSelectedDeliveryAddresses((prev) => [
          ...prev,
          item?.deliveryAddress?.userLocation?.address,
        ]);
      }
    } catch (error) {
      const errorMessage = error?.message ?? error?.response?.data?.message;
      console.log("handleCheckbox Error >>> ", errorMessage);
      showToast("Oops. " + errorMessage);
    }
  };

  const getVehicles = async () => {
    try {
      let response = await Axios.get(
        `${process.env.REACT_APP_DELLYMAN_API}/Vehicles`
      );

      const data = response.data;
      setVehicles(Object.values(data));
    } catch (error) {}
  };

  const getQuote = async () => {
    try {
      if (!currentWarehouse) {
        throw {
          message:
            "You must select a particular warehouse to get a delivery quote.",
        };
      }

      const today = moment().format("YYYY/MM/DD");
      const addresses = new Set(selectedDeliveryAddresses);
      const payload = {
        PaymentMode: "online",
        Vehicle: selectedVehicle,
        PickupRequestedTime: "06 AM to 09 PM",
        PickupRequestedDate: today,
        PickupAddress: currentWarehouse.address,
        DeliveryAddress: Array.from(addresses),
      };

      const { token } = context.authState;
      let response = await Axios.post(
        `${process.env.REACT_APP_API_V2}/admin/orders/delivery/getQuotes`,
        payload,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { data } = response?.data;

      setQuote(data);
    } catch (error) {
      const errorMessage = error?.message ?? error?.response?.data?.message;
      console.log("getQuote Error >>> ", errorMessage);
      showToast("Oops. " + errorMessage);
    }
  };

  const bookOrder = async () => {
    try {
      if (!currentWarehouse?.address) {
        throw {
          message: "You must select a particular warehouse to book an order.",
        };
      }

      const today = moment().format("YYYY/MM/DD");
      const payload = {
        CompanyID: quote.CompanyID,
        PaymentMode: "online",
        Vehicle: selectedVehicle,
        PickUpContactName: `${currentWarehouse.name} store: ${storeInfo.contactName}`,
        PickUpContactNumber: storeInfo.contactNumber,
        PickUpLandmark: "",
        PickUpGooglePlaceAddress: currentWarehouse.address,
        PickUpRequestedTime: "06 AM to 09 PM",
        PickUpRequestedDate: today,
        DeliveryRequestedTime: "06 AM to 09 PM",
        DeliveryTimeline: "sameDay",
        OrderRef: Helper.makeHash(10),
        Packages: selectedForDelivery,
      };

      const { token } = context.authState;
      let response = await Axios.post(
        `${process.env.REACT_APP_API_V2}/admin/orders/delivery/bookOrder`,
        payload,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { data } = response?.data;

      setDeliveryModalOpen(false);

      showToast("Delivery request sent", "success");
      fetchData();
      setQuote(null);
    } catch (error) {
      const errorMessage = error?.message ?? error?.response?.data?.message;
      console.log("BookOrder Error >>> ", errorMessage);
      showToast("Oops. " + errorMessage);
    }
  };

  const bookInterstateOrder = async () => {
    try {
      if (!currentWarehouse?.address) {
        throw {
          message: "You must select a particular warehouse to book an order.",
        };
      }

      setShipLoading(true);
      const today = moment().format("YYYY/MM/DD");
      const payload = {
        PaymentMode: "online",
        Vehicle: selectedVehicle,
        PickUpContactName: `${currentWarehouse.name} store: ${storeInfo.contactName}`,
        PickUpContactNumber: storeInfo.contactNumber,
        PickUpLandmark: "",
        PickUpGooglePlaceAddress: currentWarehouse.address,
        PickUpRequestedTime: "06 AM to 09 PM",
        PickUpRequestedDate: today,
        DeliveryRequestedTime: "06 AM to 09 PM",
        DeliveryTimeline: "sameDay",
        OrderRef: Helper.makeHash(10),
        Packages: [arrangePackages(shipInterstateData)],
      };

      const { token } = context.authState;
      let response = await Axios.post(
        `${process.env.REACT_APP_API_V2}/admin/orders/delivery/bookInterstateOrder`,
        payload,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { data } = response?.data;

      setDeliveryModalOpen(false);

      showToast("Delivery request sent", "success");
      setShipLoading(false);
      setShipInterstateData({});
      fetchData();
    } catch (error) {
      setShipLoading(false);
      const errorMessage = error?.message ?? error?.response?.data?.message;
      console.log("bookInterstateOrder Error >>> ", errorMessage);
      showToast("Oops. " + errorMessage);
    }
  };

  const submitDeliveryRequest = () => {
    // console.log("submitDeliveryRequest >>> ", selectedForDelivery);
  };

  return {
    bookInterstateOrder,
    bookOrder,
    quote,
    setSelectedVehicle,
    selectedVehicle,
    getQuote,
    submitDeliveryRequest,
    productsForDelivery,
    getVehicles,
    vehicles,
    deliveryModalOpen,
    setDeliveryModalOpen,
    selectPendingDelivery,
    deliveryResults,
    pickupResults,
    navState,
    setNavState,
    selectedOrders,
    selectedOrderIDs,
    handleCheckbox,
    loading,
    setLoading,
    confirmLoading,
    setConfirmLoading,
    shipLoading,
    setShipLoading,
    completeLoading,
    setCompleteLoading,
    declineLoading,
    setDeclineLoading,
    paymentLoading,
    setPaymentLoading,
    orders,
    setOrders,
    setStatus,
    status,
    results,
    setResults,
    csvResults,
    setCsvResults,
    limit,
    setLimit,
    searchValidated,
    setSearchValidated,
    confirmData,
    setConfirmData,
    shipData,
    setShipData,
    completeData,
    setCompleteData,
    declineData,
    setDeclineData,
    paymentData,
    setPaymentData,
    deleteData,
    setDeleteData,
    modalLoading,
    setModalLoading,
    processedWarehouses,
    setProcessedWarehouses,
    currentWarehouse,
    setCurrentWarehouse,
    context,
    keywordInput,
    trackInput,
    interstateDeliveryResults,
    prepareForCSV,
    fetchWarehouseData,
    fetchWarehouseOrders,
    fetchData,
    handleSearch,
    searchData,
    declineOrder,
    declineOrderSubmit,
    confirmOrder,
    confirmOrderSubmit,
    shipOrder,
    shipOrderSubmit,
    completeOrder,
    completeOrderSubmit,
    paymentOrder,
    paymentOrderSubmit,
    handlePageClick,
    getOrderValue,
    shipInterstataOrder,
    shipInterstateData,
    setShipInterstateData,
    setSelectedForInterStateDelivery,
    setQuote,
  };
}
