import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
} from "react-bootstrap";
import Loader from "react-loader-spinner";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import { AuthContext } from "../context/auth";
import { Helper } from "../helper";
import CustomStyle from "../styles";
import { CSVLink, CSVDownload } from "react-csv";
import { showToast } from "utils/general";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

function Cart() {
  const [loading, setLoading] = useState(true);
  const [cartResults, setCartResults] = useState({});
  const [results, setResults] = useState([]);
  const [limit, setLimit] = useState(20);
  const [csvResults, setCsvResults] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const context = useContext(AuthContext);

  useEffect(() => {
    if (Helper.isPopulator(context.authState?.admin)) {
      return false;
    }

    getAbandonedCart();
  }, []);

  useEffect(() => {
    prepareForCSV()
  }, [results]);


  const prepareForCSV = async () => {
    let newResults = []
    results.forEach(async r => {
        r.customer = r?.user?.name
        r.customerEmail = r?.user?.email
        r.customerPhone = r?.user?.phone
        r.products.map((cart, index) => {
          r[`item${index}`] = cart.productName
          r[`itemQty${index}`] = cart.quantity
          r[`itemPrice${index}`] = cart.price
          r[`itemCat${index}`] = cart.category
        })
        newResults.push(r)
    })

    console.log("A-Cart CSV data >>> ", newResults)

    setCsvResults(newResults)
  }

  const getAbandonedCart = async (offSet = 1) => {
    const { token } = context.authState;
    setLoading(true);
    try {
      let cartResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/users/cart/abandoned?limit=${limit}&page=${offSet}`,
        token
      );

      console.log("For life >>> ", cartResponse);

      setResults(cartResponse.data.results);
      setCartResults(cartResponse.data)
      setLoading(false);
    } catch (error) {
      showToast(`Oops. failed to fetch cart data`)
      setLoading(false);
    }
  };

  const deleteCart = async (cartId) => {
    const { token } = context.authState;
    setLoading(true);
    try {
      await Helper.deleteData(
        `${process.env.REACT_APP_API_V2}/admin/users/cart/abandoned/${cartId}`,
        token
      );
      
      setDeleteModal(false)
      getAbandonedCart()

    } catch (error) {
      showToast(`Oops. failed to fetch cart data`)
      setLoading(false);
    }
  };

  const handlePageClick = (data) => {
    let selected = data.selected + 1;
    getAbandonedCart(selected);
  };

  const confirmOrder = async (item) => {
    setConfirmData(item);
  };

  if (Helper.isPopulator(context.authState?.admin) || Helper.isFinance(context.authState?.admin)) {
    return (
      <div style={{ textAlign: "center" }}>
        <small>You are not permitted to view this page</small>
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-tasks">
              <Card.Header>
                <Row>
                    <i
                      className="nc-icon nc-chart-pie-36"
                      style={{ fontSize: 30, margin: 10 }}
                    ></i>
                    <Card.Title as="h4" style={{ margin: 10, marginLeft: 5 }}>Abandoned Cart</Card.Title>
                  <Col style={{ margin: 10, marginLeft: 20 }}>
                    <Button className="btn-sm">
                      <CSVLink data={csvResults} className="card-category">
                        Export CSV
                      </CSVLink>
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {loading ? (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    <Loader
                      type="Oval"
                      color={CustomStyle.colors.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <div className="table-full-width">
                    <Table>
                      <tbody>
                        {results && results?.length > 0 ? (
                          results?.map((item, index) => {
                            return (
                              <tr key={index.toString()}>
                                <td>
                                  <div className="media">
                                    <i
                                      className="nc-icon nc-circle-09 text-warning"
                                      style={{ fontSize: 30 }}
                                    ></i>
                                    <div className="media-content pl-2">
                                      <div>
                                        <span>Owner: </span>
                                        <OwnerDropdown user={item.user} />
                                      </div>
                                      <div>
                                        <span>Cart ID: </span>
                                        <b>{item?._id}</b>
                                      </div>
                                      <div>
                                        <span>Purchase: </span>
                                        <ProductDropdown
                                          products={item?.products}
                                        />
                                      </div>
                                      <div>
                                        <span>Payment Type: </span>
                                        <b>{item?.paymentOption}</b>
                                      </div>
                                      <small>
                                        Requested{" "}
                                        {moment(item?.createdAt).fromNow()}
                                      </small>
                                    </div>
                                  </div>
                                </td>
                                <td>{/* <Remark user={user} /> */}</td>
                                <td className="td-actions text-right">
                                 <Button
                                        className="btn btn-danger btn-fill"
                                        type="button"
                                        disabled={deleteModal == item?._id}
                                        variant="danger"
                                        onClick={async () =>
                                          setDeleteModal(item?._id)
                                        }
                                      >
                                        Delete Cart
                                      </Button>
                                    
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan="6"
                              style={{
                                textAlign: "center",
                                color: CustomStyle.colors.danger,
                              }}
                            >
                              <small>No order found</small>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card.Body>
              <Card.Footer>
                {cartResults && cartResults?.totalPages ? (
                  <ReactPaginate
                    previousLabel={
                      <i className="nc-icon ml-1 nc-stre-left"></i>
                    }
                    nextLabel={<i className="nc-icon ml-1 nc-stre-right"></i>}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={cartResults?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={deleteModal ? true : false}
        onRequestClose={() => {
          setConfirmData({});
          setLoading(false);
        }}
        style={customStyles}
        contentLabel="Confirm Delete"
      >
        {deleteModal ? (
          <div className="px-3">
            <p>Are you sure you want to delete this cart?</p>
            <Container fluid>
              <Row>
                <Col className="p-0" md="4" />
                <Col className="p-0" md="4">
                  <Button
                    onClick={() => {
                      setDeleteModal(false)
                    }}
                    className="btn-fill form-control pull-right"
                    variant="default"
                    type="button"
                  >
                    Close
                  </Button>
                </Col>
                <Col className="p-0 pl-2" md="4">
                  <Button
                    className="btn-fill form-control"
                    variant="info"
                    type="button"
                    disabled={loading}
                    onClick={() => deleteCart(deleteModal)}
                  >
                    {loading ? (
                      <Loader
                        type="Oval"
                        color={CustomStyle.colors.white}
                        height={20}
                        width={20}
                      />
                    ) : (
                      "Confirm"
                    )}
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        ) : null}
      </Modal>
    </>
  );
}

function OwnerDropdown({ user }) {
  const [isToggled, setIsToggled] = useState(false);
  return (
    <>
      <b
        className="text-info ml-1"
        role="button"
        onClick={() => {
          isToggled ? setIsToggled(false) : setIsToggled(true);
        }}
        style={{
          alignItems: "center",
          textTransform: "capitalize",
        }}
      >
        {user?.name}{" "}
        {isToggled ? (
          <i className="nc-icon ml-1 nc-stre-up"></i>
        ) : (
          <i className="nc-icon ml-1 nc-stre-down"></i>
        )}
      </b>
      <div className={`collapse ${isToggled ? "show" : ""}`}>
        <div className="card card-body">
          <div>
            <span>Email: </span>
            <b>{user?.email || "unknown"}</b>
          </div>
          <div>
            <span>Phone: </span>
            <b>{user?.phone || "unknown"}</b>
          </div>
        </div>
      </div>
    </>
  );
}

function ProductDropdown({ products }) {
  const [isToggled, setIsToggled] = useState(false);
  return (
    <>
      <b
        className="text-info ml-1"
        role="button"
        onClick={() => {
          isToggled ? setIsToggled(false) : setIsToggled(true);
        }}
        style={{
          alignItems: "center",
        }}
      >
        {products?.length} Product
        {products?.length > 1 ? "s " : " "}
        {isToggled ? (
          <i className="nc-icon ml-1 nc-stre-up"></i>
        ) : (
          <i className="nc-icon ml-1 nc-stre-down"></i>
        )}
      </b>
      <div className={`collapse ${isToggled ? "show" : ""}`}>
        <div className="card card-body p-0">
          <ul className="list-group">
            {products?.map((itm, i) => {
              return (
                <li
                  key={i.toString()}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div className="media">
                    <img
                      src={itm?.productImg}
                      style={{ width: 50, height: 50 }}
                    />
                    <div className="media-content pl-2">
                      <div>
                        <span>Name: </span>
                        <b>{itm?.productName || "unknown"}</b>
                      </div>
                      <div>
                        <span>Quantity: </span>
                        <b>x{itm?.quantity || 0}</b>
                      </div>
                      <div>
                        <span>Price: </span>
                        <b>{Helper.formatToNaira(itm?.price || 0)}</b>
                      </div>
                      <div>
                        <span>Barcode: </span>
                        <b>{itm?.barcode}</b>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

export default Cart;
