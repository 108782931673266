import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import Axios from "../utils/axios";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
	apiKey: "AIzaSyCYIyXozNrMzW5wg8TCBvoDkqnXk4lXfQA",
	authDomain: "justrite.firebaseapp.com",
	projectId: "justrite",
	storageBucket: "justrite.appspot.com",
	messagingSenderId: "50632206721",
	appId: "1:50632206721:web:0de74bca6a86afe644e080",
	measurementId: "G-BF3B46GF8H",
};

export const app = initializeApp(firebaseConfig);


const messaging = getMessaging(app);

const getAuthToken = async () => {
	let initialState = localStorage.getItem("auth");
	return JSON.parse(initialState);
};

export const requestForToken = async () => {
	try {
		await requestPermission();
		const currentToken = await getToken(messaging, {
			vapidKey:
				"BK1P_sJ92Ex18SNWRsYAzTHCwQkG3lDsfhlsB7-InFt-EmDBySD3H4rqb29Hcj_hTxS5rFPhYLyB3K7ytl4pEmI",
		});

		if (currentToken) {
			// Send the token to your server and update the UI if necessary
			// ...
			// console.log("Token >>> ", currentToken);
			await updateUserToken(currentToken);
		} else {
			// Show permission request UI
			console.log(
				"No registration token available. Request permission to generate one."
			);
			// ...
		}
	} catch (err) {
		console.log("An error occurred while retrieving token. ", err);
	}
};

const updateUserToken = async (deviceToken) => {
	try {
		const { token } = await getAuthToken();

		console.log("APP TOKEN >>> ", token);

		await Axios.patch(
			`${process.env.REACT_APP_API_V2}/admin/admins/device-token`,
			{ deviceToken },
			{
				headers: {
					"x-access-token": token,
				},
			}
		);
	} catch (error) {
		console.log(`Oops. something went wrong while saving item`, error);
	}
};

async function requestPermission() {
	console.log("Requesting permission...");
	Notification.requestPermission().then((permission) => {
		if (permission === "granted") {
			console.log("Notification permission granted.");
		}
	});
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("Foreground payload", payload)
      resolve(payload);
    });
  });