import React, { useContext } from "react";
import ReactDOM from "react-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import {
  BrowserRouter,
  HashRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { AuthContextProvider, AuthContext } from "./context/auth";
import { DashboardContextProvider } from "./context/dashboard";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0";
import "./assets/css/demo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import AdminLayout from "./layouts/Admin.js";
import Login from "./views/Login.js";
import Notification from "views/Notifications";

ReactDOM.render(
  <HashRouter>
    <ToastContainer />
    <Notification />
    <DashboardContextProvider>
      <AuthContextProvider>
        <Switch>
          <AdminPublicRoute exact path="/admin/login" component={Login} />
          <AdminPrivateRoute
            path="/admin"
            render={(props) => <AdminLayout {...props} />}
          />
          <Redirect from="/" to="/admin/dashboard" />
        </Switch>
      </AuthContextProvider>
    </DashboardContextProvider>
  </HashRouter>,
  document.getElementById("root")
);

function AdminPrivateRoute({ path, exact, component, render }) {
  const { authState } = useContext(AuthContext);

  const isLoggedIn = authState?.token;

  return (
    <>
      {isLoggedIn ? (
        <Route
          path={path}
          exact={exact}
          component={component}
          render={render}
        />
      ) : (
        <Redirect to="/admin/login" />
      )}
    </>
  );
}

function AdminPublicRoute({ path, exact, component, render }) {
  const { authState } = useContext(AuthContext);

  const isLoggedIn = authState?.token;

  return (
    <>
      {!isLoggedIn ? (
        <Route
          path={path}
          exact={exact}
          component={component}
          render={render}
        />
      ) : (
        <Redirect to="/admin/dashboard" />
      )}
    </>
  );
}
