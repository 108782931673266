import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import Loader from "react-loader-spinner";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import { showToast } from "utils/general";
import { AuthContext } from "../context/auth";
import { DashboardContext } from "../context/dashboard";
import { Helper } from "../helper";
import CustomStyle from "../styles";
import Axios from "../utils/axios";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    // maxWidth: window.innerWidth - 20,
    width: "60%",
  },
};

Modal.setAppElement("#root");
function Remarks() {
  const [loading, setLoading] = useState(true);
  const [users, setRemarks] = useState({});
  const [results, setResults] = useState([]);
  const [limit, setLimit] = useState(50);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [searching, setSearching] = useState(false);
  const [remark, setRemark] = useState("");

  const context = useContext(AuthContext);

  let remarkInput = useRef(null);

  useEffect(() => {
    if (
      Helper.isPopulator(context.authState?.admin) ||
      Helper.isFinance(context.authState?.admin) ||
      Helper.isAdministrator(context.authState?.admin)
    ) {
      return false;
    }

    fetchData();
  }, []);

  const fetchData = async (offSet = 1) => {
    const { token } = context.authState;
    setLoading(true);
    try {
      let usersResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/users?limit=${limit}&page=${offSet}`,
        token
      );

      if (usersResponse && usersResponse?.data) {
        setRemarks(usersResponse?.data || []);
        setResults(usersResponse?.data?.results || []);
      }
      setLoading(false);
    } catch (error) {
      showToast(`Oops. failed to fetch users`);
      setLoading(false);
    }
  };

  const sendRemark = (user) => {
    const remark = remarkInput ? remarkInput.current.value : null;
    if (!remark || remark == "") {
      showToast(`Please write a remark to send`);
      return false;
    }

    setModalOpen(user);
  };

  const handleRemarkSubmit = async (event) => {
    setModalLoading(true);
    try {
      const { token } = context.authState;
      let response = await Axios.post(
        `${process.env.REACT_APP_API_V2}/admin/users/remark`,
        {
          users: selectedUsers,
          remark,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { status } = response?.data;

      if (status && status == "success") {
        showToast("Remark sent successfully", "success");
        closeModal();
      } else {
        showToast(`Oops. Failed to delete item`);
      }
      setModalLoading(false);
    } catch (error) {
      showToast(`Oops. something went wrong while deleting item`);
      setModalLoading(false);
    }
  };

  const handlePageClick = (data) => {
    let selected = data.selected + 1;

    fetchData(selected);
  };

  const closeModal = () => {
    setModalLoading(false);
    setModalOpen(false);
    setSelectedUsers([]);
  };

  const handleCheckbox = (item) => {
    // console.log("Checkbox >> ", selectedUsers);

    if (selectedUsers.includes(item._id)) {
      let newUsers = selectedUsers.filter((userID) => userID != item._id);
      setSelectedUsers(newUsers);
    } else {
      setSelectedUsers([...selectedUsers, item._id]);
    }
  };

  const searchUsers = async () => {
    try {
      const { token } = context.authState;

      setLoading(true);
      let response = await Axios.get(
        `${process.env.REACT_APP_API_V2}/admin/users/search/${keyword}`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      const { data } = response?.data;
      setRemarks(data || []);
      setResults(data || []);
      setSearching(true);
      setKeyword("");

      setLoading(false);
    } catch (error) {
      showToast(`Oops. something went wrong while searching user`);
      setLoading(false);
    }
  };

  if (
    Helper.isPopulator(context.authState?.admin) ||
    Helper.isFinance(context.authState?.admin) ||
    Helper.isAdministrator(context.authState?.admin)
  ) {
    return (
      <div style={{ textAlign: "center" }}>
        <small>You are not permitted to view this page</small>
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-tasks">
              <Card.Header>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <Card.Title as="h4">Send User Remarks</Card.Title>
                        <p className="card-category">Backend development</p>
                      </Col>
                      {!!searching && (
                        <Button className="btn-sm" onClick={fetchData}>
                          See all
                        </Button>
                      )}
                    </Row>
                  </Col>
                  <Col md="1">
                    <Button
                      className="btn-sm"
                      variant="success"
                      style={{
                        background: CustomStyle.colors.success,
                        borderColor: CustomStyle.colors.success,
                        color: "white",
                      }}
                      onClick={() => setModalOpen(!modalOpen)}
                    >
                      Write Remark
                    </Button>
                  </Col>
                  <Col>
                    <Form
                      className="container"
                      noValidate
                      // validated={searchValidated}
                      onSubmit={(e) => {
                        e.preventDefault();
                        searchUsers();
                      }}
                    >
                      <Row>
                        <Col md="10">
                          <Form.Group>
                            <Form.Control
                              placeholder="Enter Search Name"
                              type="text"
                              required
                              onChange={(e) => setKeyword(e.target.value)}
                              value={keyword}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Please enter a keyword.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col md="2">
                          <Form.Group>
                            {/* <label>Search</label> */}
                            <Button
                              className="btn-fill form-control"
                              type="submit"
                              variant="primary"
                              style={{
                                background: CustomStyle.colors.primary,
                                borderColor: CustomStyle.colors.primary,
                              }}
                            >
                              <i className="nc-icon nc-zoom-split"></i>
                            </Button>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {loading ? (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    <Loader
                      type="Oval"
                      color={CustomStyle.colors.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <div className="table-full-width">
                    <Table>
                      <tbody>
                        {results && results?.length > 0 ? (
                          results?.map((item, index) => {
                            return (
                              <tr key={index.toString()}>
                                <td>
                                  <input
                                    checked={
                                      selectedUsers.includes(item?._id)
                                        ? true
                                        : false
                                    }
                                    type="checkbox"
                                    onChange={() => handleCheckbox(item)}
                                  />
                                </td>
                                <td>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <i
                                      className="nc-icon nc-circle-09 mr-2"
                                      style={{ fontSize: 30 }}
                                    ></i>
                                    <b style={{ textTransform: "capitalize" }}>
                                      {item?.name}
                                    </b>
                                  </div>
                                </td>
                                {/* <td className="td-actions text-right">
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-938342127">
                                        Send user remark
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className="btn btn-primary btn-fill"
                                      type="button"
                                      variant="primary"
                                      style={{
                                        background: CustomStyle.colors.primary,
                                        borderColor: CustomStyle.colors.primary,
                                      }}
                                      onClick={async () => sendRemark(item)}
                                    >
                                      Send Remark
                                    </Button>
                                  </OverlayTrigger>
                                </td> */}
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td
                              colSpan="6"
                              style={{
                                textAlign: "center",
                                color: CustomStyle.colors.danger,
                              }}
                            >
                              <small>No user found</small>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card.Body>
              <Card.Footer>
                {users && users?.total ? (
                  <ReactPaginate
                    previousLabel={<i className="nc-icon nc-stre-left"></i>}
                    nextLabel={<i className="nc-icon nc-stre-right"></i>}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={users?.totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                  />
                ) : null}
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => closeModal()}
        style={customStyles}
        contentLabel="Delete Modal"
      >
        {modalOpen ? (
          <>
            <h5
              style={{
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
              }}
            >
              <b>Send Remark</b>
            </h5>
            <br />
            <Form.Group>
              <Form.Control
                cols="80"
                placeholder="Write your remarks here"
                rows="4"
                as="textarea"
                onChange={(e) => setRemark(e.target.value)}
                // ref={remarkInput}
              ></Form.Control>
            </Form.Group>

            <p className="pb-3">
              Remark will be sent out to {selectedUsers.length} people{" "}
            </p>
            <Row>
              <Col className="" md="4" />
              <Col className="" md="4">
                <Form.Group>
                  <label htmlFor=""></label>
                  <Button
                    onClick={() => closeModal()}
                    className="btn-fill form-control pull-right"
                    variant="default"
                    type="button"
                  >
                    Close
                  </Button>
                </Form.Group>
              </Col>
              <Col className="" md="4">
                <Form.Group>
                  <label htmlFor=""></label>
                  <Button
                    className="btn-fill form-control pull-right"
                    variant="primary"
                    type="button"
                    disabled={
                      modalLoading || selectedUsers.length == 0 || remark == ""
                    }
                    onClick={handleRemarkSubmit}
                  >
                    {modalLoading ? (
                      <Loader
                        type="Oval"
                        color={CustomStyle.colors.white}
                        height={20}
                        width={20}
                      />
                    ) : (
                      "Send"
                    )}
                  </Button>
                </Form.Group>
              </Col>
            </Row>
          </>
        ) : null}
      </Modal>
    </>
  );
}

export default Remarks;
