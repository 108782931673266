import React, { useContext, useRef, useState } from "react";
import { Button, Col, Card, Form, Row, Table } from "react-bootstrap";
import Loader from "react-loader-spinner";
import Modal from "react-modal";
import { AuthContext } from "../../context/auth";
import { Helper } from "../../helper";
import CustomStyle from "../../styles";
import Axios from "../../utils/axios";
import { showToast } from "utils/general";
import Slider from "react-slick";
import { useProducts } from "./useProducts";

const settings = {
  items: 1,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

Modal.setAppElement("#root");

export function EditProductModal({
  currentProduct,
  modalFormData,
  closeModal,
  updateICGProducts,
  modalLoading,
  handleModalChange,
  frontimageFileInput,
  backimageFileInput,
  modalFetchLoading,
  modalValidated,
  productIsUpdate,
}) {
  console.log("LOG: ", currentProduct);

  if (!currentProduct) return;
  return (
    <Row>
      <Col md="12">
        <>
          {currentProduct?.FrontImage || currentProduct?.BackImage ? (
            <>
              <Slider {...settings}>
                {currentProduct?.FrontImage ? (
                  <div className="text-center">
                    <img
                      src={currentProduct?.FrontImage}
                      className="img-fluid product--img"
                      style={{
                        margin: "0 auto",
                        width: "300px",
                      }}
                    />
                  </div>
                ) : null}
                {currentProduct?.BackImage ? (
                  <div className="text-center">
                    <img
                      src={currentProduct?.BackImage}
                      className="img-fluid product--img"
                      style={{
                        margin: "0 auto",
                        width: "300px",
                      }}
                    />
                  </div>
                ) : null}
              </Slider>
              <br />
              <br />
            </>
          ) : null}
          <h5
            style={{
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
            }}
          >
            <i className="nc-icon nc-app fa-2x"></i>{" "}
            <b className="ml-2">{`${currentProduct?.ItemDescription} (${currentProduct?.Barcode})`}</b>
          </h5>
          <br />

          {modalFetchLoading ? (
            <div className="text-center">
              <Loader
                type="Oval"
                color={CustomStyle.colors.primary}
                height={50}
                width={50}
              />
            </div>
          ) : (
            <Form
              noValidate
              validated={modalValidated}
              onSubmit={(event) => {
                event.preventDefault();
                updateICGProducts(event);
              }}
            >
              <Row>
                <Col className="" md="4">
                  <Form.Group>
                    <label htmlFor="Name">Product Name*</label>
                    <Form.Control
                      id="Name"
                      placeholder="Name"
                      type="text"
                      required
                      defaultValue={modalFormData?.name}
                      onChange={(e) =>
                        handleModalChange("name", e.target.value)
                      }
                    ></Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter a product name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="" md="4">
                  <Form.Group>
                    <label htmlFor="Gtin">Barcode* (gtin)</label>
                    <Form.Control
                      id="Gtin"
                      placeholder="Gtin"
                      type="text"
                      required
                      disabled={true}
                      defaultValue={modalFormData?.gtin}
                      onChange={(e) =>
                        handleModalChange("gtin", e.target.value)
                      }
                    ></Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter a barcode.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="" md="4">
                  <Form.Group>
                    <label htmlFor="BrandName">Brand Name (optional)</label>
                    <Form.Control
                      id="BrandName"
                      placeholder="Brand Name"
                      type="text"
                      defaultValue={modalFormData?.brandname}
                      onChange={(e) =>
                        handleModalChange("brandname", e.target.value)
                      }
                    ></Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter a brand name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="" md="6">
                  <Form.Group>
                    <label htmlFor="LabelDescription">Description*</label>
                    <Form.Control
                      id="LabelDescription"
                      placeholder="Description"
                      as="textarea"
                      style={{ minHeight: 90 }}
                      required
                      defaultValue={modalFormData?.labeldescription}
                      onChange={(e) =>
                        handleModalChange("labeldescription", e.target.value)
                      }
                    ></Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter label description.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label htmlFor="FrontImage">Front Image*</label>
                    {currentProduct?.FrontImage.length > 0 ? (
                      <>
                        <Form.File
                          id="FrontImage"
                          label={modalFormData?.frontimage}
                          custom
                          required={productIsUpdate ? false : "required"}
                          data-browse={"Update"}
                          ref={frontimageFileInput}
                        />
                      </>
                    ) : (
                      <Form.File
                        id="FrontImage"
                        label="Select Front Image"
                        custom
                        required={productIsUpdate ? false : "required"}
                        ref={frontimageFileInput}
                      />
                    )}
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please upload front image.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label htmlFor="BackImage">Back Image (optional)</label>
                    {currentProduct?.BackImage?.length > 0 ? (
                      <Form.File
                        id="BackImage"
                        label={modalFormData?.backimage}
                        custom
                        data-browse={"Update"}
                        ref={backimageFileInput}
                      />
                    ) : (
                      <Form.File
                        id="BackImage"
                        label="Select Back Image"
                        custom
                        ref={backimageFileInput}
                      />
                    )}
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please upload back image.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="" md="6">
                  <Form.Group>
                    <label htmlFor="MarketingInformation">
                      Marketing Information (optional)
                    </label>
                    <Form.Control
                      id="MarketingInformation"
                      placeholder="Marketing Information"
                      as="textarea"
                      style={{ minHeight: 90 }}
                      defaultValue={modalFormData?.marketinginformation}
                      onChange={(e) =>
                        handleModalChange(
                          "marketinginformation",
                          e.target.value
                        )
                      }
                    ></Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter marketing information.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label htmlFor="Package">Package (optional)</label>
                    <Form.Control
                      id="Package"
                      placeholder="Package"
                      type="text"
                      defaultValue={modalFormData?.package}
                      onChange={(e) =>
                        handleModalChange("package", e.target.value)
                      }
                    ></Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter package.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label htmlFor="Expiration">Expiration (optional)</label>
                    <Form.Control
                      id="Expiration"
                      placeholder="Expiration"
                      type="text"
                      defaultValue={modalFormData?.expiration}
                      onChange={(e) =>
                        handleModalChange("expiration", e.target.value)
                      }
                    ></Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter expiration.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="" md="3">
                  <Form.Group>
                    <label htmlFor="Unit">Unit Of Measurement (optional)</label>
                    <Form.Control
                      id="Unit"
                      placeholder="Unit Of Measurement"
                      type="text"
                      defaultValue={modalFormData?.unitofmeasurement}
                      onChange={(e) =>
                        handleModalChange("unitofmeasurement", e.target.value)
                      }
                    ></Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter unit of measurement.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label htmlFor="Gross">Gross Unit Value (optional)</label>
                    <Form.Control
                      id="Gross"
                      placeholder="Gross Unit Value"
                      type="number"
                      defaultValue={modalFormData?.grossweight}
                      onChange={(e) =>
                        handleModalChange("grossweight", e.target.value)
                      }
                    ></Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter gross unit value.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label htmlFor="Net">Net Unit Value (optional)</label>
                    <Form.Control
                      id="Net"
                      placeholder="Net Unit Value"
                      type="number"
                      defaultValue={modalFormData?.netweight}
                      onChange={(e) =>
                        handleModalChange("netweight", e.target.value)
                      }
                    ></Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter net unit value.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label htmlFor="Weight">Net Weight*</label>
                    <Form.Control
                      id="Weight"
                      placeholder="Net Weight"
                      required
                      type="number"
                      defaultValue={modalFormData?.weight ?? 1}
                      onChange={(e) =>
                        handleModalChange("weight", e.target.value)
                      }
                    ></Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter net weight.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="" md="4">
                  <Form.Group>
                    <label htmlFor="BrandOwner">Brand Owner (optional)</label>
                    <Form.Control
                      id="BrandOwner"
                      placeholder="Brand Owner"
                      type="text"
                      defaultValue={modalFormData?.brandOwner}
                      onChange={(e) =>
                        handleModalChange("brandOwner", e.target.value)
                      }
                    ></Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter brand owner.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="" md="5">
                  <Form.Group>
                    <label htmlFor="Address">Address (optional)</label>
                    <Form.Control
                      id="Address"
                      placeholder="Address"
                      type="text"
                      defaultValue={modalFormData?.address}
                      onChange={(e) =>
                        handleModalChange("address", e.target.value)
                      }
                    ></Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter address.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <label htmlFor="MoName">Mo Name (optional)</label>
                    <Form.Control
                      id="MoName"
                      placeholder="Mo Name"
                      type="text"
                      defaultValue={modalFormData?.moName}
                      onChange={(e) =>
                        handleModalChange("moName", e.target.value)
                      }
                    ></Form.Control>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      Please enter mo name.
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col className="" md="6" />
                <Col className="" md="3">
                  <Form.Group>
                    <Button
                      onClick={() => closeModal()}
                      className="btn-fill form-control pull-right"
                      variant="default"
                      type="button"
                    >
                      Close
                    </Button>
                  </Form.Group>
                </Col>
                <Col className="" md="3">
                  <Form.Group>
                    <Button
                      className="btn-fill form-control pull-right"
                      variant="primary"
                      type="submit"
                      disabled={modalLoading}
                      style={{
                        background: CustomStyle.colors.primary,
                        borderColor: CustomStyle.colors.primary,
                      }}
                    >
                      {modalLoading ? (
                        <Loader
                          type="Oval"
                          color={CustomStyle.colors.white}
                          height={20}
                          width={20}
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Form.Group>
                </Col>
              </Row>
              <div className="clearfix"></div>
            </Form>
          )}
        </>
      </Col>
    </Row>
  );
}

export function AddComboModal({
  currentProduct,
  modalFormData,
  closeModal,
  modalLoading,
  handleModalChange,
  frontimageFileInput,
  backimageFileInput,
  modalFetchLoading,
  modalValidated,
  setIsOpenProductModal,
  warehousesStock,
  selectedProducts,
  updateStockValue,
  createComboProducts,
  productIsUpdate,
}) {
  if (!currentProduct) return <p>No product selected, please go back</p>;
  return (
    <Row>
      <Col md="12">
        <Col md="4" style={{ marginTop: 15, marginBottom: 35 }}>
          <Button
            className="btn-fill form-control pull-right"
            variant="primary"
            type="button"
            disabled={modalLoading}
            style={{
              background: CustomStyle.colors.primary,
              borderColor: CustomStyle.colors.primary,
            }}
            onClick={() => setIsOpenProductModal(true)}
          >
            {modalLoading ? (
              <Loader
                type="Oval"
                color={CustomStyle.colors.white}
                height={20}
                width={20}
              />
            ) : (
              "Add Products"
            )}
          </Button>
        </Col>
        <Row>
          <Col md="9">
            <>
              {currentProduct?.FrontImage || currentProduct?.BackImage ? (
                <>
                  <Slider {...settings}>
                    {currentProduct?.FrontImage ? (
                      <div className="text-center">
                        <img
                          src={currentProduct?.FrontImage}
                          className="img-fluid product--img"
                          style={{
                            margin: "0 auto",
                            width: "300px",
                          }}
                        />
                      </div>
                    ) : null}
                    {currentProduct?.BackImage ? (
                      <div className="text-center">
                        <img
                          src={currentProduct?.BackImage}
                          className="img-fluid product--img"
                          style={{
                            margin: "0 auto",
                            width: "300px",
                          }}
                        />
                      </div>
                    ) : null}
                  </Slider>
                  <br />
                  <br />
                </>
              ) : null}
              <h5
                style={{
                  textTransform: "capitalize",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i className="nc-icon nc-app fa-2x"></i>{" "}
                <b className="ml-2">{`${currentProduct?.ItemDescription} (${currentProduct.Barcode})`}</b>
              </h5>
              <br />

              {modalFetchLoading ? (
                <div className="text-center">
                  <Loader
                    type="Oval"
                    color={CustomStyle.colors.primary}
                    height={50}
                    width={50}
                  />
                </div>
              ) : (
                <Form
                  noValidate
                  validated={modalValidated}
                  onSubmit={(event) => {
                    event.preventDefault();
                    createComboProducts(event);
                  }}
                >
                  <Row>
                    <Col className="" md="4">
                      <Form.Group>
                        <label htmlFor="">Product Name*</label>
                        <Form.Control
                          placeholder="Name"
                          type="text"
                          required
                          defaultValue={modalFormData?.name}
                          onChange={(e) =>
                            handleModalChange("name", e.target.value)
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter a product name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="" md="4">
                      <Form.Group>
                        <label htmlFor="">Selling Price* (Naira)</label>
                        <Form.Control
                          placeholder="Selling Price"
                          type="number"
                          required
                          value={modalFormData?.sellingPrice ?? 0}
                          onChange={(e) =>
                            handleModalChange("sellingPrice", e.target.value)
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter a product name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="" md="4">
                      <Form.Group>
                        <label htmlFor="">Barcode* (gtin)</label>
                        <Form.Control
                          placeholder="Gtin"
                          type="text"
                          required
                          disabled={true}
                          defaultValue={modalFormData?.gtin}
                          onChange={(e) =>
                            handleModalChange("gtin", e.target.value)
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter a barcode.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="" md="6">
                      <Form.Group>
                        <label htmlFor="">Description*</label>
                        <Form.Control
                          placeholder="Description"
                          as="textarea"
                          style={{ minHeight: 90 }}
                          type="text"
                          required
                          defaultValue={modalFormData?.labeldescription}
                          onChange={(e) =>
                            handleModalChange(
                              "labeldescription",
                              e.target.value
                            )
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter label description.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>

                    <Col className="" md="3">
                      <Form.Group>
                        <label htmlFor="FrontImage">Front Image*</label>
                        {currentProduct?.FrontImage.length > 0 ||
                        !!frontimageFileInput?.current?.value ? (
                          <>
                            <Form.File
                              id="FrontImage"
                              label={
                                modalFormData?.frontimage ??
                                frontimageFileInput?.current?.value
                              }
                              custom
                              required={productIsUpdate ? false : "required"}
                              data-browse={"Update"}
                              ref={frontimageFileInput}
                            />
                          </>
                        ) : (
                          <Form.File
                            id="FrontImage"
                            label="Select Front Image"
                            custom
                            required={productIsUpdate ? false : "required"}
                            ref={frontimageFileInput}
                          />
                        )}
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please upload front image.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="" md="3">
                      <Form.Group>
                        <label htmlFor="BackImage">Back Image (optional)</label>
                        {currentProduct?.BackImage?.length > 0 ||
                        !!backimageFileInput?.current?.value ? (
                          <Form.File
                            id="BackImage"
                            label={
                              modalFormData?.backimage ??
                              backimageFileInput?.current?.value
                            }
                            custom
                            data-browse={"Update"}
                            ref={backimageFileInput}
                          />
                        ) : (
                          <Form.File
                            id="BackImage"
                            label="Select Back Image"
                            custom
                            ref={backimageFileInput}
                          />
                        )}
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please upload back image.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="" md="3">
                      <Form.Group>
                        <label htmlFor="">Unit Of Measurement (optional)</label>
                        <Form.Control
                          placeholder="Unit Of Measurement"
                          type="text"
                          defaultValue={modalFormData?.unitofmeasurement}
                          onChange={(e) =>
                            handleModalChange(
                              "unitofmeasurement",
                              e.target.value
                            )
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter unit of measurement.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="" md="3">
                      <Form.Group>
                        <label htmlFor="">Gross Unit Value (optional)</label>
                        <Form.Control
                          placeholder="Gross Weight"
                          type="number"
                          defaultValue={modalFormData?.grossweight}
                          onChange={(e) =>
                            handleModalChange("grossweight", e.target.value)
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter gross unit value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="" md="3">
                      <Form.Group>
                        <label htmlFor="">Net Unit Value (optional)</label>
                        <Form.Control
                          placeholder="Net Weight"
                          type="number"
                          defaultValue={modalFormData?.netweight}
                          onChange={(e) =>
                            handleModalChange("netweight", e.target.value)
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter net unit value.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="" md="3">
                      <Form.Group>
                        <label htmlFor="">Net Weight* (KG)</label>
                        <Form.Control
                          placeholder="Net Weight"
                          type="number"
                          required
                          defaultValue={modalFormData?.weight ?? 1}
                          onChange={(e) =>
                            handleModalChange("weight", e.target.value)
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter net weight.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="" md="6">
                      <Form.Group>
                        <label htmlFor="">
                          Marketing Information (optional)
                        </label>
                        <Form.Control
                          placeholder="Marketing Information"
                          as="textarea"
                          style={{ minHeight: 90 }}
                          defaultValue={modalFormData?.marketinginformation}
                          onChange={(e) =>
                            handleModalChange(
                              "marketinginformation",
                              e.target.value
                            )
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter marketing information.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="" md="3">
                      <Form.Group>
                        <label htmlFor="">Package (optional)</label>
                        <Form.Control
                          placeholder="Package"
                          type="text"
                          defaultValue={modalFormData?.package}
                          onChange={(e) =>
                            handleModalChange("package", e.target.value)
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter package.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="" md="3">
                      <Form.Group>
                        <label htmlFor="">Expiration (optional)</label>
                        <Form.Control
                          placeholder="Expiration"
                          type="text"
                          defaultValue={modalFormData?.expiration}
                          onChange={(e) =>
                            handleModalChange("expiration", e.target.value)
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter expiration.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="" md="3">
                      <Form.Group>
                        <label htmlFor="">Brand Name (optional)</label>
                        <Form.Control
                          placeholder="Brand Name"
                          type="text"
                          defaultValue={modalFormData?.brandname}
                          onChange={(e) =>
                            handleModalChange("brandname", e.target.value)
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter a brand name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="" md="3">
                      <Form.Group>
                        <label htmlFor="">Brand Owner (optional)</label>
                        <Form.Control
                          placeholder="Brand Owner"
                          type="text"
                          defaultValue={modalFormData?.brandOwner}
                          onChange={(e) =>
                            handleModalChange("brandOwner", e.target.value)
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter brand owner.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="" md="3">
                      <Form.Group>
                        <label htmlFor="">Mo Name (optional)</label>
                        <Form.Control
                          placeholder="Mo Name"
                          type="text"
                          defaultValue={modalFormData?.moName}
                          onChange={(e) =>
                            handleModalChange("moName", e.target.value)
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter mo name.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className="" md="3">
                      <Form.Group>
                        <label htmlFor="">Address (optional)</label>
                        <Form.Control
                          placeholder="Address"
                          type="text"
                          defaultValue={modalFormData?.address}
                          onChange={(e) =>
                            handleModalChange("address", e.target.value)
                          }
                        ></Form.Control>
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                          Please enter address.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      className=""
                      style={{
                        marginTop: 15,
                        marginBottom: 45,
                        borderTop: 1,
                      }}
                    >
                      <label>Selected Products</label>
                      <Table className="table-hover table-striped">
                        <tbody>
                          {selectedProducts.length > 0 ? (
                            selectedProducts.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{item.ItemDescription}</td>
                                  <td>
                                    <Button
                                      className="btn-fill form-control btn-sm"
                                      type="button"
                                      variant="primary"
                                      style={{
                                        background: CustomStyle.colors.primary,
                                        borderColor: CustomStyle.colors.primary,
                                      }}
                                      onClick={() => removeProducts(item)}
                                    >
                                      -
                                    </Button>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan="6"
                                style={{
                                  textAlign: "center",
                                  color: CustomStyle.colors.danger,
                                }}
                              >
                                <small>No product added</small>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="" md="3" />
                    <Col className="" md="3">
                      <Form.Group>
                        <label htmlFor=""></label>
                        <Button
                          onClick={closeModal}
                          className="btn-fill form-control pull-right"
                          variant="default"
                          type="button"
                        >
                          Close
                        </Button>
                      </Form.Group>
                    </Col>
                    <Col className="" md="3">
                      <Form.Group>
                        <label htmlFor=""></label>
                        <Button
                          className="btn-fill form-control pull-right"
                          variant="primary"
                          type="submit"
                          disabled={modalLoading}
                          style={{
                            background: CustomStyle.colors.primary,
                            borderColor: CustomStyle.colors.primary,
                          }}
                        >
                          {modalLoading ? (
                            <Loader
                              type="Oval"
                              color={CustomStyle.colors.white}
                              height={20}
                              width={20}
                            />
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="clearfix"></div>
                </Form>
              )}
            </>
          </Col>
          <Col md="3">
            <Card className="card-tasks">
              <Card.Header>
                <Card.Title as={"h5"}>Stock by Warehouses</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="table-full-width">
                  <Table>
                    <tbody>
                      {warehousesStock?.length > 0 ? (
                        warehousesStock?.map((item, index) => (
                          <tr key={index}>
                            <td>
                              <Row>
                                <Col md="8">
                                  <b>{item?.name}</b>
                                </Col>
                                <Col md="4">
                                  <Form.Control
                                    placeholder={`${item?.WarehouseId} stock`}
                                    type="number"
                                    required
                                    min={0}
                                    // defaultValue={
                                    // 	item?.Qty
                                    // }
                                    value={item?.Qty}
                                    onChange={(e) =>
                                      updateStockValue(item, e.target.value)
                                    }
                                  ></Form.Control>
                                </Col>
                              </Row>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="6"
                            style={{
                              textAlign: "center",
                              color: CustomStyle.colors.danger,
                            }}
                          >
                            <small>No warehouse found</small>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export function AddComboProductModal({
  totalProductAmount,
  selectedProducts,
  removeProducts,
  setIsOpenProductModal,
  submitLoading,
  setComboList,
  searchValidated,
  handleSearch,
  keywordInput,
  isAllProducts,
  loading,
  products,
  addProducts,
}) {
  console.log("PROD PROP: ", products);
  return (
    <Row>
      <Col md={4} style={{ borderRightWidth: 1 }}>
        <div style={{ marginTop: 15 }}>
          <label>Total worth: {Helper.formatToNaira(totalProductAmount)}</label>
        </div>

        <div style={{ marginTop: 15, marginBottom: 45 }}>
          <label>Selected Products</label>
          <Table className="table-hover table-striped">
            <tbody>
              {selectedProducts.length > 0 ? (
                selectedProducts.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.ItemDescription}</td>
                      <td>
                        <Button
                          className="btn-fill form-control btn-sm"
                          type="button"
                          variant="primary"
                          style={{
                            background: CustomStyle.colors.primary,
                            borderColor: CustomStyle.colors.primary,
                          }}
                          onClick={() => removeProducts(item)}
                        >
                          -
                        </Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td
                    colSpan="6"
                    style={{
                      textAlign: "center",
                      color: CustomStyle.colors.danger,
                    }}
                  >
                    <small>No product added</small>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <Row>
          <Col className="" md="5">
            <Button
              className="btn-fill form-control pull-right"
              variant="default"
              type="button"
              onClick={() => setIsOpenProductModal(false)}
            >
              Close
            </Button>
          </Col>
          <Col className="" md="7">
            <Button
              className="btn-fill pointer form-control pull-right"
              variant="primary"
              type="button"
              disabled={submitLoading}
              style={{
                background: CustomStyle.colors.primary,
                borderColor: CustomStyle.colors.primary,
              }}
              // onClick={setComboList}
              onClick={() => {
                setComboList();
              }}
            >
              {submitLoading ? (
                <Loader
                  type="Oval"
                  color={CustomStyle.colors.white}
                  height={20}
                  width={20}
                />
              ) : (
                "Set"
              )}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col md={8}>
        <Form
          className="container"
          noValidate
          validated={searchValidated}
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch(e);
          }}
        >
          <Row>
            <Col md="10">
              <Form.Group>
                <Form.Control
                  placeholder="Search and Add Products"
                  type="text"
                  required
                  ref={keywordInput}
                  disabled={isAllProducts == "true" ? true : false}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  Please enter a keyword.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="2">
              <Form.Group>
                <Button
                  className="btn-fill form-control"
                  type="submit"
                  disabled={isAllProducts == "true" ? true : false}
                  variant="primary"
                  style={{
                    background: CustomStyle.colors.primary,
                    borderColor: CustomStyle.colors.primary,
                  }}
                >
                  <i className="nc-icon nc-zoom-split"></i>
                </Button>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <div>
          {loading ? (
            <div
              style={{
                flex: 1,
                textAlign: "center",
                padding: "20px 0px",
              }}
            >
              <Loader
                type="Oval"
                color={CustomStyle.colors.primary}
                height={50}
                width={50}
              />
            </div>
          ) : (
            <Table className="table-hover table-striped">
              <thead>
                <tr>
                  <th className="border-0">Name</th>
                  <th className="border-0">Barcode</th>
                  <th className="border-0">Price</th>
                  <th className="border-1"></th>
                </tr>
              </thead>
              <tbody>
                {products.length > 0 ? (
                  products.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.ItemDescription}</td>
                        <td>{item.Barcode}</td>
                        <td>{Helper.formatToNaira(item.SellingPrice)}</td>
                        <td>
                          <Button
                            className="btn-fill form-control btn-sm"
                            type="submit"
                            variant="primary"
                            style={{
                              background: CustomStyle.colors.primary,
                              borderColor: CustomStyle.colors.primary,
                            }}
                            onClick={() => addProducts(item)}
                            disabled={selectedProducts.includes(item)}
                          >
                            +
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td
                      colSpan="6"
                      style={{
                        textAlign: "center",
                        color: CustomStyle.colors.danger,
                      }}
                    >
                      <small>No product found</small>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </div>
      </Col>
    </Row>
  );
}
