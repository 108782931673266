import { Helper } from "../helper";
import Axios from "../utils/axios";

export const getWarehouses = async (token) => {
	try {
		let { data } = await Helper.getData(
			`${process.env.REACT_APP_API_V2}/admin/warehouses`,
			token
		);

		console.log("getWarehouses response >> ", data);

		return { status: true, data };
	} catch (error) {
		return { status: false };
	}
};

export const updateWarehouse = async (token, formData) => {
	try {
		let { data } = await Helper.putData(
			`${process.env.REACT_APP_API_V2}/admin/warehouses`,
			token,
			formData
		);

		console.log("updateWarehouse response >> ", data);

		return { status: true, data };
	} catch (error) {
		return { status: false };
	}
};

export const deleteWarehouse = async (id, token) => {
	try {
		await Helper.deleteData(
			`${process.env.REACT_APP_API_V2}/admin/warehouses/${id}`,
			token
		);

		console.log("deleteWarehouse response >> ");

		return { status: true };
	} catch (error) {
		return { status: false };
	}
};
