import React from "react";
import { Nav, Container } from "react-bootstrap";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { useProducts } from "./useProducts";

import AllProducts from "./AllProducts";
import ComboProducts from "./ComboProducts";
import InactiveProducts from "./InactiveProducts";

function Products() {
	const {
		handleModalChange,
		navState,
		processedWarehouses,
		modalFormData,
		setNavState,
		setModalFormData,
	} = useProducts();

	return (
		<>
			<Container fluid>
				<Nav variant="tabs" defaultActiveKey="our-products">
					<Nav.Item>
						<Nav.Link
							eventKey="our-products"
							onClick={() => setNavState("our-products")}
						>
							Active Products
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey="inactive-products"
							onClick={() => setNavState("inactive-products")}
						>
							Inactive Products
						</Nav.Link>
					</Nav.Item>
					<Nav.Item>
						<Nav.Link
							eventKey="combo-products"
							onClick={() => setNavState("combo-products")}
						>
							Combo Products
						</Nav.Link>
					</Nav.Item>
				</Nav>
				{navState == "our-products" && <AllProducts />}
				{navState == "inactive-products" && <InactiveProducts />}
				{navState == "combo-products" && (
					<ComboProducts />
				)}
			</Container>
		</>
	);
}

export default Products;
