import React, { useEffect, useState, useContext, useRef } from "react";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import moment from "moment";
// react-bootstrap components
import {
  Badge,
  Button,
  Card,
  Navbar,
  Nav,
  Table,
  Container,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Modal,
  Pagination,
  Select,
} from "react-bootstrap";

import Axios from "../utils/axios";

import { Helper } from "../helper";
import CustomStyle from "../styles";
import { AuthContext } from "../context/auth";
import { DashboardContext } from "../context/dashboard";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function Loyalty() {
  const pageRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const context = useContext(AuthContext);
  const [isApproving, setIsApproving] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [activeTab, setActiveTab] = useState("pending");
  const [pagination, setPagination] = useState({
    currentPage: 0,
    pageCount: 0,
    totalCount: 0,
  });

  useEffect(() => {
    // get data from Our database
    // if (
    //   Helper.isPopulator(context.authState?.admin) ||
    //   Helper.isFinance(context.authState?.admin) ||
    //   Helper.isAdministrator(context.authState?.admin)
    // ) {
    //   return false;
    // }

    fetchData();
  }, []);

  const [declineData, setDeclineData] = useState(null);

  const closeDeclineModal = () => setDeclineData(null);

  const fetchData = async (page = 1) => {
    const { token } = context.authState;
    // console.log("TOKEN: ", token);
    setLoading(true);
    try {
      const { data } = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/loyalty/change/data?page=${page}&size=${pageSize}&status=${activeTab}`,
        token
      );

      setData(data?.loyaltyData);
      // setFilteredData(
      //   data?.loyaltyData.filter((dt) => dt.status.toLowerCase() === activeTab)
      // );
      setPagination(data?.pagination);

      setLoading(false);
    } catch (error) {
      toast(`Oops. failed to fetch loyalty change requests`, {
        position: "top-right",
        type: "error",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false);
    }
  };

  const approveHandler = async (data) => {
    const { token } = context.authState;
    setIsApproving(true);
    const response = await Helper.putData(
      `${process.env.REACT_APP_API_V2}/admin/loyalty/change/approve`,
      token,
      data
    );

    toast(response.status, {
      position: "top-right",
      type: "success",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    setIsApproving(false);
    window.location.reload();
  };

  const rejectHandler = async (email, userId) => {
    if (rejectionReason === "") return;

    const { token } = context.authState;

    setIsRejecting(true);
    const response = await Helper.putData(
      `${process.env.REACT_APP_API_V2}/admin/loyalty/change/decline/${userId}`,
      token,
      { email, reason: rejectionReason }
    );

    toast(response.status, {
      position: "top-right",
      type: "success",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    setIsRejecting(false);

    window.location.reload();
  };

  useEffect(() => {
    if (pagination.currentPage <= pagination.totalCount) {
      fetchData(pagination.currentPage);
    }
  }, [pagination.currentPage, pageSize, activeTab]);

  const nextHandler = () => {
    if (pagination.currentPage === pagination.pageCount) return;

    pageRef.current?.scrollIntoView({ behavior: "smooth" });

    if (pagination.currentPage <= pagination.pageCount)
      setPagination((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage + 1,
      }));
    else return;
  };

  const prevHandler = () => {
    pageRef.current?.scrollIntoView({ behavior: "smooth" });
    if (pagination.currentPage > 1)
      setPagination((prevState) => ({
        ...prevState,
        currentPage: prevState.currentPage - 1,
      }));
    else return;
  };

  const filtrationHandler = (activeTab) => {
    setActiveTab(activeTab);
  };

  return (
    <>
      <Modal show={declineData !== null} onHide={closeDeclineModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Rejection reason</h4>
            <p> Kindly provide a reason for rejecting this request.</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Control
              required={true}
              as="textarea"
              rows={3}
              placeholder="Rejection reason"
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-fill form-control"
            variant="danger"
            disabled={isRejecting}
            onClick={() => rejectHandler(declineData.email, declineData.userId)}
            type="submit"
          >
            Decline
          </Button>
        </Modal.Footer>
      </Modal>

      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-tasks">
              <Card.Header ref={pageRef}>
                <Row>
                  <Col>
                    <Card.Title as="h4">Loyalty</Card.Title>
                    <p className="card-category">
                      Loyalty Number Update Requests
                    </p>
                  </Col>

                  <Col className="flex-grow-6">
                    <Form className="p-2">
                      <Row className="justify-between ">
                        <Col className="d-flex gap-1 ">
                          <Form.Group className="flex-grow-1">
                            <Form.Control
                              placeholder="Search..."
                              type="text"
                              onChange={(e) => setSearchQuery(e.target.value)}
                              required
                            ></Form.Control>
                          </Form.Group>
                          <Button
                            className="btn-fill form-control"
                            type="submit"
                            variant="primary"
                            style={{
                              background: CustomStyle.colors.primary,
                              borderColor: CustomStyle.colors.primary,
                              width: "10%",
                            }}
                          >
                            <i className="bi bi-search"></i>
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col className="flex-grow-2">
                    <select
                      className="custom-select custom-select-lg"
                      onChange={(e) => setPageSize(e.target.value)}
                    >
                      <option>10</option>
                      <option>20</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {loading ? (
                  <div
                    style={{
                      flex: 1,
                      textAlign: "center",
                      padding: "20px 0px",
                    }}
                  >
                    <Loader
                      type="Oval"
                      color={CustomStyle.colors.primary}
                      height={50}
                      width={50}
                    />
                  </div>
                ) : (
                  <div className="">
                    <Nav
                      variant="tabs"
                      defaultActiveKey={activeTab}
                      onSelect={filtrationHandler}
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="pending">Pending</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="approved">Accepted</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="declined">Rejected</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    {data && (
                      <div className="p-2 mt-1">
                        {data.map((req) => {
                          return (
                            <Row
                              key={req._id}
                              className={`p-4 border-top ${
                                (searchQuery !== "" &&
                                  req.name
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ===
                                    false) ||
                                (searchQuery !== "" &&
                                  req.email
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase()) ===
                                    false)
                                  ? " d-none "
                                  : " "
                              } `}
                            >
                              <Col className="flex-grow-1">
                                <p className="text-dark card-category">
                                  <b>Name:</b> {req.name}
                                </p>
                                <p className="text-dark card-category">
                                  <b>Email:</b> {req.email}
                                </p>
                                <p className="text-dark card-category">
                                  <b>Gender:</b> {req.sex}
                                </p>
                                <p className="text-dark card-category">
                                  <b>Status:</b> {req.status}
                                </p>
                                <p className="text-dark card-category">
                                  <b>Address:</b> {req.address}
                                </p>
                                <p className="text-dark card-category">
                                  <b>USER ID:</b> {req.userId}
                                </p>
                              </Col>
                              <Col className="flex-grow-1">
                                <p className="text-dark card-category">
                                  New Loyalty Number
                                </p>
                                <Card.Title as="h4" className="fw-semibold">
                                  {req?.cardNo}
                                </Card.Title>
                              </Col>
                              <Col className="d-flex gap-1 flex-align-center flex-grow-0">
                                {req.status !== "APPROVED" &&
                                  req.status !== "DECLINED" && (
                                    <>
                                      <Button
                                        className="btn-fill form-control"
                                        variant="primary"
                                        disabled={
                                          isApproving ||
                                          req.status === "APPROVED"
                                        }
                                        onClick={() => approveHandler(req)}
                                      >
                                        {/* {isApproving ? "Loading..." : "Approve"} */}
                                        Approve
                                      </Button>
                                      <Button
                                        className="btn-fill form-control"
                                        variant="danger"
                                        disabled={
                                          isRejecting ||
                                          req.status === "DECLINED"
                                        }
                                        onClick={() => setDeclineData(req)}
                                      >
                                        Decline
                                      </Button>
                                    </>
                                  )}
                              </Col>
                            </Row>
                          );
                        })}

                        {data && data?.length > 0 && (
                          <div>
                            <Pagination>
                              {pagination.currentPage > 1 && (
                                <Pagination.Prev onClick={prevHandler} />
                              )}
                              {Array.from(
                                { length: pagination.pageCount },
                                (_, index) => index + 1
                              ).map((page) => {
                                return (
                                  <Pagination.Item
                                    key={page}
                                    active={page === pagination.currentPage}
                                    onClick={() => null}
                                    className="pe-none"
                                  >
                                    {page}
                                  </Pagination.Item>
                                );
                              })}
                              {pagination.currentPage <
                                pagination.pageCount && (
                                <Pagination.Next onClick={nextHandler} />
                              )}
                            </Pagination>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Loyalty;
