import React, { useEffect, useState, useContext, useRef } from "react";
import Loader from "react-loader-spinner";
import moment from "moment";
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  Nav,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";

import { Helper } from "../helper";
import CustomStyle from "../styles";
import { AuthContext } from "../context/auth";
import { CSVLink, CSVDownload } from "react-csv";
import { useParams, useHistory, Link, useLocation } from "react-router-dom";
import { showToast } from "utils/general";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function SingleFinance() {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState({});
  const [user, setUser] = useState({});
  const [results, setResults] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersAllData, setOrdersAllData] = useState({});
  const [limit, setLimit] = useState(50);
  const [searching, setSearching] = useState(false);
  const [csvResults, setCsvResults] = useState([]);
  const [csvOrders, setCsvOrders] = useState([]);
  const [navState, setNavState] = useState("orders");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [creditType, setCreditType] = useState("credit");
  const [creditAmount, setCreditAmount] = useState();
  const [remark, setRemark] = useState();
  const [reference, setReference] = useState("");

  const context = useContext(AuthContext);

  const userID = params?.id;

  useEffect(() => {
    // empty active user
    if (Helper.isPopulator(context.authState?.admin)) {
      return false;
    }

    fetchUserTransactions(userID);
    fetchUserOrders(userID);
  }, [userID, location.search]);

  useEffect(() => {
    prepareForCSV();
  }, [results]);

  useEffect(() => {
    prepareCSVForOrders();
  }, [orders]);

  const prepareForCSV = async () => {
    let newResults = [];
    results.forEach(async (r) => {
      r.userName =
        r?.userId?.name ??
        `${r?.userId?.firstname} ${r?.userId?.lastname}` ??
        "";
      r.userEmail = r?.userId?.email ?? "";
      r.userPhone = r?.userId?.phone ?? "";

      newResults.push(r);
    });

    // console.log("Order CSV data >>> ", newResults);

    setCsvResults(newResults);
  };

  const prepareCSVForOrders = async () => {
    let newResults = [];
    orders.forEach(async (r) => {
      r.warehouse = r.deliveryAddress.warehouse.name;
      r.cart.map((cart, index) => {
        r[`item${index}`] = cart.productName;
        r[`itemQty${index}`] = cart.quantity;
        r[`itemPrice${index}`] = cart.price;
        r[`itemCat${index}`] = cart.category;
      });
      newResults.push(r);
    });

    setCsvOrders(newResults);
  };

  const fetchUserTransactions = async (userID, offSet = 1) => {
    // console.log("fetchUserTransactions >> ", userID, offSet);
    const { token } = context.authState;
    setLoading(true);
    try {
      let usersResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/finance/${userID}${
          location.search ? location.search + "&" : "?"
        }limit=${limit}&page=${offSet}`,
        token
      );

      if (usersResponse && usersResponse?.data) {
        setAllData(usersResponse?.data || []);
        setResults(usersResponse?.data?.results || []);
        // setCsvResults(usersResponse?.data?.results);
        setUser(usersResponse?.data?.user);
      }
      setLoading(false);
    } catch (error) {
      showToast(`Oops. failed to fetch user transactions`);
      setLoading(false);
    } finally {
      setSearching(false);
    }
  };

  const fetchUserOrders = async (userID, offSet = 1) => {
    // console.log("fetchUserOrders >> ", userID, offSet);
    const { token } = context.authState;
    setLoading(true);
    try {
      let usersResponse = await Helper.getData(
        `${process.env.REACT_APP_API_V2}/admin/orders/user/${userID}${
          location.search ? location.search + "&" : "?"
        }limit=${limit}&page=${offSet}`,
        token
      );

      if (usersResponse && usersResponse?.data) {
        setOrdersAllData(usersResponse?.data || []);
        setOrders(usersResponse?.data?.results || []);
      }
      setLoading(false);
    } catch (error) {
      showToast(`Oops. failed to fetch user orders`);
      setLoading(false);
    } finally {
      setSearching(false);
    }
  };

  const creditUser = async () => {
    const confirmStatus = confirm(
      `${creditAmount} will be ${
        creditType == "credit" ? "credited into " : "debited from "
      } this user's wallet.`
    );

    if (!!confirmStatus) {
      const { token } = context.authState;
      const userID = params?.id;
      setLoading(true);
      try {
        let usersResponse = await Helper.postData(
          `${process.env.REACT_APP_API_V2}/admin/finance/${userID}/wallet?type=${creditType}`,
          token,
          {
            amount: creditAmount,
            reference: reference,
            reason: remark,
          }
        );

        setLoading(false);
      } catch (error) {
        showToast(`Oops. Transaction failed`);
        setLoading(false);
      } finally {
        fetchUserTransactions(userID);
        closeModal();
      }
    }
  };

  const handlePageClick = (data) => {
    let selected = data.selected + 1;
    const userID = params?.id;
    if (navState == "transactions") {
      fetchUserTransactions(userID, selected);
    } else {
      fetchUserOrders(userID, selected);
    }
  };

  const getOrderValue = (cart) => {
    return cart.reduce(
      (acc, item) => acc + Number(item.price) * item.quantity,
      0
    );
  };

  const closeModal = () => {
    setModalLoading(false);
    setModalOpen(false);
    setCreditAmount();
  };

  if (Helper.isPopulator(context.authState?.admin)) {
    return (
      <div style={{ textAlign: "center" }}>
        <small>You are not permitted to view this page</small>
      </div>
    );
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <div>
              <div
                style={{ marginBottom: 10, cursor: "pointer" }}
                onClick={() => history.goBack()}
              >
                <i
                  className="nc-icon nc-stre-left"
                  style={{ color: "#000000" }}
                ></i>{" "}
                <span>Go Back</span>
              </div>
              <Row>
                <Col lg="4" sm="4">
                  <Card className="card-stats">
                    <Card.Body>
                      <Row>
                        <Col xs="4">
                          <div className="icon-big text-center icon-warning">
                            <i
                              className="nc-icon 
                              nc-single-02"
                              style={{ color: "#009688" }}
                            ></i>
                          </div>
                        </Col>
                        <Col xs="8">
                          <div className="numbers">
                            <p className="card-category">
                              {user?.email}, {user?.phone}
                            </p>
                            <Card.Title as="h4">{user?.name}</Card.Title>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="4">
                  <Card className="card-stats">
                    <Card.Body>
                      <Row>
                        <Col xs="4">
                          <div className="icon-big text-center icon-success">
                            <i
                              className="nc-icon nc-icon nc-delivery-fast"
                              style={{ color: "#673ab7" }}
                            ></i>
                          </div>
                        </Col>
                        <Col xs="8">
                          <div className="numbers">
                            <p className="card-category">Wallet Balance</p>
                            <Card.Title as="h4">
                              {Helper.formatToNaira(user?.wallet ?? 0)}
                            </Card.Title>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg="4" sm="4">
                  <Card className="card-stats">
                    <Card.Body>
                      <Row>
                        <Col xs="5">
                          <div className="icon-big text-center icon-success">
                            <i
                              className="nc-icon nc-icon nc-chart-pie-36"
                              style={{ color: "yellowgreen" }}
                            ></i>
                          </div>
                        </Col>
                        <Col xs="7">
                          <div className="numbers">
                            <p className="card-category">Total Top Up</p>
                            <Card.Title as="h4">
                              {Helper.formatToNaira(allData?.credit ?? 0)}
                            </Card.Title>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Nav variant="tabs" defaultActiveKey="orders">
          <Nav.Item>
            <Nav.Link eventKey="orders" onClick={() => setNavState("orders")}>
              Orders
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="transactions"
              onClick={() => setNavState("transactions")}
            >
              Transactions
            </Nav.Link>
          </Nav.Item>
          {!!Helper.isSuper(context.authState?.admin) && (
            <Button
              className="btn-sm"
              style={{
                position: "absolute",
                right: location?.search && navState == "orders" ? 230 : 150,
              }}
              onClick={() => setModalOpen(!modalOpen)}
            >
              Manage wallet
            </Button>
          )}
          {location?.search && navState == "orders" && (
            <Link to={"/admin/finance/" + userID}>
              <Button
                className="btn-sm"
                style={{ position: "absolute", right: 150 }}
              >
                Show all
              </Button>
            </Link>
          )}
          {!Helper.isAdministrator(context.authState?.admin) && (
            <>
              {navState == "orders" && (
                <Button
                  className="btn-sm"
                  style={{ position: "absolute", right: 50 }}
                >
                  <CSVLink data={csvOrders} className="card-category">
                    Export CSV
                  </CSVLink>
                </Button>
              )}

              {navState == "transactions" && (
                <Button
                  className="btn-sm"
                  style={{ position: "absolute", right: 50 }}
                >
                  <CSVLink data={csvResults} className="card-category">
                    Export CSV
                  </CSVLink>
                </Button>
              )}
            </>
          )}
        </Nav>
        {navState == "transactions" ? (
          <Row>
            <Col md="12">
              <Card className="card-tasks">
                <Card.Header>
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          {/* <span style={{ fontSize: 10 }}>Showing: </span> */}
                          <Row style={{ marginLeft: 2 }}>
                            {/* <select onChange={(e) => setLimit(e.target.value)}>
                            <option>50</option>
                            <option>100</option>
                            <option>1000</option>
                            <option>5000</option>
                          </select>
                          <div style={{ marginLeft: 2 }}></div> */}
                            {/* <Button className="btn-sm">
                            <CSVLink
                              data={csvResults}
                              className="card-category"
                            >
                              Export CSV
                            </CSVLink>
                          </Button> */}
                          </Row>
                        </Col>
                        {!!searching && (
                          <Button
                            className="btn-sm"
                            onClick={fetchUserTransactions}
                          >
                            See all
                          </Button>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body>
                  {loading ? (
                    <div
                      style={{
                        flex: 1,
                        textAlign: "center",
                        padding: "20px 0px",
                      }}
                    >
                      <Loader
                        type="Oval"
                        color={CustomStyle.colors.primary}
                        height={50}
                        width={50}
                      />
                    </div>
                  ) : (
                    <div className="table-full-width">
                      <Table>
                        <tbody>
                          {results && results?.length > 0 ? (
                            results?.map((item, index) => {
                              return (
                                <tr key={index.toString()}>
                                  <td>
                                    <div>
                                      <span>Type:</span>{" "}
                                      <b
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {item?.typeOfTx}
                                      </b>{" "}
                                      <small>({item?.status})</small>
                                    </div>
                                    <div>
                                      <span>Amount:</span>{" "}
                                      <b
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {Helper.formatToNaira(item?.amount)}
                                      </b>
                                    </div>
                                    <div>
                                      <span>Reference:</span>{" "}
                                      <b
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {item?.reference}
                                      </b>
                                    </div>
                                    <div>
                                      <span>Channels:</span>{" "}
                                      <b
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {item?.txChannel} {",  "}{" "}
                                        {item?.paymentType}
                                      </b>
                                    </div>
                                    <small>
                                      Created{" "}
                                      {moment(item?.createdAt).fromNow()} (
                                      {moment(item?.createdAt).format(
                                        "DD/MM/YYYY"
                                      )}
                                      )
                                    </small>
                                  </td>
                                  {/* <td className="td-actions text-right">
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip id="tooltip-938342127">
                                        Toggle user status
                                      </Tooltip>
                                    }
                                  >
                                    {item?.status == "success" && (
                                      <Button
                                        className="btn btn-info btn-fill"
                                        type="button"
                                        variant="info"
                                        disabled={submitLoading == item?._id}
                                        onClick={async () =>
                                          toggleUserStatus(true, item?._id)
                                        }
                                      >
                                        {submitLoading == item?._id
                                          ? "Confirming"
                                          : "Confirm Payments"}
                                      </Button>
                                    )}
                                  </OverlayTrigger>
                                </td> */}
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan="6"
                                style={{
                                  textAlign: "center",
                                  color: CustomStyle.colors.danger,
                                }}
                              >
                                <small>No transaction found</small>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </Card.Body>
                <Card.Footer>
                  {allData && allData?.total ? (
                    <ReactPaginate
                      previousLabel={<i className="nc-icon nc-stre-left"></i>}
                      nextLabel={<i className="nc-icon nc-stre-right"></i>}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={allData?.totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </Card.Footer>
              </Card>
            </Col>
            {allData && allData?.length > 0 && (
              <Col
                md="4"
                style={{ position: "absolute", right: 0, top: 0, bottom: 0 }}
              >
                <Card className="card-tasks">
                  <Card.Header>
                    <Card.Title as={"h4"}>Users</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div className="table-full-width">
                      <Table>
                        <tbody>
                          {allData && allData?.length > 0 ? (
                            allData?.map((item, index) => {
                              return (
                                <tr>
                                  <td>
                                    <div>
                                      <b
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        <Link to={item._id + "?type=user"}>
                                          {item?.name}
                                        </Link>
                                      </b>
                                    </div>
                                    <div>
                                      <b
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {item?.email}
                                      </b>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan="6"
                                style={{
                                  textAlign: "center",
                                  color: CustomStyle.colors.danger,
                                }}
                              >
                                <small>No user found</small>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        ) : (
          <Row>
            <Col md="12">
              <Card className="card-tasks">
                {/* <Card.Header>
                <Row>
                  
                  <Col>
                  <Row>
                      <Col>
                        <span style={{ fontSize: 10 }}>Showing: </span>
                        <Row style={{ marginLeft: 2 }}>
                          <select onChange={(e) => setLimit(e.target.value)}>
                            <option>50</option>
                            <option>100</option>
                            <option>1000</option>
                            <option>5000</option>
                          </select>
                          <div style={{ marginLeft: 2 }}></div>
                          <Button className="btn-sm">
                            <CSVLink
                              data={csvResults}
                              className="card-category"
                            >
                              Export CSV
                            </CSVLink>
                          </Button>
                        </Row>
                      </Col>
                      {!!searching && (
                        <Button className="btn-sm" onClick={fetchUserTransactions}>
                          See all
                        </Button>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Card.Header> */}
                <Card.Body>
                  {loading ? (
                    <div
                      style={{
                        flex: 1,
                        textAlign: "center",
                        padding: "20px 0px",
                      }}
                    >
                      <Loader
                        type="Oval"
                        color={CustomStyle.colors.primary}
                        height={50}
                        width={50}
                      />
                    </div>
                  ) : (
                    <div className="table-full-width">
                      <Table>
                        <thead>
                          <tr>
                            <td>Order#</td>
                            <td>Amount </td>
                            <td>Payment </td>
                            <td>Purchases</td>
                            <td>Store</td>
                            <td>Fulfilment</td>
                          </tr>
                        </thead>
                        <tbody>
                          {orders && orders?.length > 0 ? (
                            orders?.map((item, index) => {
                              return (
                                <tr key={index.toString()}>
                                  <td>
                                    <div>{item?.id}</div>
                                    <small>
                                      Requested{" "}
                                      {moment(item?.createdAt).fromNow()}
                                    </small>
                                  </td>
                                  <td>
                                    {Helper.formatToNaira(
                                      getOrderValue(item?.cart)
                                    )}
                                  </td>
                                  <td>
                                    <div>
                                      {Helper.formatToNaira(item?.totalAmount)}
                                      <BreakdownDropdown
                                        item={item?.transactions}
                                      />
                                    </div>
                                    <small>{item?.paymentOption}, </small>
                                    <small
                                      className={
                                        item?.paymentStatus == "success"
                                          ? "text-success"
                                          : item?.paymentStatus == "pending"
                                          ? "text-warning"
                                          : "text-dark"
                                      }
                                    >
                                      {item?.paymentStatus}
                                    </small>
                                    <div>
                                      <small>
                                        {item?.paymentData?.reference}
                                      </small>
                                    </div>
                                  </td>

                                  <td>
                                    <ProductDropdown item={item} />
                                  </td>
                                  <td>
                                    <div
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      <b>
                                        {item?.deliveryOption == "delivery"
                                          ? item?.deliveryAddress?.description
                                          : item?.deliveryAddress?.warehouse
                                              ?.name}
                                      </b>
                                    </div>
                                    <small>{item?.deliveryOption}</small>
                                  </td>
                                  <td>
                                    <div
                                      className={
                                        item?.status == "completed"
                                          ? "text-success"
                                          : item?.status == "shipped"
                                          ? "text-primary"
                                          : item?.status == "confirmed"
                                          ? "text-info"
                                          : item?.status == "declined"
                                          ? "text-danger"
                                          : item?.status == "pending"
                                          ? "text-warning"
                                          : "text-dark"
                                      }
                                    >
                                      {item?.status}
                                    </div>
                                    {item?.trackId && (
                                      <small>
                                        Tracking ID: {item?.trackId}
                                      </small>
                                    )}
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan="6"
                                style={{
                                  textAlign: "center",
                                  color: CustomStyle.colors.danger,
                                }}
                              >
                                <small>No orders found</small>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  )}
                </Card.Body>
                <Card.Footer>
                  {ordersAllData && ordersAllData?.total ? (
                    <ReactPaginate
                      previousLabel={<i className="nc-icon nc-stre-left"></i>}
                      nextLabel={<i className="nc-icon nc-stre-right"></i>}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={ordersAllData?.totalPages}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  ) : null}
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
      <Modal
        isOpen={modalOpen}
        onRequestClose={() => closeModal()}
        style={customStyles}
        contentLabel="Delete Modal"
      >
        {modalOpen ? (
          <>
            <h5
              style={{
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
              }}
            >
              <b style={{ textTransform: "capitalize" }}>{creditType} User</b>
            </h5>
            <br />
            <Col>
              <Form
                className="container"
                noValidate
                // validated={searchValidated}
                onSubmit={(e) => {
                  e.preventDefault();
                  creditUser();
                }}
              >
                <Col>
                  <Row>
                    <Col md="1.5">
                      <select onChange={(e) => setCreditType(e.target.value)}>
                        <option value="credit">Credit</option>
                        <option value="debit">Debit</option>
                      </select>
                    </Col>
                    <Col md="5">
                      <Form.Group>
                        {/* <label>Keyword</label> */}
                        <Form.Control
                          placeholder={`Add amount to ${creditType}`}
                          type="number"
                          required
                          onChange={(e) => setCreditAmount(e.target.value)}
                          value={creditAmount}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          Please add an amount to {creditType}.
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <br />
                <Form.Group>
                  <Form.Control
                    placeholder="Reference (if it relates to another transaction)"
                    type="text"
                    onChange={(e) => setReference(e.target.value)}
                    value={reference}
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Control
                    cols="80"
                    placeholder={`Reason for ${creditType}`}
                    rows="4"
                    as="textarea"
                    onChange={(e) => setRemark(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Row>
                  <Col className="" md="4" />
                  <Col className="" md="4">
                    <Form.Group>
                      <label htmlFor=""></label>
                      <Button
                        onClick={() => closeModal()}
                        className="btn-fill form-control pull-right"
                        variant="default"
                        type="button"
                      >
                        Close
                      </Button>
                    </Form.Group>
                  </Col>
                  <Col className="" md="4">
                    <Form.Group>
                      <label htmlFor=""></label>
                      <Button
                        className="btn-fill form-control pull-right"
                        variant="primary"
                        type="submit"
                        disabled={
                          modalLoading ||
                          creditAmount == 0 ||
                          !creditAmount ||
                          remark == ""
                        }
                        // onClick={handleRemarkSubmit}
                      >
                        {modalLoading ? (
                          <Loader
                            type="Oval"
                            color={CustomStyle.colors.white}
                            height={20}
                            width={20}
                          />
                        ) : (
                          `${creditType} user`
                        )}
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </>
        ) : null}
      </Modal>
    </>
  );
}

function ProductDropdown({ item }) {
  const [isToggled, setIsToggled] = useState(false);
  return (
    <>
      <b
        className="text-info ml-1"
        role="button"
        onClick={() => {
          isToggled ? setIsToggled(false) : setIsToggled(true);
        }}
        style={{
          alignItems: "center",
        }}
      >
        {item?.cart.length} Item
        {item?.cart.length > 1 ? "s " : " "}
        {isToggled ? (
          <i className="nc-icon ml-1 nc-stre-up"></i>
        ) : (
          <i className="nc-icon ml-1 nc-stre-down"></i>
        )}
      </b>
      <div className={`collapse ${isToggled ? "show" : ""}`}>
        <div className="card card-body p-0">
          <ul className="list-group">
            {item?.cart.map((itm, i) => {
              return (
                <li
                  key={i}
                  className="list-group-item d-flex justify-content-between align-items-center"
                >
                  <div className="media">
                    <img
                      src={itm?.productImg}
                      style={{ width: 50, height: 50 }}
                    />
                    <div className="media-content pl-2">
                      <div>
                        <span>Name: </span>
                        <b>{itm?.productName || "unknown"}</b>
                      </div>
                      <div>
                        <span>Quantity: </span>
                        <b>x{itm?.quantity || 0}</b>
                      </div>
                      <div>
                        <span>Price: </span>
                        <b>{Helper.formatToNaira(itm?.price || 0)}</b>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

function BreakdownDropdown({ item }) {
  const [isToggled, setIsToggled] = useState(false);
  return (
    <>
      <b
        class="text-info ml-1"
        role="button"
        onClick={() => {
          isToggled ? setIsToggled(false) : setIsToggled(true);
        }}
        style={{
          alignItems: "center",
          textTransform: "capitalize",
        }}
      >
        {" "}
        <small>See more</small>
        {isToggled ? (
          <i className="nc-icon ml-1 nc-stre-up"></i>
        ) : (
          <i className="nc-icon ml-1 nc-stre-down"></i>
        )}
      </b>
      <div class={`collapse ${isToggled ? "show" : ""}`}>
        {item.map((tx, index) => (
          <div class="card card-body">
            <div>
              <span>type: </span>
              <b>{tx?.typeOfTx}</b>
            </div>
            <div>
              <span>Amount: </span>
              <b>{Helper.formatToNaira(tx?.amount)}</b>
            </div>
            <div>
              <span>Paid with: </span>
              <b>{tx?.paymentType}</b>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default SingleFinance;
