import { Helper } from "../helper";
import Axios from "../utils/axios";

// export const syncICGProducts = async (token, icgProducts, callback) => {
//   try {
//     await Helper.putData(
//       `${process.env.REACT_APP_API_V2}/admin/products`,
//       token,
//       icgProducts
//     );

//     callback({ status: true });
//   } catch (error) {
//     callback({ status: false });
//   }
// };

export const syncICGProducts = async (token, sD, eD, callback) => {
  try {
    await Helper.putData(
      `${process.env.REACT_APP_API_V2}/admin/products/sync?start=${sD}&end=${eD}`,
      token
    );

    callback({ status: true });
  } catch (error) {
    callback({ status: false });
  }
};

export const getOurProducts = async (
  token,
  offSet = 1,
  limit,
  callback,
  start,
  end
) => {
  try {
    let response = await Helper.getData(
      `${process.env.REACT_APP_API_V2}/admin/products?page=${offSet}&limit=${limit}&start=${start}&end=${end}`,
      token
    );

    callback({ status: true, data: response.data });
  } catch (error) {
    callback({ status: false });
  }
};

export const getOurInactiveProducts = async (
  token,
  offSet = 1,
  limit,
  callback,
  start,
  end
) => {
  try {
    let response = await Helper.getData(
      `${process.env.REACT_APP_API_V2}/admin/products/inactive?page=${offSet}&limit=${limit}&start=${start}&end=${end}`,
      token
    );

    callback({ status: true, data: response.data });
  } catch (error) {
    callback({ status: false });
  }
};

export const getCombos = async (
  token,
  offSet = 1,
  limit,
  callback,
  start,
  end
) => {
  try {
    let response = await Helper.getData(
      `${process.env.REACT_APP_API_V2}/admin/products/combo?page=${offSet}&limit=${limit}&start=${start}&end=${end}`,
      token
    );

    callback({ status: true, data: response.data });
  } catch (error) {
    callback({ status: false });
  }
};

export const setCombo = async (token, comboSet) => {
  if (!token || !callback || !Array.isArray(comboSet)) return;

  try {
    const formData = new FormData();

    formData.append("comboSet", comboSet);

    let response = await Helper.postData(
      `${process.env.REACT_APP_API_V2}/admin/products/`,
      token,
      formData
    );

    return { status: true, data: response.data };
  } catch (error) {
    console.log("ERROR: ", error);
    return { status: false, error };
  }
};

export const getCategories = async (token, callback) => {
  try {
    let response = await Helper.getData(
      `${process.env.REACT_APP_API_V2}/admin/category`,
      token
    );

    callback({ status: true, data: response.data });
  } catch (error) {
    setModalFetchLoading(false);
  }
};

export const updateOurProduct = async (
  products,
  modalFormData,
  images,
  token,
  callback
) => {
  const formData = new FormData();

  formData.append("ItemID", modalFormData?.itemid);
  formData.append("Name", modalFormData?.name ?? "");
  formData.append("ItemDescription", modalFormData?.itemDescription ?? "");
  formData.append("GTIN", modalFormData?.gtin ?? "");
  formData.append("Barcode", modalFormData?.gtin ?? "");
  formData.append("BrandName", modalFormData?.brandname ?? "");
  // formData.append("Category", modalFormData?.category ?? "");
  formData.append("Package", modalFormData?.package ?? "");
  formData.append("LabelDescription", modalFormData?.labeldescription ?? "");
  formData.append(
    "MarketingInformation",
    modalFormData?.marketinginformation ?? ""
  );
  if (
    images.frontimageFileInput.current &&
    images.frontimageFileInput.current.files[0]
  ) {
    formData.append("FrontImage", images.frontimageFileInput.current.files[0]);
  } else {
    formData.append("FrontImage", modalFormData?.frontimage);
  }
  if (
    images.backimageFileInput.current &&
    images.backimageFileInput.current.files[0]
  ) {
    formData.append("BackImage", images.backimageFileInput.current.files[0]);
  } else {
    formData.append("BackImage", modalFormData?.backimage ?? "");
  }
  formData.append("UnitOfMeasurement", modalFormData?.unitofmeasurement ?? "");
  formData.append("GrossWeight", modalFormData?.grossweight ?? "");
  formData.append("NetWeight", modalFormData?.netweight ?? "");
  formData.append("Expiration", modalFormData?.expiration ?? "");
  formData.append("BrandOwner", modalFormData?.brandOwner ?? "");
  formData.append("Address", modalFormData?.address ?? "");
  formData.append("MoName", modalFormData?.moName ?? "");
  formData.append("comboSet", JSON.stringify(modalFormData?.comboSet) ?? []);
  formData.append("stock", JSON.stringify(modalFormData?.stock) ?? []);
  formData.append("SellingPrice", Number(modalFormData?.sellingPrice));
  formData.append("ItemDeptCode", modalFormData?.itemDeptCode ?? "");
  formData.append("ItemDeptName", modalFormData?.itemDeptName ?? "");
  formData.append("ItemSectionCode", modalFormData?.itemSectionCode ?? "");
  formData.append("ItemSectionName", modalFormData?.itemSectionName ?? "");
  formData.append("Reference", modalFormData?.reference ?? "");
  formData.append("Weight", modalFormData?.weight);

  console.log("Weight >>> ", modalFormData?.weight);

  try {
    let response;

    response = await Axios.patch(
      `${process.env.REACT_APP_API_V2}/admin/products/${modalFormData.id}`,
      formData,
      {
        headers: {
          "x-access-token": token,
        },
      }
    );

    const { data } = response?.data;

    callback({ status: true, data });

    // console.log("DDL >> ", data)
    // callback({ status: true });

    // if (data && data?.ItemID) {
    // 	const index = products.findIndex(
    // 		(p) => p?.Barcode == modalFormData?.gtin
    // 	);
    // 	if (index > -1) {
    // 		const updatedProducts = products;
    // 		const updated = {
    // 			...updatedProducts[index],
    // 			...data,
    // 		};
    // 		updatedProducts[index] = updated;
    // 		// console.log("index >> ", index, updatedProducts)

    // 		callback({ status: true, data: [...updatedProducts] });
    // 	}
    // }
    // else {
    //   callback({ status: true });

    // }
  } catch (error) {
    console.log("ERROR >> ", error);
    callback({ status: false, error });
  }
};

export const searchOurProducts = async (token, keyword, callback) => {
  try {
    let response = await Helper.getData(
      `${process.env.REACT_APP_API_V2}/admin/products/search/${keyword}`,
      token
    );

    callback({ status: true, data: response.data });
  } catch (error) {
    callback({ status: false });
  }
};
