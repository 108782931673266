import React, { useEffect } from "react";
import Loader from "react-loader-spinner";
import { Button, Card, Table, Row, Col, Form } from "react-bootstrap";
import Modal from "react-modal";
import ReactPaginate from "react-paginate";
import { Helper } from "helper";
import CustomStyle from "../../styles";
import { useProducts } from "./useProducts";
import { EditProductModal } from "./utils";

const customStyles = {
	content: {
		top: "0px",
		left: "0px",
		right: "0px",
		bottom: "0px",
	},
};

Modal.setAppElement("#root");

function InactiveProducts() {
	const {
		handleInactivePagination,
		handleRangeSearch,
		handleSearch,
		editProduct,
		loading,
		validated,
		searchValidated,
		results,
		start,
		end,
		keywordInput,
		startInput,
		endInput,
		products,
		updateICGProducts,
		afterOpenOurProductsModal,
		modalLoading,
		modalIsOpen,
		warehousesStock,
		currentProduct,
		modalFormData,
		closeModal,
		handleModalChange,
		getInactiveProducts,
		frontimageFileInput,
		backimageFileInput,
		modalFetchLoading,
		modalValidated,
		productIsUpdate,
	} = useProducts();

	useEffect(() => {
		getInactiveProducts();
	}, []);

	return (
		<>
			<Row>
				{/* <Col md="9"> */}
				<Col md="12">
					<Card className="strpied-tabled-with-hover">
						<Card.Header>
							<Row>
								<Col md="6">
									<Card.Title as="h4">
										Inactive Products Table
									</Card.Title>
									<p className="card-category">
										View and modify all products{" "}
										<span className="text-info">
											(Showing products from {start} to{" "}
											{end})
										</span>
									</p>
								</Col>
								<Col md="6">
									<Form
										className="container"
										noValidate
										validated={validated}
										onSubmit={(e) => {
											e.preventDefault();
											handleRangeSearch(e);
										}}
									>
										<Row>
											<Col md="5">
												<Form.Group>
													<label>Start</label>
													<Form.Control
														placeholder={`Start Value (${start})`}
														type="number"
														min="1"
														required
														ref={startInput}
													></Form.Control>
													<Form.Control.Feedback type="invalid">
														Please enter a start
														value.
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col md="5">
												<Form.Group>
													<label>End</label>
													<Form.Control
														placeholder={`End Value (${end})`}
														type="number"
														min="1"
														required
														ref={endInput}
													></Form.Control>
													<Form.Control.Feedback type="invalid">
														Please enter an end
														value.
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col md="2">
												<Form.Group>
													<label>Fetch</label>
													<Button
														className="btn-fill form-control"
														type="submit"
														variant="primary"
														style={{
															background:
																CustomStyle
																	.colors
																	.primary,
															borderColor:
																CustomStyle
																	.colors
																	.primary,
														}}
													>
														<i className="nc-icon nc-cloud-download-93"></i>
													</Button>
												</Form.Group>
											</Col>
										</Row>
									</Form>
									<Form
										className="container"
										noValidate
										validated={searchValidated}
										onSubmit={(e) => {
											e.preventDefault();
											handleSearch(e);
										}}
									>
										<Row>
											<Col md="10">
												<Form.Group>
													<Form.Control
														placeholder="Enter Search Keyword"
														type="text"
														required
														ref={keywordInput}
													></Form.Control>
													<Form.Control.Feedback type="invalid">
														Please enter a keyword.
													</Form.Control.Feedback>
												</Form.Group>
											</Col>
											<Col md="2">
												<Form.Group>
													<Button
														className="btn-fill form-control"
														type="submit"
														variant="primary"
														style={{
															background:
																CustomStyle
																	.colors
																	.primary,
															borderColor:
																CustomStyle
																	.colors
																	.primary,
														}}
													>
														<i className="nc-icon nc-zoom-split"></i>
													</Button>
												</Form.Group>
											</Col>
										</Row>
									</Form>
								</Col>
							</Row>
						</Card.Header>
						<Card.Body className="table-full-width table-responsive px-0">
							{loading ? (
								<div
									style={{
										flex: 1,
										textAlign: "center",
										padding: "20px 0px",
									}}
								>
									<Loader
										type="Oval"
										color={CustomStyle.colors.primary}
										height={50}
										width={50}
									/>
								</div>
							) : (
								<Table className="table-hover table-striped">
									<thead>
										<tr>
											<th className="border-0">ID</th>
											<th className="border-0">Name</th>
											<th className="border-0">
												Barcode
											</th>
											<th className="border-0">Price</th>
											<th className="border-0">Status</th>
											<th className="border-1"></th>
										</tr>
									</thead>
									<tbody>
										{products.length > 0 ? (
											products.map((item, index) => {
												return (
													<tr key={index}>
														<td>{item.ItemID}</td>
														<td>{item.Name}</td>
														<td>{item.Barcode}</td>
														<td>
															{Helper.formatToNaira(
																item.SellingPrice
															)}
														</td>
														<td>
															{item?.FrontImage ? (
																<small className="text-success">
																	Updated With
																	Image
																</small>
															) : item?.Address ||
															  item?.Category ||
															  item?.Package ||
															  item?.FrontImage ||
															  item?.BackImage ? (
																<small className="text-info">
																	Processing...
																</small>
															) : (
																<small className="text-warning">
																	Pending
																</small>
															)}
														</td>
														<td>
															<Button
																className="btn-fill form-control"
																type="submit"
																variant="primary"
																style={{
																	background:
																		CustomStyle
																			.colors
																			.primary,
																	borderColor:
																		CustomStyle
																			.colors
																			.primary,
																}}
																onClick={() =>
																	editProduct(
																		item
																	)
																}
															>
																<i className="nc-icon nc-preferences-circle-rotate"></i>
															</Button>
														</td>
													</tr>
												);
											})
										) : (
											<tr>
												<td
													colSpan="6"
													style={{
														textAlign: "center",
														color: CustomStyle
															.colors.danger,
													}}
												>
													<small>
														No product found
													</small>
												</td>
											</tr>
										)}
									</tbody>
								</Table>
							)}
						</Card.Body>
						<Card.Footer>
							{results && results?.total ? (
								<ReactPaginate
									previousLabel={
										<i className="nc-icon nc-stre-left"></i>
									}
									nextLabel={
										<i className="nc-icon nc-stre-right"></i>
									}
									breakLabel={"..."}
									breakClassName={"break-me"}
									pageCount={results?.totalPages}
									marginPagesDisplayed={2}
									pageRangeDisplayed={5}
									onPageChange={handleInactivePagination}
									containerClassName={"pagination"}
									activeClassName={"active"}
								/>
							) : null}
						</Card.Footer>
					</Card>
				</Col>
			</Row>

			<Modal
				isOpen={modalIsOpen}
				onAfterOpen={() => afterOpenOurProductsModal()}
				onRequestClose={() => closeModal()}
				style={customStyles}
				contentLabel="Product Modal"
			>
				{currentProduct ? (
					<EditProductModal
						currentProduct={currentProduct}
						modalFormData={modalFormData}
						warehousesStock={warehousesStock}
						closeModal={closeModal}
						handleModalChange={handleModalChange}
						updateICGProducts={updateICGProducts}
						loading={loading}
						modalLoading={modalLoading}
						frontimageFileInput={frontimageFileInput}
						backimageFileInput={backimageFileInput}
						modalFetchLoading={modalFetchLoading}
						modalValidated={modalValidated}
						productIsUpdate={productIsUpdate}
					/>
				) : (
					<Loader
						type="Oval"
						color={CustomStyle.colors.white}
						height={20}
						width={20}
					/>
				)}
			</Modal>
		</>
	);
}

export default InactiveProducts;
