import React, { useEffect, useState, useContext } from "react";
import ChartistGraph from "react-chartist";
import { toast } from "react-toastify";
import Loader from "react-loader-spinner";
import moment from "moment";
// react-bootstrap components
import {
	Badge,
	Button,
	Card,
	Navbar,
	Nav,
	Table,
	Container,
	Row,
	Col,
	Form,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";

import { useHistory } from "react-router-dom";

import { Helper } from "../helper";
import CustomStyle from "../styles";
import { AuthContext } from "../context/auth";
import {
	DashboardContext,
	SET_WAREHOUSES,
	SET_ACTIVE_WAREHOUSE,
} from "../context/dashboard";
import { getDYWarehouses } from "services/dynamics";

function Dashboard() {
	const [loading, setLoading] = useState(true);
	const [loadingChart, setLoadingChart] = useState(false);
	const [data, setData] = useState({});
	const [chartData, setChartData] = useState({});
	const [startDate, setStartDate] = useState("2021-05-01");
	const [endDate, setEndDate] = useState("2021-08-07");
	const [abandonedData, setAbandonedData] = useState([]);

	const { dispatch } = useContext(DashboardContext);
	const context = useContext(AuthContext);
	const history = useHistory();

	useEffect(() => {
		if (
			// Helper.isPopulator(context.authState?.admin) ||
			Helper.isAdministrator(context.authState?.admin) ||
			Helper.isFinance(context.authState?.admin)
		) {
			return false;
		}
		// component did mount
		initWeekDates();

		// get data from Our database
		fetchData();
		getAbandonedCart();
	}, []);

	useEffect(() => {
		fetchChartData();
	}, [startDate, endDate]);

	const getAbandonedCart = async () => {
		const { token } = context.authState;

		let cartResponse = await Helper.getData(
			`${process.env.REACT_APP_API_V2}/admin/users/cart/abandoned`,
			token
		);

		// console.log("For life >>> ", cartResponse);

		setAbandonedData(cartResponse.data);
	};

	const initWeekDates = () => {
		let curr = new Date(); // get current date
		let first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
		let last = first + 6; // last day is the first day + 6

		let firstday = new Date(curr.setDate(first)).toUTCString();
		let lastday = new Date(curr.setDate(last)).toUTCString();

		setStartDate(moment(firstday).format("YYYY-MM-DD"));
		setEndDate(moment(lastday).format("YYYY-MM-DD"));
	};

	const fetchData = async () => {
		const { token, erpToken } = context.authState;
		setLoading(true);
		try {
			let productResponse = await Helper.getData(
				`${process.env.REACT_APP_API_V2}/admin/analytics`,
				token
			);
			let warehouseResponse = await getDYWarehouses(token, erpToken);

			if (
				productResponse &&
				productResponse?.data &&
				productResponse?.data?.productTotal
			) {
				setData({
					productTotal: productResponse?.data?.productTotal,
					warehouseTotal: productResponse?.data?.warehouseTotal,
					feedsTotal: productResponse?.data?.feedsTotal,
					orderTotal: productResponse?.data?.orderTotal,
					promoTotal: productResponse?.data?.promoTotal,
					userTotal: productResponse?.data?.userTotal,
					warehouses: warehouseResponse?.data || [],
				});
				dispatch({
					type: SET_WAREHOUSES,
					payload: warehouseResponse?.data || [],
				});
			}
			setLoading(false);
		} catch (error) {
			toast(`Oops. failed to fetch analytics`, {
				position: "top-right",
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			setLoading(false);
		}
	};

	const fetchChartData = async () => {
		const { token } = context.authState;
		setLoadingChart(true);
		try {
			let chartResponse = await Helper.postData(
				`${process.env.REACT_APP_API_V2}/admin/orders/analytics`,
				token,
				{
					startDate,
					endDate,
				}
			);

			if (chartResponse && chartResponse?.data) {
				setChartData(chartResponse?.data);
			}
			setLoadingChart(false);
		} catch (error) {
			console.log("Chart analytics error >>> ", error.message);
			toast(`Oops. failed to fetch chart analytics`, {
				position: "top-right",
				type: "error",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			setLoadingChart(false);
		}
	};

	const getChartLabels = () => {
		let labels = [];
		for (let index = 0; index < chartData.length; index++) {
			const data = chartData[index];
			const date = `${data?._id?.day}/${data?._id?.month}/${data?._id?.year}`;
			labels.push(moment(date, "DD/MM/YYYY").format("ddd"));
		}

		return labels;
	};

	const getChartSeries = () => {
		let series = [];
		for (let index = 0; index < chartData.length; index++) {
			const data = chartData[index];
			const amount = data?.totalValue;
			series.push(amount);
		}

		return series;
	};

	if (
		// Helper.isPopulator(context.authState?.admin) ||
		Helper.isFinance(context.authState?.admin) ||
		Helper.isAdministrator(context.authState?.admin)
	) {
		return (
			<div style={{ textAlign: "center" }}>
				<small>You are not permitted to view this page</small>
			</div>
		);
	}

	if (loading) {
		return (
			<div
				style={{
					flex: 1,
					textAlign: "center",
					padding: "20px 0px",
				}}
			>
				<Loader
					type="Oval"
					color={CustomStyle.colors.primary}
					height={50}
					width={50}
				/>
			</div>
		);
	}

	return (
		<>
			<Container fluid>
				{data && data?.productTotal && data?.warehouseTotal ? (
					<Row>
						<Col lg="3" sm="6">
							<Card className="card-stats">
								<Card.Body>
									<Row>
										<Col xs="5">
											<div className="icon-big text-center icon-warning">
												<i className="nc-icon nc-circle-09 text-warning"></i>
											</div>
										</Col>
										<Col xs="7">
											<div className="numbers">
												<p className="card-category">
													Users
												</p>
												<Card.Title as="h4">
													{data?.userTotal || 0}
												</Card.Title>
											</div>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
						<Col lg="3" sm="6">
							<Card className="card-stats">
								<Card.Body>
									<Row>
										<Col xs="5">
											<div className="icon-big text-center icon-secondary">
												<i className="nc-icon nc-square-pin text-secondary"></i>
											</div>
										</Col>
										<Col xs="7">
											<div className="numbers">
												<p className="card-category">
													Warehouses
												</p>
												<Card.Title as="h4">
													{data?.warehouseTotal || 0}
												</Card.Title>
											</div>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
						<Col lg="3" sm="6">
							<Card className="card-stats">
								<Card.Body>
									<Row>
										<Col xs="5">
											<div className="icon-big text-center icon-warning">
												<i className="nc-icon nc-single-copy-04 text-primary"></i>
											</div>
										</Col>
										<Col xs="7">
											<div className="numbers">
												<p className="card-category">
													Feeds
												</p>
												<Card.Title as="h4">
													{data?.feedsTotal || 0}
												</Card.Title>
											</div>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
						<Col lg="3" sm="6">
							<Card className="card-stats">
								<Card.Body>
									<Row>
										<Col xs="5">
											<div className="icon-big text-center icon-danger">
												<i className="nc-icon nc-icon nc-spaceship text-danger"></i>
											</div>
										</Col>
										<Col xs="7">
											<div className="numbers">
												<p className="card-category">
													Promos
												</p>
												<Card.Title as="h4">
													{data?.promoTotal || 0}
												</Card.Title>
											</div>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				) : null}
				<Row>
					{/* <Col md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Users Behavior</Card.Title>
                <p className="card-category">24 Hours performance</p>
              </Card.Header>
              <Card.Body>
                <div className="ct-chart" id="chartHours">
                  <ChartistGraph
                    data={{
                      labels: [
                        "9:00AM",
                        "12:00AM",
                        "3:00PM",
                        "6:00PM",
                        "9:00PM",
                        "12:00PM",
                        "3:00AM",
                        "6:00AM",
                      ],
                      series: [
                        [287, 385, 490, 492, 554, 586, 698, 695],
                        [67, 152, 143, 240, 287, 335, 435, 437],
                        [23, 113, 67, 108, 190, 239, 307, 308],
                      ],
                    }}
                    type="Line"
                    options={{
                      low: 0,
                      high: 800,
                      showArea: false,
                      height: "245px",
                      axisX: {
                        showGrid: false,
                      },
                      lineSmooth: true,
                      showLine: true,
                      showPoint: true,
                      fullWidth: true,
                      chartPadding: {
                        right: 50,
                      },
                    }}
                    responsiveOptions={[
                      [
                        "screen and (max-width: 640px)",
                        {
                          axisX: {
                            labelInterpolationFnc: function (value) {
                              return value[0];
                            },
                          },
                        },
                      ],
                    ]}
                  />
                </div>
              </Card.Body>
              <Card.Footer>
                <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  Open <i className="fas fa-circle text-danger"></i>
                  Click <i className="fas fa-circle text-warning"></i>
                  Click Second Time
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-history"></i>
                  Updated 3 minutes ago
                </div>
              </Card.Footer>
            </Card>
          </Col>
          <Col md="4">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Email Statistics</Card.Title>
                <p className="card-category">Last Campaign Performance</p>
              </Card.Header>
              <Card.Body>
                <div
                  className="ct-chart ct-perfect-fourth"
                  id="chartPreferences"
                >
                  <ChartistGraph
                    data={{
                      labels: ["40%", "20%", "40%"],
                      series: [40, 20, 40],
                    }}
                    type="Pie"
                  />
                </div>
                <div className="legend">
                  <i className="fas fa-circle text-info"></i>
                  Open <i className="fas fa-circle text-danger"></i>
                  Bounce <i className="fas fa-circle text-warning"></i>
                  Unsubscribe
                </div>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-clock"></i>
                  Campaign sent 2 days ago
                </div>
              </Card.Body>
            </Card>
          </Col> */}
				</Row>
				<Row>
					<Col md="8">
						<div>
							<Row>
								<Col lg="4" sm="4">
									<Card className="card-stats">
										<Card.Body>
											<Row>
												<Col xs="5">
													<div className="icon-big text-center icon-warning">
														<i
															className="nc-icon 
                              nc-layers-3"
															style={{
																color: "#009688",
															}}
														></i>
													</div>
												</Col>
												<Col xs="7">
													<div className="numbers">
														<p className="card-category">
															Products
														</p>
														<Card.Title as="h4">
															{data?.productTotal ||
																0}
														</Card.Title>
													</div>
												</Col>
											</Row>
										</Card.Body>
									</Card>
								</Col>
								<Col lg="4" sm="4">
									<Card className="card-stats">
										<Card.Body>
											<Row>
												<Col xs="5">
													<div className="icon-big text-center icon-success">
														<i
															className="nc-icon nc-icon nc-delivery-fast"
															style={{
																color: "#673ab7",
															}}
														></i>
													</div>
												</Col>
												<Col xs="7">
													<div className="numbers">
														<p className="card-category">
															Orders
														</p>
														<Card.Title as="h4">
															{data?.orderTotal ||
																0}
														</Card.Title>
													</div>
												</Col>
											</Row>
										</Card.Body>
									</Card>
								</Col>
								<Col lg="4" sm="4">
									<Card className="card-stats">
										<Card.Body>
											<Row>
												<Col xs="5">
													<div className="icon-big text-center icon-success">
														<i
															className="nc-icon nc-icon nc-chart-pie-36"
															style={{
																color: "yellowgreen",
															}}
														></i>
													</div>
												</Col>
												<Col xs="7">
													<div className="numbers">
														<p className="card-category">
															Abandoned Cart
														</p>
														<Card.Title as="h4">
															{abandonedData.length ||
																0}
														</Card.Title>
													</div>
												</Col>
											</Row>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</div>
						<Card>
							<Card.Header>
								<Card.Title as="h4">Orders Chart</Card.Title>
								<p className="card-category">
									{moment(startDate).format(
										"DD ddd MMM YYYY"
									)}{" "}
									-{" "}
									{moment(endDate).format("DD ddd MMM YYYY")}{" "}
									Sales
								</p>
							</Card.Header>
							<Card.Body>
								<div className="ct-chart" id="chartActivity">
									{loadingChart ? (
										<div
											style={{
												flex: 1,
												textAlign: "center",
												padding: "20px 0px",
											}}
										>
											<Loader
												type="Oval"
												color={
													CustomStyle.colors.primary
												}
												height={50}
												width={50}
											/>
										</div>
									) : (
										<ChartistGraph
											data={{
												labels: getChartLabels(),
												series: [
													getChartSeries(),
													// [
													//   412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636,
													//   695,
													// ],
												],
											}}
											type="Bar"
											options={{
												seriesBarDistance: 10,
												axisX: {
													showGrid: false,
												},
												height: "245px",
											}}
											responsiveOptions={[
												[
													"screen and (max-width: 640px)",
													{
														seriesBarDistance: 5,
														axisX: {
															labelInterpolationFnc:
																function (
																	value
																) {
																	return value[0];
																},
														},
													},
												],
											]}
										/>
									)}
								</div>
							</Card.Body>
							<Card.Footer>
								<div className="legend">
									<i className="fas fa-circle text-info"></i>
									Earnings
								</div>
							</Card.Footer>
						</Card>
					</Col>
					<Col md="4">
						<Card className="card-tasks">
							<Card.Header>
								<Card.Title as="h4">All Warehouses</Card.Title>
								<p className="card-category">
									Backend development
								</p>
							</Card.Header>
							<Card.Body>
								<div className="table-full-width">
									<Table>
										<tbody>
											{data?.warehouses?.length > 0 ? (
												data?.warehouses?.map(
													(item, index) => {
														return (
															<tr
																key={index.toString()}
															>
																<td>
																	<b>
																		(
																		{
																			item?.WarehouseId
																		}
																		)
																	</b>{" "}
																	{item?.Name}
																</td>
																<td className="td-actions text-right">
																	<OverlayTrigger
																		overlay={
																			<Tooltip id="tooltip-938342127">
																				Edit
																				Warehouse
																			</Tooltip>
																		}
																	>
																		<Button
																			className="btn-simple btn-link p-1"
																			type="button"
																			variant="info"
																			onClick={async () => {
																				await dispatch(
																					{
																						type: SET_ACTIVE_WAREHOUSE,
																						payload:
																							item,
																					}
																				);
																				history.push(
																					"/admin/warehouses"
																				);
																			}}
																		>
																			<i className="fas fa-edit"></i>
																		</Button>
																	</OverlayTrigger>
																</td>
															</tr>
														);
													}
												)
											) : (
												<tr>
													<td
														colSpan="6"
														style={{
															textAlign: "center",
															color: CustomStyle
																.colors.danger,
														}}
													>
														<small>
															No warehouse found
														</small>
													</td>
												</tr>
											)}
										</tbody>
									</Table>
								</div>
							</Card.Body>
							{/* <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="now-ui-icons loader_refresh spin"></i>
                  Updated 3 minutes ago
                </div>
              </Card.Footer> */}
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Dashboard;
